import React from "react";
import { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fireStoreDB } from "../../firebase/fire-app";
import DeleteExamModal from "../DeleteExamModal";

const db = fireStoreDB;
const examCollectionRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam");
const LiveExamCard = ({ id, examDetails }) => {
    const [show, setShow] = useState(false);
    const {
        level,
        title,
        subTitle,
        numberOfQuestions,
        positiveMarks,
        negativeMarks,
        startAt,
        endAt,
        timeInMinutesForEachQuestion,
    } = examDetails;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDeleteExam = (exID) => {
        examCollectionRef
            .doc(exID)
            .delete()
            .then((res) => {
                setShow(false);
                window.location.reload();
            });
    };

    return (
        <div>
            <Card className="mb-3 p-4">
                <Row>
                    <Col md={4}>
                        <p>
                            ID: <strong>{id} </strong>
                        </p>
                        <p>Level: {level} </p>
                        <p>Title: {title}</p>
                        <p>Subtitle:{subTitle} </p>
                    </Col>
                    <Col md={4}>
                        <p>Number of Questions: {numberOfQuestions} </p>
                        <p>Positive mark: {positiveMarks} </p>
                        <p>Negative mark: {negativeMarks} </p>
                        <p>
                            Duration:{" "}
                            {timeInMinutesForEachQuestion * numberOfQuestions}{" "}
                            minutes
                        </p>
                        <p>Is Published: {examDetails.isPublished ? "True" : "False"} </p>
                    </Col>

                    <Col md={3} className="d-flex align-items-center">
                        <div style={{ width: "100%" }}>
                            <Link className="mt-0 btn btn-primary btn-sm btn-block" to={"/liveExams/details/" + id}>
                                Preview
                            </Link>
                            <Link className="btn btn-primary btn-sm btn-block" to={"/leaderBoard/liveExam/" + id} size="sm" block>
                                LeaderBoard
                            </Link>
                            <Link className="btn btn-primary btn-sm btn-block" to={"/liveExams/edit/" + id} size="sm" block>
                                Edit
                            </Link>
                            <Button
                                onClick={() => setShow(true)}
                                size="sm"
                                variant="danger"
                                block
                            >
                                Delete
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Card>
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        Delete exam : <strong>{id} </strong>?
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => handleDeleteExam(id)}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default LiveExamCard;
