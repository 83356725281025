import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import ComponentCourseVideoPreview from '../../../../Components/CourseVideoPreviewComponent/ComponentCourseVideoPreview';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { useCourseModule } from '../useCourseModule';
const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const PreviewTheory = () => {
    const { register, handleSubmit } = useForm();
    const { register: register1, handleSubmit: handleSubmit1 } = useForm();
    const [showPositionVideo, setShowPositionVideo] = useState(false);
    // local states
    const [currentTopic, setCurrentTopic] = useState(null);
    const [sizeVideos, setSizeVideos] = useState(1);
    const [sizeQuestions, setSizeQuestions] = useState(1);
    // const [topicVideos, setTopicVideos] = useState(null);
    const [topicQuestions, setTopicQuestions] = useState(null);

    const [serial, setSerial] = useState(null);
    const [topName, setTopName] = useState(null);
    const [lessonId, setLessonId] = useState(null)
    useEffect(() => {
        if (currentTopic) {
            setSerial(currentTopic.serialNumber);
            setTopName(currentTopic.name);
            setLessonId(currentTopic.id);
        }


    }, [currentTopic])
    // context
    const { currentSub, currentChap, currentType } = useCourseModule();
    const theoryCollection = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("Theory");
    const moduleRef = theoryCollection.orderBy("serialNumber");
    const [topics, topicsLoading, topicsError] = useCollectionData(moduleRef, { idField: "id" });
    const lessonClicked = (data) => {
        setCurrentTopic(null);
        setSizeVideos(0);
        setSizeQuestions(0);
        setSerial(null)
        setTopicQuestions([])
        setTimeout(function () {
            setCurrentTopic({ ...data });
            setSerial(data.serialNumber)
            setSizeVideos(data.videos.length);
            setSizeQuestions(data.questions.length);
            // setTopicVideos([...data.videos]);
            setTopicQuestions([...data.questions]);
        }, 800);

    }
    const deleteLesson = () => {
        console.log(lessonId);
        theoryCollection.doc(lessonId).delete().then(() => {
            console.log("document deleted");
        }).catch(error => {
            console.error("Error removing lesson: ", error)
        })
    }
    const onVideoAddedPosition = (data, e) => {
        let oldVideos = currentTopic.videos;
        oldVideos.splice(data.position, 0, data.vId);
        let oldTopic = { ...currentTopic, videos: oldVideos };

        theoryCollection.doc(currentTopic.id).update({ videos: oldVideos })
            .then(() => {
                console.log("updated");
                lessonClicked(oldTopic)
            }).catch(error => {
                console.error("Error updating document: ", error)
            })


    }
    const onTopicUpdated = (data) => {
        if (data.questions) {
            data.questions = data.questions.filter(Boolean);
        } else {
            data.questions = []
        }
        if (data.videos) {
            data.videos = data.videos.filter(Boolean);
        } else {
            data.videos = []
        }
        data.serialNumber = Number(data.serialNumber)
        // console.log(currentTopic.id);
        let oldTopic = { ...currentTopic, ...data };
        theoryCollection.doc(currentTopic.id).set(data)
            .then(() => {
                console.log("updated");
                lessonClicked(oldTopic)
            }).catch(error => {
                console.error("Error updating document: ", error)
            })

        // console.log(data);
    }
    return (
        <>
            {topicsLoading && <Spinner animation="grow" variant="primary" />}

            <div className="row">
                <div className="col-8 ">
                    {topics && topics.map(item => <button onClick={() => lessonClicked(item)} className="btn btn-dark m-1 btn-sm">{`${item.serialNumber}. ${item.name}`} </button>)}
                </div>
                {currentTopic && <div className="col-8">
                    <hr />
                    <div className="d-flex justify-content-between">
                        <button onClick={() => deleteLesson()} className="btn-danger btn">Delete {`${serial}. ${topName} ?`}</button>
                        <button onClick={() => setShowPositionVideo(!showPositionVideo)} className="btn-dark btn">Insert video at position?</button>
                    </div>
                    {showPositionVideo && <form onSubmit={handleSubmit1(onVideoAddedPosition)}>
                        <div className="row">
                            <InputWrapper col="3" label="Position">
                                <input name="position" ref={register1({ required: true })} type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="6" label="V. Id">
                                <input name="vId" ref={register1({ required: true })} type="text" className="form-control" />
                            </InputWrapper>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </div>
                    </form>}
                    <hr />
                    <form onSubmit={handleSubmit(onTopicUpdated)}>
                        <div className="row">
                            <InputWrapper col="6" label="Sl. No.">
                                <input defaultValue={currentTopic.serialNumber} ref={register({
                                    required: true,
                                })} type="num" name="serialNumber" placeholder="Serial no.." className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="10" label="Topic name">
                                <input defaultValue={currentTopic.name} ref={register({
                                    required: true,
                                })} type="text" name="name" placeholder="Topic name" className="form-control" />
                            </InputWrapper>
                            <div className="col-12">
                                <h5 className="mt-4">Add videos here</h5>
                            </div>
                            <div className="col-md-10">
                                {createArrayWithNumbers(sizeVideos).map(
                                    (index) => {
                                        return (
                                            <>
                                                <div className="d-flex">
                                                    <h4>{index - 1}</h4>
                                                    <ComponentCourseVideoPreview vidId={currentTopic.videos[index - 1]} />

                                                </div>
                                                <input
                                                    className="form-control mb-2 "
                                                    name={`videos[${index}]`}
                                                    placeholder={
                                                        "Put video id here "
                                                    }
                                                    ref={register()}
                                                    defaultValue={currentTopic.videos[index - 1]}
                                                // defaultValue={topicVideos[index - 1]}
                                                />
                                            </>
                                        );
                                    }
                                )}
                            </div>
                            <div className="col-10 text-right">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeVideos(sizeVideos + 1)} >
                                    Add more
                                    </button>
                            </div>
                            {/* questions */}
                            <div className="col-12">
                                <h5 className="mt-4">Add questions here</h5>
                            </div>
                            <div className="col-md-10">
                                {createArrayWithNumbers(sizeQuestions).map(
                                    (index) => {
                                        return (

                                            <input
                                                className="form-control mb-2 "
                                                name={`questions[${index}]`}
                                                placeholder={
                                                    "Put question id here "
                                                }
                                                ref={register()}
                                                defaultValue={topicQuestions[index - 1]}

                                            />

                                        );
                                    }
                                )}
                            </div>
                            <div className="col-10 text-right">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeQuestions(sizeQuestions + 1)} >
                                    Add more
                                    </button>
                            </div>

                            <div className="col-4">
                                <button type="submit" className="btn btn-primary">Update Lesson</button>
                            </div>
                        </div>
                    </form>
                </div>}
            </div>
        </>
    );
};

export default PreviewTheory;