import React, { useState } from 'react';
import { useCollegeAdmissionCourse } from './useCollegeAdmissionCourse';
import { useForm } from "react-hook-form";
import { Row } from 'react-bootstrap';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import { videoType } from './data';
import { fireStoreDB } from '../../../firebase/fire-app';

const CompVideoDetails = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [videoId, setVideoId] = useState(null)
    const videoCollectionRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos");



    const { subject, setSubject, chapter, type, setType } = useCollegeAdmissionCourse();
    const onTypeChanged = (e) => {
        setType(e.target.value)

    }
    const onFormSubmitted = (data, e) => {
        data.subject = subject;
        data.chapter = Number(chapter);
        data.type = type;
        data.level = "CollegeAdmission"
        if (data.important === "true") {
            data.important = true
        } else {
            data.important = false;
        }
        const currentTime = new Date();
        data.createdAt = currentTime;
        videoCollectionRef.add(data).then((docRef) => {
            console.log(docRef.id);
            e.target.reset();
            setVideoId(null);
        })
        console.log(data);

    }
    return (
        <>
            <div className="container">
                <form className="mt-0" onSubmit={handleSubmit(onFormSubmitted)}>
                    <Row>
                        <InputWrapper col="5" label="Name" labelClass="bg-danger" >
                            <input type="text" name="name" className="form-control" placeholder="Name of the video"
                                ref={register({
                                    required: true,
                                })} />
                        </InputWrapper>

                        <InputWrapper col={3} label="Important?" labelClass="bg-danger">
                            <select className="custom-select" name="important" ref={register} >
                                <option defaultValue value={false}>False</option>
                                <option value={true} >True</option>
                            </select>
                        </InputWrapper>
                        <InputWrapper col={4} label="Type">
                            <select onChange={onTypeChanged} className="custom-select" name="type" >
                                <option value={type ? type : ""}>{type ? type : "Choose type.."}</option>
                                {videoType && videoType.map(item => <option value={item} >{item} </option>)}
                            </select>
                        </InputWrapper>
                        <div className="col-md-6 mt-4">
                            <div className="row">
                                <InputWrapper col="12" label="Vimeo id" labelClass="bg-danger">
                                    <input onChange={(e) => setVideoId(e.target.value)} type="text" name="vimeo_id" className="form-control" placeholder="Vimeo id" ref={register({
                                        required: true,
                                    })} />
                                </InputWrapper>
                                <InputWrapper col="12" label="Lesson" labelClass="bg-danger">
                                    <input type="text" name="lesson" className="form-control" placeholder="lesson " ref={register()} />
                                </InputWrapper>
                                <div className="col-md-12">
                                    <p className="font-weight-bold mb-2 mt-3">Video Description:</p>
                                    <textarea ref={register()} name="description" rows="10" className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <iframe src={`https://player.vimeo.com/video/${videoId}`} className="w-100" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="col-12 mt-3 text-center">
                            <button type="submit" className="btn-lg btn btn-primary">Submit this video</button>
                        </div>

                    </Row>
                </form>
            </div>
        </>
    );
};

export default CompVideoDetails;