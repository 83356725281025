import React from 'react';
import { Link } from 'react-router-dom';
import "./Styles.scss"

const CourseSubjectList = () => {
    const subjects = ["PH01", "CH01", "MT01", "BIO01", "BIO02"]
    return (
        <>
            <section className="py-5 subjects">
                <div className="container">
                    <div className="row">
                        {subjects.map(item => <SubComponent id={item} />)}
                    </div>
                </div>
            </section>
        </>
    );
};

export default CourseSubjectList;

const SubComponent = ({ id }) => {
    return (
        <div className="col-md-6 mt-3">
            <Link to={"/add-module/" + id}>
                <div className="btn btn-primary d-block py-4 ">
                    <h4 className="h5 text-white text-center">{id} </h4>
                </div>
            </Link>
        </div>
    );
};