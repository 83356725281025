import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import FetchAvatarImg from '../../Components/FetchAvatarImg/FetchAvatarImg';
import ParaSpanBold from '../../Components/ParaSpanBold';
import UserDetailsChallengeCard from '../../Components/UserDetailsChallengeCard/UserDetailsChallengeCard';
import { useUserProgress } from '../../Hooks/useUserProgress';
import { useUsers } from '../../Hooks/useUsers';
import "./UserCardComponent.scss"

const DailyReportDetails = () => {
    const { userId, date } = useParams();
    const [dailyReport, setDailyReport] = useState(null);
    const { getSingleDayReport } = useUserProgress();
    const [user, setUser] = useState(null);
    const { findUserById } = useUsers()

    useEffect(() => {
        findUserById(userId).then(res => {
            setUser(res);
        });
        getSingleDayReport(userId, date).then(res => {
            setDailyReport(res);
        })
    }, [])
    if (user) {
        document.title = user.name
    }

    return (
        <>{dailyReport &&
            <div>
                <div className="py-4 jumbotron">
                    <Container>
                        {user && <div className="d-flex  px-5 mx-5 justify-content-between align-items-center">
                            <div>
                                <h3>{user.name}</h3>
                                <p><strong>College:</strong> {user.college}</p>
                                <p><strong>Phone: </strong>{user.phone} </p>
                                <p><strong>E-mail: </strong>{user.email} </p>
                                <p><strong>District: </strong>{user.district} </p>
                            </div>
                            <div className="text-center">
                                <FetchAvatarImg width={"166px"} imgName={userId} />
                                <p><i>Date of Birth: <strong> {user.dob}</strong></i></p>
                            </div>
                        </div>}
                    </Container>
                </div>
                <div className="container py-4">
                    <h4 className="text-center mb-4">Challenges taken: {dailyReport.challenges.length === 0 ? "0" : dailyReport.challenges.length} </h4>
                    {dailyReport.challenges.map(item => <UserDetailsChallengeCard id={item} />)}
                </div>
                <div className="container py-4">
                    <h4 className="text-center mb-4">Live Exams taken at: {date}</h4>
                    {dailyReport && dailyReport.liveExams.length === 0 && <p className="text-center">No Live exam taken.</p>}
                    {dailyReport && dailyReport.liveExams.map(item => (
                        <div className=" card p-4">
                            <div className="row">
                                <div className="col-md-5 offset-md-2">
                                    <p className="font-bold">{item}</p>
                                </div>
                                <div className="col-md-3">
                                    <Link className="mt-0 btn btn-primary btn-sm btn-block" to={"/liveExams/details/" + item}>
                                        Exam Details
                            </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="container py-4">
                    <h4 className="text-center mb-4">Practice Exams taken at: {date}</h4>
                    {dailyReport && dailyReport.practiceExams.map(item => <PracticeExamCard examId={item} user={userId} key={item} />)}
                </div>
            </div>}
        </>
    );
};

export default DailyReportDetails;

const PracticeExamCard = ({ user, examId }) => {
    const { getPracticeExamDetails } = useUserProgress()
    const [details, setDetails] = useState(null);
    useEffect(() => {
        getPracticeExamDetails(user, examId).then(res => {
            setDetails(res)
        })
    }, [])
    return (
        <>
            {details && <div className="card p-4 mb-3">
                <div className="row">
                    <div className="col-md-4 border-right-gray">
                        <ParaSpanBold title={"ID: "} body={details.id} />
                        <ParaSpanBold title={"Subject: "} body={details.subject} />
                        <p>Chapters: {details && details.chapters.map(item => <span className="font-bold">{item}</span>)}</p>
                    </div>
                    <div className="col-md-4 border-right-gray">
                        <ParaSpanBold title="Level: " body={details.level} />
                        <ParaSpanBold title="TotalQuestions: " body={details.totalQuestions} />
                        <ParaSpanBold title="TotalNumber: " body={details.totalNumber} />
                    </div>
                    <div className="col-md-4">
                        <ParaSpanBold title="Correct Ans: " body={details.correctAns} />
                        <ParaSpanBold title="Wrong Ans: " body={details.wrongAns} />
                        <ParaSpanBold title="Score Obtained: " body={details.gotNumber} />

                    </div>
                </div>
            </div>}
        </>
    )
}