import React from 'react';
import { useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PageSpinner from '../../Components/Spinner/PageSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import { InputWrapper } from '../EditUser';
const youTubeCollectionRef = fireStoreDB.collection("YouTubeActivity");
const AddYouTubeActivity = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [showSpinner, setShowSpinner] = useState(false)
    const onSubmit = (data, e) => {
        data.serialNumber = Number(data.serialNumber)
        data.date = new Date()
        youTubeCollectionRef.add(data).then(res => {
            console.log("Added successfully.");
            e.target.reset()
        })

    }
    document.title = "Add YouTube activity"
    // if(showSpinner){
    //     return <PageSpinner/>
    // }
    return (
        <div>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <InputWrapper col={4} label="Title">
                            <input type="text" name="title" id="" className="form-control" ref={register({
                                required: true,
                            })} />
                        </InputWrapper>
                        <InputWrapper col={4} label="Video ID: ">
                            <input type="text" name="videoId" id="" className="form-control" ref={register({
                                required: true,
                            })} />
                        </InputWrapper>
                        <InputWrapper col={4} label="serialNumber">
                            <input type="num" name="serialNumber" className="form-control" ref={register({
                                required: true,
                            })} />
                        </InputWrapper>
                        <InputWrapper col={12} label="Description">
                            <textarea name="description" className="form-control" cols="30" rows="10" ref={register({
                                required: true,
                            })}></textarea>
                        </InputWrapper>

                    </Row>
                    <div className="text-center">
                        <Button type="submit" center>
                            Add Activity
                        </Button>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default AddYouTubeActivity;