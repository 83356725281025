import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../Hooks/useAuth";
import AddLiveExam from "../Pages/LiveExams/AddLiveExam";
import AllColleges from "../Pages/AllColleges";
import AllUsersPage from "../Pages/AllUsersPage/AllUsersPage";
import EditLiveExam from "../Pages/LiveExams/EditLiveExam";
import EditUser from "../Pages/EditUser";
import ExamDetails from "../Pages/LiveExams/ExamDetails";
import LiveExamList from "../Pages/LiveExams/LiveExamList";
import LoginPage from "../Pages/LoginPage/LoginPage";
import NewColleges from "../Pages/NewColleges/NewColleges";
import PushNewNotification from "../Pages/PushNewNotification";
import Header from "./Header/Header";
import NotificationList from "./NotificationList";
import LiveExamLeaderBoard from "../Pages/LeaderBoard/LiveExamLeaderBoard";
import OverallLeaderBoard from "../Pages/LeaderBoard/OverallLeaderBoard";
import AddYouTubeActivity from "../Pages/YoutubeActivity/AddYoutubeActivity";
import YouTubeActivityPreview from "../Pages/YoutubeActivity/YouTubeActivityPreview";
import UserDetails from "../Pages/AllUsersPage/UserDetails";
import WeeklyLeaderBoard from "../Pages/LeaderBoard/WeeklyLeaderBoard";
import Challenges from "../Pages/Challenges";
import DailyReportDetails from "../Pages/AllUsersPage/DailyReportDetails";
import AddNewWord from "../Pages/WordGame/AddNewWord";
import MemoryAddQuestion from "../Pages/MemoryTest/MemoryAddQuestion";
import WordGameResult from "../Pages/WordGame/WordGameResult";
import MnemoManiaResult from "../Pages/WordGame/MnemoManiaResult";
import AddCourseVideo from "../Pages/HSCCourses/AddVideo/AddCourseVideo";
import ViewAllVideos from "../Pages/HSCCourses/ViewAllVideos/ViewAllVideos";
import EditCourseVideo from "../Pages/HSCCourses/AddVideo/EditCourseVideo";
import CourseSubjectList from "../Pages/HSCCourses/AddCourseModule/CourseSubjectList";
import AddCourseModule from "../Pages/HSCCourses/AddCourseModule/AddCourseModule";
import UpdateCourseChapters from "../Pages/HSCCourses/UpdateChapters/UpdateCourseChapters";
import EditYoutubeActivity from "../Pages/YoutubeActivity/EditYoutubeActivity";
import DUCrashCourse21 from "../Pages/CrashCourse/DUCrashCourse21";
import CAAddCourseVideo from "../Pages/CollegeAdmission/AddVideo/AddCourseVideo";
import CAViewAllVideos from "../Pages/CollegeAdmission/ViewAllVideos/ViewAllVideos";
import CAEditCourseVideo from "../Pages/CollegeAdmission/AddVideo/EditCourseVideo";
import CACourseSubjectList from "../Pages/CollegeAdmission/AddCourseModule/CourseSubjectList";
import CAAddCourseModule from "../Pages/CollegeAdmission/AddCourseModule/AddCourseModule";
import CAUpdateCourseChapters from "../Pages/CollegeAdmission/UpdateSubjects/UpdateCourseSubject";
import LiveZoomClasses from "../Pages/LiveZoomClasses/LiveZoomClasses";
import AddLiveZoomClass from "../Pages/LiveZoomClasses/AddLiveZoomClass";
import EditZoomClass from "../Pages/LiveZoomClasses/EditZoomClass";
import MedicalAddModule from "../Pages/MedicalAdmission/MedicalAddModule";
import MedicalAllCourseContent from "../Pages/MedicalAdmission/MedicalAllCourseContent";
import MedicalAllStudents from "../Pages/MedicalAdmission/MedicalAllStudents";

const Main = () => {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    {/* <PrivateRoute exact path="/addExam" component={AddLiveExam} /> */}

                    {/* <PrivateRoute
                        exact
                        path="/details/:examId"
                        component={ExamDetails}
                    /> */}

                    {/* <PrivateRoute
                        exact
                        path="/edit/:examId"
                        component={EditLiveExam}
                    /> */}

                    {/* live classes zoom  */}
                    <PrivateRoute exact path="/live-classes" component={LiveZoomClasses} />
                    <PrivateRoute exact path="/live-classes/add" component={AddLiveZoomClass} />
                    <PrivateRoute exact path="/live-classes/edit/:classId" component={EditZoomClass} />

                    <PrivateRoute exact path="/colleges/new" component={NewColleges} />
                    <PrivateRoute exact path="/colleges" component={AllColleges} />
                    {/* Users */}
                    <PrivateRoute exact path="/users" component={AllUsersPage} />
                    <PrivateRoute exact path="/users/edit/:userId" component={EditUser} />
                    <PrivateRoute exact path="/users/details/:userId" component={UserDetails} />
                    <PrivateRoute exact path="/users/details/:userId/:date" component={DailyReportDetails} />
                    {/* LiveExams */}
                    <PrivateRoute exact path="/liveExams" component={LiveExamList} />
                    <PrivateRoute exact path="/liveExams/add" component={AddLiveExam} />
                    <PrivateRoute exact path="/liveExams/edit/:examId" component={EditLiveExam} />
                    <PrivateRoute exact path="/liveExams/details/:examId" component={ExamDetails} />
                    {/* Push Notifications */}
                    <PrivateRoute exact path="/pushNewNotification" component={PushNewNotification} />
                    <PrivateRoute exact path="/previousNotifications" component={NotificationList} />
                    {/* LeaderBoard */}
                    <PrivateRoute exact path="/leaderBoard" component={OverallLeaderBoard} />
                    <PrivateRoute exact path="/leaderBoard/monthly" component={WeeklyLeaderBoard} />
                    <PrivateRoute exact path="/leaderBoard/liveExam/:examId" component={LiveExamLeaderBoard} />
                    {/* YouTube Activity */}
                    <PrivateRoute exact path="/youTubeActivity/add" component={AddYouTubeActivity} />
                    <PrivateRoute exact path="/youTubeActivity/edit/:vidId" component={EditYoutubeActivity} />
                    <PrivateRoute exact path="/youTubeActivity" component={YouTubeActivityPreview} />
                    {/* Challenges */}
                    <PrivateRoute exact path="/challenges" component={Challenges} />
                    <PrivateRoute exact path="/" component={LiveExamList} />
                    {/* Word Game */}
                    <PrivateRoute exact path="/memory-game" component={AddNewWord} />
                    {/* mMemory Test  */}
                    <PrivateRoute exact path="/memory-test/addQuestion" component={MemoryAddQuestion} />

                    <PrivateRoute exact path="/result/minitest" component={WordGameResult} />
                    <PrivateRoute exact path="/result/mnemo-mania" component={MnemoManiaResult} />

                    {/* Course Pages */}
                    {/* <PrivateRoute exact path="/hsc-courses/add-video/:level" component={AddCourseVideo} /> */}
                    <PrivateRoute exact path="/hsc-courses/add-video" component={AddCourseVideo} />
                    <PrivateRoute exact path="/hsc-courses/all-videos" component={ViewAllVideos} />
                    <PrivateRoute exact path="/edit-lesson/:lessonId" component={EditCourseVideo} />
                    <PrivateRoute exact path="/course-subjects-list" component={CourseSubjectList} />
                    <PrivateRoute exact path="/add-module/:subId" component={AddCourseModule} />
                    <PrivateRoute exact path="/hsc-courses/edit-chapters" component={UpdateCourseChapters} />
                    {/* College admission */}
                    <PrivateRoute exact path="/college-admission/HSCCourses/add-video" component={CAAddCourseVideo} />
                    <PrivateRoute exact path="/college-admission/HSCCourses/all-videos" component={CAViewAllVideos} />
                    <PrivateRoute exact path="/college-admission/edit-lesson/:lessonId" component={CAEditCourseVideo} />
                    <PrivateRoute exact path="/college-admission/course-subjects-list" component={CACourseSubjectList} />
                    <PrivateRoute exact path="/college-admission/add-module/:subId" component={CAAddCourseModule} />
                    <PrivateRoute exact path="/college-admission/HSCCourses/edit-chapters" component={CAUpdateCourseChapters} />

                    {/* Medical admission */}
                    <PrivateRoute exact path="/medical-admission/modules/add" component={MedicalAddModule} />
                    <PrivateRoute exact path="/medical-admission/modules/all" component={MedicalAllCourseContent} />
                    <PrivateRoute exact path="/medical-admission/all-students" component={MedicalAllStudents} />
                   
                    {/* Crash Course Pages */}
                    <PrivateRoute exact path="/du-crash-course" component={DUCrashCourse21} />

                    {/* Login Route */}
                    <Route exact path="/login" component={LoginPage} />


                </Switch>
            </Router>
        </div>
    );
};

export default Main;
