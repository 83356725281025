import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { fireStoreDB } from "../../../firebase/fire-app";
import { useCollectionData } from 'react-firebase-hooks/firestore';


const VideoContext = createContext();

export const VideoContextProvider = (props) => {
    const videoManager = VideoManager();
    return (
        <VideoContext.Provider value={videoManager}>
            {props.children}
        </VideoContext.Provider>
    )
}
export const useCourseVideo = () => useContext(VideoContext);


const VideoManager = () => {
    const [subject, setSubject] = useState("CH01");
    const [chapter, setChapter] = useState(null);
    const [requestedSubject, setRequestedSubject] = useState("CH01C01");
    const [type, setType] = useState(null);
    const [chapterNum, setChapterNum] = useState(null)
    const chapterCollectionRef = fireStoreDB.collection("Courses").doc("Subjects").collection(subject);
    const reqChapterCollectionRef = fireStoreDB.collection("Courses").doc("Subjects").collection(requestedSubject);
    const [chapters, chapLoading, chapError] = useCollectionData(chapterCollectionRef, { idField: "id" })
    const [requestedChapters, reqChapLoading, reqChapError] = useCollectionData(reqChapterCollectionRef, { idField: "id" })

    return {
        requestedChapters, reqChapLoading, setRequestedSubject, subject, setSubject, chapter, setChapter, chapters, chapLoading, type, setType, chapterNum, setChapterNum
    }
}