import firebase from "firebase";
import "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyBBYG5hOkmcSoQUdNmWnMVYzyVPf-u48Rs",
    authDomain: "prottoy-16bf0.firebaseapp.com",
    databaseURL: "https://prottoy-16bf0.firebaseio.com",
    projectId: "prottoy-16bf0",
    storageBucket: "prottoy-16bf0.appspot.com",
    messagingSenderId: "104681094193",
    appId: "1:104681094193:web:01692139c4df9785f52910",
    measurementId: "G-0EFTX4MDP1"
  };

const fireApp = firebase.initializeApp(firebaseConfig);
// fireApp
//     .firestore()
//     .enablePersistence()
//     .catch((err) => {
//         if (err.code === "failed-precondition") {
//             console.log(err);
//         } else console.log(err);
//     });

export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();
export const duCrashCourseReg21 = fireStoreDB.collection("Marketing").doc("duCrashCourse21").collection("registrations")

export const zoomClassesCollection = fireStoreDB.collection("OnlineTutor").doc("LiveClass").collection("liveClasses");
export const usersCollectionRef = fireStoreDB.collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");

export const medicalCourseContentCollection = fireStoreDB.collection("Courses").doc("SpecialCourses").collection("CrashCourses").doc("MedicalAdmission2022").collection("courseContents");
export const medicalCourseStudentsCollection = fireStoreDB.collection("Courses").doc("SpecialCourses").collection("CrashCourses").doc("MedicalAdmission2022").collection("registeredUsers");