import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import NewCollegeCard from "../../Components/NewCollegeCard/NewCollegeCard";
import { fireStoreDB } from "../../firebase/fire-app";
import { useColleges } from "../../Hooks/useColleges";
import { InputWrapper } from "../../Pages/EditUser";
const newCollegesRef = fireStoreDB
    .collection("metadata")
    .doc("collegeData")
    .collection("newColleges");

const NewColleges = () => {
    const collegesManager = useColleges();
    const colleges = collegesManager.colleges;
    const [newColleges, setNewColleges] = useState([]);
    document.title = "New Colleges";
    useEffect(() => {
        const fetchNewColleges = async () => {
            const newCollegesCollection = await newCollegesRef.get();
            setNewColleges(
                newCollegesCollection.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() };
                })
            );
        };
        fetchNewColleges();
    }, []);
    return (
        <div>
            <div className="container">
                {newColleges &&
                    newColleges.map((item, index) => (
                        <NewCollegeCard college={item} serial={index + 1} />
                    ))}
            </div>
        </div>
    );
};

export default NewColleges;
