import React from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { useCourseModule } from '../useCourseModule';

const TypeOutline = () => {
    const { register, handleSubmit } = useForm();
    const { currentSub, currentChap, currentType } = useCourseModule()
    const moduleRef = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("Outline").doc("outline");
    const onModuleSubmitted = (data, e) => {
        console.log(data);
        moduleRef.set(data).then(() => {
            console.log("updated");
            e.target.reset();
        })
        // console.log(currentSub, currentChap, currentType);
    }
    return (
        <>
            <div className="container-fluid mt-4">

                <div className="row">
                    <div className="col-md-4 border-right">
                        <h4 className="text-left mb-4">You are adding <span className="bg-danger text-white px-3"> outline </span> videos</h4>
                        <form onSubmit={handleSubmit(onModuleSubmitted)}>
                            <div className="row">
                                <InputWrapper col="10" label="Video Id">
                                    <input ref={register()} type="text" name="videoId" placeholder="Put the video id here" className="form-control" />
                                </InputWrapper>
                                <div className="col-md-12">
                                    <textarea ref={register()} name="description" id="" cols="30" rows="10" className="form-control"></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Add</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h4 className="mb-4">Lesson Preview</h4>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TypeOutline;