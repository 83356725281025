import React from 'react';
import { Table } from "react-bootstrap";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { duCrashCourseReg21 } from '../../firebase/fire-app';

const DUCrashCourse21 = () => {
    const [registrations, loading, error] = useCollectionData(duCrashCourseReg21.orderBy("date", "desc"), { idField: "id" })
    return (
        <>
            <div className="jumbotron text-center">
                <h2 className="font-weight-bold">Crash Course Registrations</h2>
                {/* <button onClick={() => console.log(registrations)} className="btn btn-primary">Log</button> */}
            </div>
            <section className="py-4">
                <div className="container">
                    <Table responsive bordered hover variant="primary">
                        <thead>
                            <tr className="">
                                <th>#</th>
                                {/* <th>Delete</th> */}
                                <th>Date</th>
                                <th>Name</th>
                                <th>Phone</th>

                            </tr>
                        </thead>
                        <tbody>
                            {registrations && registrations.map((item, index) => <SubscriptionCard key={item.id} data={item} serial={index} />)}
                            {/* {data && data.length == 0 && <td className="font-weight-bold h5 ml-4 text-center text-danger" >No sale yet.</td>} */}
                            {/* <td>  <button onClick={()=> console.log(data)} className="btn btn-dark">Log</button> </td> */}
                        </tbody>
                    </Table>

                </div>
            </section>
        </>
    );
};

export default DUCrashCourse21;

const SubscriptionCard = ({ data, serial }) => {
    const onDelete = () => {
        duCrashCourseReg21.doc(data.id).delete();
    }
    return (
        <>
            <tr className="" >
                <td>{serial + 1} </td>
                {/* <td> <button onClick={onDelete} className="btn btn-danger">Delete</button> </td> */}
                <td>{customDateFormat(data.date)}</td>
                <td>{data.name} </td>
                <td>{data.phone} </td>
            </tr>
        </>
    )
}

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.seconds * 1000);
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        // today = mm + '-' + dd + '-' + yyyy;
        // console.log(today);
        // today = mm + '/' + dd + '/' + yyyy;
        // console.log(today);
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);
        today = dd + '/' + mm + '/' + yyyy;
        return today
    } else {
        return "Not yet"
    }
}