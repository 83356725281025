import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import fireApp from "../../firebase/fire-app";

const FetchImgComponent = ({ imgName }) => {

    return (
        <div>
            <div>
                <img style={{ width: "70%" }} src={imgName} alt="" />
            </div>
        </div>
    );
};

export default FetchImgComponent;
