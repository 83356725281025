import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillTag, AiFillTags } from "react-icons/ai";
import "./styles.scss"
import { useForm } from 'react-hook-form';
import { fireStoreDB } from '../../firebase/fire-app';
const wordCollectionRef = fireStoreDB.collection("Marketing").doc("memory-game").collection("words");

const WordsContainer = ({ words }) => {
    const [currentWord, setCurrentWord] = useState(words[0]);
    const [edit, setEdit] = useState(false);

    const { register, handleSubmit } = useForm();

    // Edit word function
    const onWordUpdated = (data, e) => {
        data.createdAt = new Date();
        console.log(data);

        wordCollectionRef.doc(currentWord.id).set(data).then(res => {
            console.log("Added: ", data.word);
            e.target.reset();
            setEdit(false);
        })
    }

    // edit option
    const setEditOption = (word) => {
        setCurrentWord(word);
        setEdit(true);
    }
    // delete
    const deleteWord = (id) => {
        wordCollectionRef.doc(id).delete().then(res => {
            setCurrentWord(words[0]);
        })
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="py-2 d-flex flex-wrap">
                            {words.map(item => <button onClick={() => setCurrentWord(item)} className="btn btn-primary m-2" to={"/words/edit/" + item.id}><AiFillTag /> {item.word} </button>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        {currentWord &&
                            <div className="p-4 mb-4 bg-primary word-preview text-white" >
                                <p>Word: <span>{currentWord.word} </span> </p>
                                <p>Meaning: <span>{currentWord.meaning} </span> </p>
                                <p>Pronunciation: <span>{currentWord.pronunciation} </span> </p>
                                <p>Parts of Speech: <span>{currentWord.partsOfSpeech} </span> </p>
                                <p>Fun fact: <span>{currentWord.funFact} </span> </p>
                                <div className="d-flex justify-content-between mt-3">
                                    <button onClick={() => setEditOption(currentWord)} className="btn btn-danger btn-sm">Edit this word</button>
                                    <button onClick={() => deleteWord(currentWord.id)} className="btn btn-danger btn-sm">Delete </button>
                                </div>
                            </div>
                        }
                        {edit &&
                            <form onSubmit={handleSubmit(onWordUpdated)} className="p-4 bg-primary word-preview mb-5">
                                <input defaultValue={currentWord.word} ref={register()} type="text" name="word" className="form-control mb-3" placeholder="Write the word" />
                                <input defaultValue={currentWord.meaning} ref={register()} type="text" name="meaning" className="form-control mb-3" placeholder="Write the meaning" />
                                <input defaultValue={currentWord.partsOfSpeech} ref={register()} type="text" className="form-control mb-3" name="partsOfSpeech" placeholder="Parts of Speech..." />
                                <input defaultValue={currentWord.pronunciation} ref={register()} type="text" className="form-control mb-3" name="pronunciation" placeholder="Pronunciation..." />
                                <input defaultValue={currentWord.funFact} ref={register()} type="text" name="funFact" className="form-control mb-3" placeholder="Fun fact of this word" />
                                <button type="submit" className="btn btn-danger">Update word</button>
                            </form>}

                    </div>
                </div>

            </div>
        </>
    );
};

export default WordsContainer;