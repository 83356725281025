import React from 'react';

const ParaSpanBold = ({ title, body }) => {
    return (
        <p>{title}
            <span className="font-bold" >{body}</span>
        </p>
    );
};

export default ParaSpanBold;