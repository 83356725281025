import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PageSpinner from "../Components/Spinner/PageSpinner";
import { fireStoreDB } from "../firebase/fire-app";

const db = fireStoreDB;

const UserProgressContext = createContext();

export const UserProgressContextProvider = (props) => {
    const userProgressManager = UserProgressManager();
    return (
        <UserProgressContext.Provider value={userProgressManager}>
            {props.children}
        </UserProgressContext.Provider>
    )
}

export const useUserProgress = () => useContext(UserProgressContext);
const userProgressCollectionRef = db.collection("ExamOrQuiz").doc("Results").collection("Users");

const UserProgressManager = () => {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const getPointsBadge = (uid) => {
        return userProgressCollectionRef.doc(uid).get().then(doc => {
            return { id: doc.id, ...doc.data() }
        })
    }
    const getDailyReport = (uid) => {
        return userProgressCollectionRef.doc(uid).collection("Daily").orderBy("date", 'desc').limit(7).get().then(docs => {
            setLoading(false);
            return docs

        })
    }
    const fetchMoreReport = (uid, last) => {
        return userProgressCollectionRef.doc(uid).collection("Daily").orderBy("date", "desc").startAfter(last).limit(7).get().then(docs => {
            return docs;
        })
    }
    const getSingleDayReport = (uid, date) => {
        return userProgressCollectionRef.doc(uid).collection("Daily").doc(date).get().then(doc => {
            return { id: doc.id, ...doc.data() }
        })
    }
    const getPracticeExamDetails = (uid, examId) => {
        return userProgressCollectionRef.doc(uid).collection("PracticeExams").doc(examId).get().then(doc => {
            return { id: doc.id, ...doc.data() }
        })
    }
    const updateUserBadges = (uid, badge) => {
        return userProgressCollectionRef.doc(uid).get().then(doc => {
            const currentBadges = doc.data().weeklyBadges;
            const uniqueBadges = [...new Set(currentBadges.concat(badge))]
            userProgressCollectionRef.doc(uid).update({ weeklyBadges: uniqueBadges })
            return "done"
        })
    }
    return {
        getPointsBadge,
        getDailyReport,
        getPracticeExamDetails,
        getSingleDayReport,
        fetchMoreReport,
        updateUserBadges
    }
}