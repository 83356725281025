import React from "react";
import {
    Container,
    Dropdown,
    DropdownButton,
    Nav,
    Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import fireApp from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";

const Header = () => {
    const { currentUser } = useAuth()
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" className="navbar ">
                <Container className="d-flex align-items-center">
                    <Navbar.Brand className="mr-auto" >
                        <Link to="/" className="text-white">Prottoy</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="ml-auto" id="basic-navbar-nav">
                        <Nav className="ml-auto text-center">
                            <DropdownButton className="" title="LiveExams">

                                <Dropdown.Item >
                                    <Link to="/liveExams">
                                        Exam List
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item >
                                    <Link to="/liveExams/add">Add Exam</Link>
                                </Dropdown.Item>
                            </DropdownButton>

                            {/* live classes */}
                            <DropdownButton className="" title="Live Class">
                                <Dropdown.Item>
                                    <Link to="/live-classes">Live Classes</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/live-classes/add">Add New Live Class</Link>
                                </Dropdown.Item>
                            </DropdownButton>

                            <DropdownButton className="" title="Users">
                                <Dropdown.Item>
                                    <Link to="/users"> All Users</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton className="" title="LeaderBoard">
                                <Dropdown.Item>
                                    <Link to="/leaderBoard"> Leader Board</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/challenges"> Challenges</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/leaderboard/Monthly"> Monthly</Link>
                                </Dropdown.Item>

                            </DropdownButton>
                            <DropdownButton className="" title="YouTube">
                                <Dropdown.Item>
                                    <Link to="/youTubeActivity"> View All</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/youTubeActivity/add"> Add New</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton className="" title="Notifications">
                                <Dropdown.Item>
                                    <Link to="/pushNewNotification"> Push New Notification</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/previousNotifications"> Previous Notifications</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            {/* <DropdownButton className="mr-3" title="Game">
                                <Dropdown.Item>
                                    <Link to="/result/minitest">Mini Test Result</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/result/mnemo-mania">Mnemo-Mania Result</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/memory-test/addQuestion">Add Question</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/memory-game">Add New Word</Link>
                                </Dropdown.Item>

                            </DropdownButton> */}
                            {/* ssc corner */}
                            <DropdownButton className="" title="Medical A.">
                                <Dropdown.Item>
                                    <Link to="/medical-admission/modules/all">View all modules</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/medical-admission/modules/add">Add Module</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/medical-admission/all-students">Students</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            {/* ssc corner */}
                            <DropdownButton className="" title="College A.">
                                <Dropdown.Item>
                                    <Link to="/college-admission/HSCCourses/add-video">Add New Video</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/college-admission/HSCCourses/all-videos">View all videos</Link>
                                </Dropdown.Item>
                                {/* <Dropdown.Item>
                                    <Link to="/college-admission/course-subjects-list">Edit Course Module</Link>
                                </Dropdown.Item> */}
                                <Dropdown.Item>
                                    <Link to="/college-admission/add-module/CollegeAdmission">Edit Course Module</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/college-admission/HSCCourses/edit-chapters">Edit Subjects</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            {/* hsc corner */}
                            <DropdownButton className="" title="HSC Courses">
                                <Dropdown.Item>
                                    <Link to="/hsc-courses/add-video">Add New Video</Link>
                                </Dropdown.Item>
                                {/* <Dropdown.Item>
                                    <Link to="/hsc-courses/add-video/ssc">Add SSC</Link>
                                </Dropdown.Item> */}
                                <Dropdown.Item>
                                    <Link to="/hsc-courses/all-videos">View all videos</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/course-subjects-list">Edit Course Module</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to="/hsc-courses/edit-chapters">Edit Chapters</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                            

                            {/* <Link className="mr-2 btn btn-danger" to="/du-crash-course">DU </Link> */}
                            {/* {currentUser ? (
                                <button
                                    className="btn btn-danger"
                                    onClick={() => fireApp.auth().signOut()}
                                >
                                    Signout
                                </button>
                            ) : (
                                <Nav.Link>
                                    <Link to="/login" className="btn btn-danger btn-sm">Login</Link>
                                </Nav.Link>
                            )} */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
