import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import FetchAvatarImg from "../../Components/FetchAvatarImg/FetchAvatarImg";
import fireApp, { usersCollectionRef } from "../../firebase/fire-app";
import SectionSpinner from "../../Components/Spinner/SectionSpinner";

const ClassStudentDetails = ({userId}) => {
    const [userDetails, loading, error] = useDocumentData(usersCollectionRef.doc(userId), { idField: "id" })
    useEffect(()=>{
        if(userDetails){
            console.log(userDetails)
        }
    },[])

    return (
        <>
        <div className="col-12">
        {loading && <SectionSpinner />}
        </div>
        <div className="col-12">
            {/* <h5>{userId}</h5> */}
            {userDetails && <Card className="p-4 pr-0 mb-3">
                <Row>
                    <Col md={2}>
                        {/* <FetchAvatarImg imgName={userId} /> */}
                        {userDetails.photoUrl ? (
                            <img alt=""
                                className="avatar rounded-square"
                                src={userDetails.photoUrl} />
                        ) : (
                                <span>no image</span>
                            )}
                        {/* {photoUrl ? (
                            <Image
                                className="avatar"
                                src={photoUrl}
                                roundedCircle
                            />
                        ) : (
                                <span>no image</span>
                            )} */}
                    </Col>
                    <Col md={4}>
                        <h4>{userDetails.name}</h4>
                        <p className="font-weight-bold ">{userId}</p>
                        <p>{userDetails.email} </p> <p>{userDetails.phone} </p>
                        <p>District: {userDetails.district} </p>
                    </Col>
                    <Col md={4}>
                        <h4>&nbsp;</h4>
                        <p>College: {userDetails.college} </p>
                        <p>
                            College ID:<strong> {userDetails.collegeId}</strong>
                        </p>
                    </Col>
                    <Col md={2}>
                        <h4>&nbsp;</h4>
                        <Link
                            className="btn btn-primary btn-sm btn-block"
                            to={"/users/details/" + userId}
                            size="sm"
                            block
                        >
                            View Profile
                        </Link>
                        <Link className="btn btn-sm btn-danger btn-block"
                            to={"/users/edit/" + userId}
                        >
                            Edit Profile
                        </Link>
                    </Col>
                </Row>
            </Card>}

        </div>
        </>
    );
};

export default ClassStudentDetails;
