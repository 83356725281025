import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import LeaderBoardCard from '../../Components/LeaderBoardCard';
import { fireStoreDB } from '../../firebase/fire-app';
import { useUserProgress } from '../../Hooks/useUserProgress';
import { InputWrapper } from '../EditUser';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const weekCollectionRef = fireStoreDB
    .collection("ExamOrQuiz")
    .doc("Results")
    .collection("Monthly");
const WeeklyLeaderBoard = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [weekId, setWeekId] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(null)
    // const [weeks, setWeeks] = useState([])
    const [weeks, weeksLoading, weeksErr] = useCollectionData(weekCollectionRef, { idField: "id" })
    useEffect(() => {
        const fetchWeekCollection = () => {
            weekCollectionRef.get().then(res => {
                if (weekId === null) {
                    setWeekId(res.docs[0].id);

                    setCurrentWeek({ id: res.docs[0].id, ...res.docs[0].data() })
                }
                // setWeeks(
                //     res.docs.map(doc => {
                //         return { id: doc.id, ...doc.data() }
                //     })
                // )
            })
        }
        fetchWeekCollection()
    }, [weekId])
    const [updated, setUpdated] = useState(1)
    useEffect(() => {
        const fetchUsers = async () => {
            if (weekId) {
                const usersCollection = await weekCollectionRef.doc(weekId).collection("Rank").orderBy("points", "desc").get();
                setUsers(
                    usersCollection.docs.map(doc => {
                        return { id: doc.id, ...doc.data() }
                    })
                )
                setLoading(false)
            }
        }
        fetchUsers()
    }, [weekId, updated])
    const { updateUserBadges } = useUserProgress()
    const updateRank = (week, id, newRank) => {
        weekCollectionRef.doc(week).collection("Rank").doc(id)
            .update({ rank: newRank })
            .then(res => {
                console.log('updated: ', res);
            })
    }
    const updateAllUsers = () => {
        users.forEach((usr, index) => {
            updateRank(weekId, usr.id, index + 1);
        })
        setUpdated(updated + 1)
    }
    const updateWeekId = (e) => {
        const newWeekId = e.target.value
        console.log(weeks);
        setWeekId(newWeekId);
        const newWeek = weeks.find(item => item.id === newWeekId)
        setCurrentWeek(newWeek)
    }
    const updateWeeklyBadges = (allUsers, week) => {
        // let tenUsers = [];
        // if (allUsers.length > 10) {
        //     tenUsers = allUsers.slice(0, 10);
        // } else {
        //     tenUsers = allUsers
        // }

        allUsers.forEach((item) => {
            updateUserBadges(item.id, week).then(res => {
                console.log("updated");
            })
        })
    }
    const currentDate = new Date();
    const currentSeconds = currentDate.getTime() / 1000;
    return (
        <>
            <div className="bg-light">
                <div className="container py-4">
                    <h3 className="text-center mb-4">Monthly LeaderBoard </h3>
                    <Row>
                        <div className="col-md-3 text-center"></div>
                        {/* <button onClick={() => console.log(weeks)} className="btn-danger btn">Log</button> */}
                        <InputWrapper col={6} addClass="md-offset-3" label="Month ">
                            <select className="form-control" onChange={updateWeekId} >
                                {weeks && weeks.map(item => <option value={item.id}>{item.id}</option>)}
                            </select>
                        </InputWrapper>
                    </Row>


                    <div className="text-center mt-4">
                        <h4>Current month: {weekId} </h4>
                        <Button onClick={() => updateAllUsers()}>Update All User Rank</Button>
                    </div>
                    <hr />
                    {/* {currentWeek && currentSeconds > currentWeek.endAt.seconds && <div className="text-center py-4">
                        <h4 >Update top 10 users' Badges</h4>
                        <Button onClick={() => updateWeeklyBadges(users, weekId)} variant="danger">Update</Button>
                    </div>} */}
                </div>
            </div>
            <Container>
                {users && users.map(item => <LeaderBoardCard key={item.id} userId={item.id} gotNumber={item.points} rank={item.rank} />)}
            </Container>
        </>
    );
};

export default WeeklyLeaderBoard;