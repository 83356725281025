import React from 'react';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';

import { useCollectionData } from 'react-firebase-hooks/firestore';
import { medicalCourseStudentsCollection } from '../../firebase/fire-app';
import { Link } from 'react-router-dom';
import UserInfoFetch from './UserInfoFetch';

const MedicalAllStudents = () => {
    const [students, loading, error] = useCollectionData(medicalCourseStudentsCollection, { idField: "id" });

    return (
        <>
            <section className="py-3 bg-light">
                <p className="mb-0 text-left font-14">Medical Admission/<b>Registered Students</b></p>
            </section>
            {students && students.length > 0 ? <section className="py-4">
                <div className="container">
                    <div className="row">
                        {students && students.length > 0 && students.map((item, index) => <CrashStudentCard index={index} data={item} key={item.id} />)}
                    </div>
                </div>
            </section> : <SectionSpinner />}
        </>
    );
};

export default MedicalAllStudents;

const CrashStudentCard = ({ data, index }) => {
    return (
        <div className="col-md-12">
            <div className="crash-card p-4">
                <div className="row d-flex align-items-center">
                    <div className="col-4 d-flex justify-content-start">
                        <span className="number mr-3">{index + 1}</span>
                        <p className="mb-0 font-14">
                            <b><UserInfoFetch uid={data.uid}/></b> <br />
                            HSC Batch: {data.hscBatch}
                        </p>
                     
                    </div>
                    <div className="col-3 d-flex justify-content-around ">
                        Admit card: {data.admitCardNo} <br />
                        Reg. No: {data.registrationNo}
                    </div>
                    <div className="col-3 d-flex justify-content-around ">
                    {
                            data.isPaid ? <span className="btn btn-danger btn-sm">PAID: {data.amount}</span>
                                :
                                <span className="btn btn-primary btn-sm">FREE</span>
                        }
                    </div>
                    <div className="col-2 d-flex justify-content-around">

                        <Link to={`/users/details/${data.uid}`} className="btn btn-sm btn-primary">
                            User Details
                        </Link>
                        {/* <Button size='sm' variant="danger" onClick={() => setModalShow(true)}>
                                Edit Content
                            </Button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

