import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import fireApp from '../../firebase/fire-app';

const FetchExamImage = ({ imgName }) => {
    console.log(imgName);
    const [imgLink, setImgLink] = useState(null);
    const storageRef = fireApp.storage().ref();
    useEffect(() => {
        if (imgName) {
            storageRef.child("liveExamImages").child(imgName).getDownloadURL()
                .then(res => {
                    setImgLink(res);
                    console.log(res);
                })
        }
    }, [])
    return (

        <div className="col-md-8 offset-md-2 mb-4">
            {!imgLink && <SectionSpinner />}
            {imgLink && <img src={imgLink} alt="" className="img-fluid" />}
        </div>

    );
};

export default FetchExamImage;