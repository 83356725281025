import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { usersCollectionRef } from '../../firebase/fire-app';

const UserInfoFetch = ({uid}) => {
    const [info, loading, error] = useDocumentData(usersCollectionRef.doc(uid));
    return (
        <>
            {
                info? info.name : uid
            }
        </>
    );
};

export default UserInfoFetch;