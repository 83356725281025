import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUsers } from '../Hooks/useUsers';
import ParaSpanBold from './ParaSpanBold';
const AllTimeLeaderboardCard = ({ usr, rank }) => {
    const { findUserById } = useUsers();
    const [user, setUser] = useState();
    useEffect(() => {
        findUserById(usr.id).then(response => {
            setUser(response);
        })
    }, [])
    return (
        <Card className="my-4 p-4">
            {user && <Row>
                <Col md={1}><strong>#{rank} {" "}</strong></Col>
                <Col md={5}>
                    <ParaSpanBold title="Name: " body={user.name} />
                    <ParaSpanBold title="College: " body={user.college} />
                    <ParaSpanBold title="District: " body={user.district} />
                    <ParaSpanBold title="Phone: " body={user.phone} />
                    <ParaSpanBold title="Email: " body={user.email} />
                </Col>
                <Col md={4}>
                    <ParaSpanBold title="Points obtained: " body={usr.points} />
                    <ParaSpanBold title="Time Spent: " body={new Date(usr.timeSpent * 1000).toISOString().substr(11, 8)} />
                    <ParaSpanBold title="Total Questions: " body={usr.totalQuestion} />
                    <ParaSpanBold title="Correct answers: " body={usr.correctAns} />
                    <ParaSpanBold title="Wrong answers: " body={usr.wrongAns} />

                </Col>
                <Col md={2}>
                    <Link className="btn btn-primary btn-small" to={"/users/details/" + usr.id} >View UserDetails</Link>
                </Col>
            </Row>}
        </Card>
    );
};

export default AllTimeLeaderboardCard;