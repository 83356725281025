import React, { useEffect, useState } from 'react';
import { useUsers } from '../../Hooks/useUsers';

const CompLeaderReportRow = ({gotNumber,rank,userId,}) => {
    const { findUserById } = useUsers();
    const [user, setUser] = useState();
    useEffect(() => {
        findUserById(userId).then(response => {
            setUser(response);
        })
    }, [])
    return (
        <>
            {user && <tr>
                <td>{rank} </td>
                <td>{user.name} </td>
                <td>{gotNumber} </td>
                <td>{user.phone} </td>
                <td>{user.district} </td>
                <td>{userId} </td>
            </tr>}
        </>
    );
};

export default CompLeaderReportRow;