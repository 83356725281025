import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Image, Jumbotron, Spinner } from 'react-bootstrap';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams, useRouteMatch } from 'react-router';
import FetchAvatarImg from '../../Components/FetchAvatarImg/FetchAvatarImg';
import ParaSpanBold from '../../Components/ParaSpanBold';
import PageSpinner from '../../Components/Spinner/PageSpinner';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import fireApp, { fireStoreDB } from '../../firebase/fire-app';
import { useUserProgress } from '../../Hooks/useUserProgress';
import { useUsers } from '../../Hooks/useUsers';
import ComponentDailyUserCard from './ComponentDailyUserCard';

const userCollectionRef = fireStoreDB
    .collection("ExamOrQuiz")
    .doc("Results")
    .collection("Users");

const UserDetails = () => {
    const { userId } = useParams();
    const { getPointsBadge, getDailyReport, getPracticeExamDetails, fetchMoreReport } = useUserProgress()
    const [loading, setLoading] = useState(false);
    const { findUserById } = useUsers()
    const [user, setUser] = useState(null);
    const [score, setScore] = useState({});
    const [dailyReport, setDailyReport] = useState([]);
    const [lastReport, setLastReport] = useState(null)
    const [progress, loadProgress, errProgress] = useDocumentData(userCollectionRef.doc(userId))
    useEffect(() => {
        findUserById(userId).then(res => {
            setUser(res);
        });
        getPointsBadge(userId).then(res => {
            setScore(res)
        })
        getDailyReport(userId).then(docSnapshots => {
            if (docSnapshots) {
                let lastDoc = docSnapshots.docs[docSnapshots.docs.length - 1];
                const list = [];
                docSnapshots.forEach(doc => {
                    list.push({ id: doc.id, ...doc.data() })
                })
                let updatedList = dailyReport.concat(list);
                setDailyReport(updatedList);
                setLastReport(lastDoc);
            }

        })
    }, [])
    const handleFetchMore = () => {
        setLoading(true)
        fetchMoreReport(userId, lastReport).then(docSnapshots => {
            if (docSnapshots) {
                let lastDoc = docSnapshots.docs[docSnapshots.docs.length - 1];
                const list = [];
                docSnapshots.forEach(doc => {
                    list.push({ id: doc.id, ...doc.data() })
                })
                let updatedList = dailyReport.concat(list);
                setLoading(false)
                setDailyReport(updatedList);
                setLastReport(lastDoc)
            }
        })
    }
    const { path, url } = useRouteMatch()

    document.title = user ? user.name : "Loading..."
    if (!user) {
        return <PageSpinner />
    }
    return (
        <>
            <div className="py-4 jumbotron">
                <Container>
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4">
                            <div>
                                <h3>{user.name}</h3>
                                <p><strong>UID:</strong> {user.uid}</p>
                                <p><strong>College:</strong> {user.college}</p>
                                <p><strong>Phone: </strong>{user.phone} </p>
                                <p><strong>E-mail: </strong>{user.email} </p>
                                <p><strong>Birth date: </strong>{user.birthdate} </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <h3>&nbsp;</h3>
                                <p><strong>District: </strong>{user.district} </p>
                                <p><strong>Address: </strong>{user.address} </p>
                                <p><strong>Gender:</strong> {user.gender}</p>
                                <p><strong>User type: </strong>{user.userType} </p>

                            </div>
                        </div>
                        {!errProgress && progress && <div className="col-md-3">
                            <h3>&nbsp;</h3>
                            <ParaSpanBold title="Points obtained: " body={progress.points} />
                            <ParaSpanBold title="Time Spent: " body={new Date(progress.timeSpent * 1000).toISOString().substr(11, 8)} />
                            <ParaSpanBold title="Total Questions: " body={progress.totalQuestion} />
                            <ParaSpanBold title="Correct answers: " body={progress.correctAns} />
                            <ParaSpanBold title="Wrong answers: " body={progress.wrongAns} />
                        </div>}
                        <div className="col-md-2">
                            <div className="text-center">
                                {user.photoUrl ? (
                                    <img alt={user.name}
                                        className="avatar rounded-square"
                                        src={user.photoUrl} />
                                ) : (
                                        <span>no image</span>
                                    )}
                                {/* {user.photoUrl ? (
                                <Image
                                    className="avatar"
                                    src={user.photoUrl}
                                    roundedCircle
                                />
                            ) : (
                                    <span>no image</span>
                                )} */}
                                {/* <FetchAvatarImg width={"166px"} imgName={userId} /> */}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <h4 className="text-center mb-4">Last 7 days' report</h4>
                {/* <h3 className="text-center">Progress, Exams, Results will appear here...<Spinner animation="grow" variant="primary" /></h3> */}
                {dailyReport && dailyReport.map(item => <ComponentDailyUserCard user={userId} details={item} />)}
                <div className="text-center py-5">
                    {loading && <SectionSpinner />}
                    <Button onClick={handleFetchMore}>Load more...</Button>
                </div>
            </Container>
        </>
    );
};

export default UserDetails;