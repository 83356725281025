import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MedAddContentClass from '../../Pages/MedicalAdmission/_MedAddContentClass';
import CrashContentModal from './CrashContentModal';

const CrashLiveClassCard = ({ data }) => {
        const [modalShow, setModalShow] = useState(false);

        return (
            <>
                <div className="col-md-12">
                    <div className="crash-card p-4">
                        <div className="row d-flex align-items-center">
                            <div className="col-5">
                                <p className="mb-1">
                                    <span className="number mr-3">{data.serialNumber}</span>   <b>{data.type}</b>
                                </p>
                            </div>
                            <div className="col-4 d-flex justify-content-around ">
                                {
                                    data.isPremium ? <span className="font-bold text-danger">Premium Class</span> : <span className="text-primary">Free Class</span>
                                }
                                {
                                    data.isPublished ? <span className="font-bold text-danger">Published</span> : <span className="text-primary">Unpublished</span>
                                }
                                {/* <span className="text-primary"><b>Class ID: </b>{data.liveClassId}</span> */}
                            </div>
                            <div className="col-3 d-flex justify-content-around">
                                <Link className='btn btn-sm btn-primary' to={`/live-classes/edit/${data.liveClassId}`}>
                                    Edit Class
                                </Link>
                                <Button size='sm' variant="danger" onClick={() => setModalShow(true)}>
                                    Edit Content
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <CrashContentModal show={modalShow} onHide={() => setModalShow(false)}>
                    <MedAddContentClass defaultData={data} />
                </CrashContentModal>
            </>
        );
    };

export default CrashLiveClassCard
    ;