
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { emptyElementRemover, emptyElementRemoverLinks } from '../../../../utils/useful-modules';
import { useCACourseModule } from '../useCACourseModule';
import PreviewTheory from './PreviewTheory';

const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const TypeTheory = () => {
    const { register, handleSubmit } = useForm();
    const { currentSub, currentChap, currentType } = useCACourseModule();
    const [sizeVideos, setSizeVideos] = useState(1);
    const [sizeQuestions, setSizeQuestions] = useState(1);
    const [lectureSheets, setLectureSheets] = useState(1);
    const [links, setLinks] = useState(1);
    const moduleRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection('CollegeAdmission').doc(currentChap).collection("Theory");


    const onModuleSubmitted = (data, e) => {
        data.serialNumber = Number(data.serialNumber)
        data.videos.shift()
        data.videos = emptyElementRemover(data.videos)
        data.questions.shift()
        data.questions = emptyElementRemover(data.questions)
        data.lectureSheets.shift()
        data.lectureSheets = emptyElementRemoverLinks(data.lectureSheets)
        data.links.shift()
        data.links = emptyElementRemoverLinks(data.links)
        console.log(data);
        console.log(currentChap);
        if (currentChap) {
            moduleRef.add(data).then(() => {
                console.log("added");
                e.target.reset();
            })
        } else {
            // alert(`Subject: ${currentSub}; and Chapter: ${currentChap} don't match! `)
            alert("Please choose the right CHAPTER!");
        }

    }
    return (
        <>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-5 border-right">
                        <h4 className="text-left mb-4">You are adding <span className="bg-danger text-white px-3">Theory</span>  videos</h4>
                        <form onSubmit={handleSubmit(onModuleSubmitted)} >
                            <div className="row">
                                <InputWrapper col="6" label="Sl. No.">
                                    <input ref={register({
                                        required: true,
                                    })} type="num" name="serialNumber" placeholder="Serial no.." className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="10" label="Topic name">
                                    <input ref={register({
                                        required: true,
                                    })} type="text" name="name" placeholder="Topic name" className="form-control" />
                                </InputWrapper>
                                <div className="col-12">
                                    <h5 className="mt-4">Add videos here</h5>
                                </div>
                                <div className="col-md-10">
                                    {createArrayWithNumbers(sizeVideos).map(
                                        (index) => {
                                            return (

                                                <input
                                                    className="form-control mb-2 "
                                                    name={`videos[${index}]`}
                                                    placeholder={
                                                        "Put video id here "
                                                    }
                                                    ref={register()}
                                                />

                                            );
                                        }
                                    )}
                                </div>
                                <div className="col-10 text-right">
                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeVideos(sizeVideos + 1)} >
                                        Add more
                                    </button>
                                </div>
                                {/* questions */}
                                <div className="col-12">
                                    <h5 className="mt-4">Add questions here</h5>
                                </div>
                                <div className="col-md-10">
                                    {createArrayWithNumbers(sizeQuestions).map(
                                        (index) => {
                                            return (

                                                <input
                                                    className="form-control mb-2 "
                                                    name={`questions[${index}]`}
                                                    placeholder={
                                                        "Put question id here "
                                                    }
                                                    ref={register()}
                                                />


                                            );
                                        }
                                    )}
                                </div>
                                <div className="col-10 text-right">
                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeQuestions(sizeQuestions + 1)} >
                                        Add more
                                    </button>
                                </div>

                                {/*  Lecture Sheets */}
                                <div className="col-12">
                                    <h5 className="mt-4">Add Lecture Sheets here</h5>
                                </div>
                                {createArrayWithNumbers(lectureSheets).map(
                                    (index) => {
                                        return (
                                            <>
                                                <InputWrapper col="10" addClass=" mb-md-1" label={`Title ${index}`}>


                                                    <input
                                                        className="form-control  "
                                                        name={`lectureSheets[${index}].title`}
                                                        placeholder={
                                                            "Put sheet title"
                                                        }
                                                        ref={register()}
                                                    />
                                                </InputWrapper>
                                                <InputWrapper col="10" addClass=" mb-3" label={`Link ${index}`}>
                                                    <input
                                                        className="form-control "
                                                        name={`lectureSheets[${index}].link`}
                                                        placeholder={
                                                            "Put sheet link "
                                                        }
                                                        type='link'
                                                        ref={register()}
                                                    /></InputWrapper>

                                            </>

                                        );
                                    }
                                )}
                                <div className="col-10 text-right">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setLectureSheets(lectureSheets + 1)} >
                                        Add more
                                    </button>
                                </div>

                                {/*  Links */}
                                <div className="col-12">
                                    <h5 className="mt-4">Add Links here</h5>
                                </div>
                                {createArrayWithNumbers(links).map(
                                    (index) => {
                                        return (
                                            <>
                                                <InputWrapper col="10" addClass=" mb-md-1" label={`Title ${index}`}>


                                                    <input
                                                        className="form-control  "
                                                        name={`links[${index}].title`}
                                                        placeholder={
                                                            "Put sheet title"
                                                        }
                                                        ref={register()}
                                                    />
                                                </InputWrapper>
                                                <InputWrapper col="10" addClass=" mb-3" label={`Link ${index}`}>
                                                    <input
                                                        className="form-control "
                                                        name={`links[${index}].link`}
                                                        placeholder={
                                                            "Put sheet link "
                                                        }
                                                        type='link'
                                                        ref={register()}
                                                    /></InputWrapper>

                                            </>

                                        );
                                    }
                                )}
                                <div className="col-10 text-right">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setLinks(links + 1)} >
                                        Add more
                                    </button>
                                </div>

                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary">Add Lesson</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-7">
                        {/* <h4 className="mb-4">Lesson Preview</h4> */}
                        <PreviewTheory />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TypeTheory;

