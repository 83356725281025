import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import PageSpinner from "../../Components/Spinner/PageSpinner";
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { fireStoreDB } from "../../firebase/fire-app";
import { splitString } from "../../utilities";
import { timeStampConverter } from "../../utils/useful-modules";
const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};
const db = fireStoreDB;
const examCollectionRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam");
const EditLiveExam = () => {
    const { examId } = useParams();
    const history = useHistory();
    const examIdInfo = splitString(examId, "-");
    const examRef = examCollectionRef.doc(examId);
    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [questionCount, setQuestionCount] = useState(0);
    const [videoCount, setVideoCount] = useState(null);
    const [videoExists, setVideoExists] = useState(false);

    const updateVideoCount = () => {
        if (!videoCount) {
            setVideoCount(false)
        }
    }

    document.title = "Edit " + examId;
    // fetching exam
    useEffect(() => {
        const fetchExam = () => {
            examRef
                .get()
                .then((doc) => {
                    let previousData = doc.data();
                    setExamDetails(doc.data());
                    setLoading(false);
                    setQuestionCount(doc.data().numberOfQuestions);
                    if (previousData.videos) {
                        setVideoCount(doc.data().videos.length);
                        setVideoExists(true)
                    }
                })
                .catch((error) => console.log(error));
        };
        fetchExam();
    }, []);

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data, e) => {
        setLoading(true)
        data.questionList.shift();
        if(data.videos){
            data.videos.shift()
        }
        
        const newExam = {};
        newExam.title = data.title;
        newExam.subTitle = data.subTitle;
        newExam.level = data.level;
        newExam.startAt = new Date(data.startAt ? data.startAt : examDetails.startAt.seconds * 1000);
        newExam.endAt = new Date(data.endAt ? data.endAt : examDetails.endAt.seconds * 1000);
        newExam.numberOfQuestions = Number(data.numberOfQuestions);
        newExam.timeInMinutesForEachQuestion = Number(
            data.timeInMinutesForEachQuestion
        );
        newExam.positiveMarks = Number(data.positiveMarks);
        newExam.negativeMarks = Number(data.negativeMarks);
        newExam.questionList = data.questionList;
        newExam.isPublished = (data.isPublished ? (data.isPublished == "true") : examDetails.isPublished);
        newExam.isPremium = (data.isPremium ? (data.isPremium == "true") : examDetails.isPremium);
        if(data.videos){
            if(data.videos[0])
            newExam.videos = data.videos;
        }
        // console.log(newExam);
        examRef.set(newExam).then((res) => {
            setLoading(false)
            e.target.reset();
            history.push("/liveExams/details/" + examId)
        }).catch(err => {
            setLoading(false);
            alert(err)
        })
    };

    return (
        <div>
            <h3 className="text-center mt-4">Edit exam: {examId}</h3>
            {loading && <SectionSpinner />}
            {examDetails && <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-3">
                        <Row>
                            <InputWrapper col={4} label="Title">
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    placeholder="Exam title"
                                    defaultValue={examDetails.title}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Sub Title:">
                                <input
                                    name="subTitle"
                                    id="subTitle"
                                    type="text"
                                    placeholder="Exam sub title"
                                    className="form-control"
                                    defaultValue={examDetails.subTitle}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Level">
                                <select
                                    className="custom-select"
                                    name="level"
                                    id="level"
                                    ref={register({
                                        required: true,
                                    })}
                                >
                                    <option selected>
                                        {examDetails.level}
                                    </option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={6} label="Starts at">
                                <input
                                    type="text"
                                    name="startAt"
                                    className="form-control"
                                    defaultValue={
                                        new Date(examDetails.startAt.seconds * 1000)
                                    }
                                    disabled
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="New start time: ">
                                <input
                                    type="datetime-local"
                                    name="startAt"
                                    className="form-control"
                                    ref={register()}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Ends at">
                                <input
                                    type="text"
                                    name="endAt"
                                    id="endAt"
                                    className="form-control"
                                    defaultValue={
                                        new Date(examDetails.endAt.seconds * 1000)
                                    }
                                    disabled
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="New end time: ">
                                <input
                                    type="datetime-local"
                                    name="endAt"
                                    className="form-control"
                                    ref={register()}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Num. of Questions">
                                <input
                                    type="number"
                                    name="numberOfQuestions"
                                    className="form-control"
                                    defaultValue={examDetails.numberOfQuestions}
                                    onBlur={(e) => {
                                        setQuestionCount(e.target.value);
                                    }}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Time in min. for each question."
                            >
                                <input
                                    type="num"
                                    name="timeInMinutesForEachQuestion"
                                    className="form-control"
                                    defaultValue={
                                        examDetails.timeInMinutesForEachQuestion
                                    }
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Positive marks per question"
                            >
                                <input
                                    type="num"
                                    name="positiveMarks"
                                    className="form-control"
                                    defaultValue={examDetails.positiveMarks}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Negative marks per question"
                            >
                                <input
                                    type="num"
                                    name="negativeMarks"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={examDetails.negativeMarks}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Is Published?"
                            >
                                <select name="isPublished" className="custom-select" ref={register()}  >
                                    <option value={examDetails.isPublished}>{examDetails.isPublished ? "True" : "False"}</option>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Premium Exam"
                            >
                                <select name="isPremium" className="custom-select" ref={register()}  >
                                    <option value={examDetails.isPremium}>{examDetails.isPremium ? "True" : "False"}</option>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </InputWrapper>
                            <div className="col-md-6 text-center mt-3">
                                <h4>Exam Questions</h4>
                                <div className="row">
                                    {createArrayWithNumbers(questionCount).map(
                                        (index) => {
                                            return (
                                                <InputWrapper
                                                    addClass=" "
                                                    col={12}
                                                    label={"ID for question: " + index}
                                                >
                                                    <input
                                                        className="form-control"
                                                        name={`questionList[${index}]`}
                                                        placeholder={
                                                            "Put question ID of " +
                                                            index
                                                        }
                                                        defaultValue={
                                                            examDetails.questionList[
                                                            index - 1
                                                            ]
                                                        }
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                </InputWrapper>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                            {/* videos */}
                            {
                                videoExists && <div className="col-md-6 text-center mt-3">
                                    <h4>Related Videos</h4>
                                    <div className="row">
                                        {createArrayWithNumbers(videoCount).map(
                                            (index) => {
                                                return (
                                                    <InputWrapper
                                                        addClass=" "
                                                        col={12}
                                                        label={"ID for video: " + index}
                                                    >
                                                        <input
                                                            className="form-control"
                                                            name={`videos[${index}]`}
                                                            placeholder={
                                                                "Put video ID of " +
                                                                index
                                                            }
                                                            defaultValue={
                                                                examDetails.videos[
                                                                index - 1
                                                                ]
                                                            }
                                                            ref={register()}
                                                        />
                                                    </InputWrapper>
                                                );
                                            }
                                        )}
                                        <div className="col-12 text-center"><Button onClick={() => setVideoCount(videoCount + 1)} className="btn btn-primary btn-sm">Add more..</Button> </div>

                                    </div>
                                </div>
                            }
                            {(!videoCount && !videoExists) ?
                            <div className="col-md-6 text-center mt-3">
                                <Button onClick={() => setVideoCount(1)} className="btn btn-primary">Add videos</Button>
                                </div>
                                :
                                <>{!videoExists && <div className="col-md-6 text-center mt-3">
                                    <h4>Related Videos</h4>
                                    <div className="row">
                                        {createArrayWithNumbers(videoCount).map(
                                            (index) => {
                                                return (
                                                    <>
                                                        <InputWrapper
                                                            addClass=" "
                                                            col={12}
                                                            label={"ID for video: " + index}
                                                        >
                                                            <input
                                                                className="form-control"
                                                                name={`videos[${index}]`}
                                                                placeholder={
                                                                    "Put video ID of " +
                                                                    index
                                                                }
                                                                ref={register()}
                                                            />
                                                        </InputWrapper>

                                                    </>
                                                );
                                            }
                                        )}
                                        <div className="col-12 text-center"><Button onClick={() => setVideoCount(videoCount + 1)} className="btn btn-primary btn-sm">Add more..</Button> </div>
                                    </div>
                                </div>}</>
                            }

                        </Row>
                        <div className="text-center">
                            <Button type="submit" center>
                                Submit Form
                        </Button>
                        </div>
                    </div>
                </form>
            </Container>}
        </div>
    );
};

export default EditLiveExam;
const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};
