import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import SectionSpinner from '../../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../../firebase/fire-app';
import { courseSubjects, videoType } from '../AddVideo/data';
import { useCourseVideo } from '../AddVideo/useCollegeAdmissionCourse';
import "./ViewAllVideos.scss"
import { CopyToClipboard } from 'react-copy-to-clipboard';


const CAViewAllVideos = () => {
    const [subject, setSubject] = useState("CollegeAdmission-BIO");
    const [chapter, setChapter] = useState();
    const [type, setType] = useState("Outline");
    const [lesson, setLesson] = useState(null);
    const subjectCollectionRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection("CollegeAdmission");
    const [subjects, subjectsLoading, chapError] = useCollectionData(subjectCollectionRef, { idField: "id" });

    // let courseVideoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").where("subject", "==", subject).where("chapter", "==", chapter).where("type", "==", type).limit(10);
    let courseVideoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").where("subject", "==", subject).where("type", "==", type).orderBy("createdAt", "desc");
    if(chapter){
        courseVideoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").where("subject", "==", subject).where("chapter", "==", chapter).where("type", "==", type).orderBy("createdAt", "desc");
    }
    if (lesson) {
        courseVideoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").where("subject", "==", subject).where("chapter", "==", chapter).where("type", "==", type).where("lesson", "==", lesson).orderBy("createdAt", "desc");

    }
    // if (lesson) {
    //     courseVideoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").where("subject", "==", subject).where("chapter", "==", chapter).where("type", "==", type).where("lesson", "==", lesson).limit(10);

    // }
    // const [vidLoading, setVidLoading] = useState(true);
    // const [vidError, setVidError] = useState(true);
    // const [courseVideos, setcourseVideos] = useState(null)
    const [courseVideos, vidLoading, vidError] = useCollectionData(courseVideoColRef, { idField: "id" });

    // useEffect(() => {
    //     courseVideoColRef.get()
    //         .then((querySnapshot) => {
    //             querySnapshot.forEach((doc) => {
    //                 // doc.data() is never undefined for query doc snapshots
    //                 console.log(doc.id, " => ", doc.data());
    //             });
    //         })
    //         .catch((error) => {
    //             console.log("Error getting documents: ", error);
    //         });

    // }, [type, lesson])

    const onSubjectChanged = (e) => {
        console.log(e.target.value);
        setSubject(e.target.value)
    }

    const onChapterChanged = (e) => {
        const newChap = e.target.value;
        setChapter(e.target.value?Number(e.target.value):0);
        console.log(e.target.value);
    }

    const onTypeChanged = (e) => {
        console.log(e.target.value);
        setType(e.target.value)
    }
    const basicLog = () => {
        console.log(subject, chapter, type);
    }

    return (
        <>
            {/* <div className="text-center">
                <button onClick={() => console.log(courseVideos)} className="btn btn-primary">Log</button>
            </div> */}
            <div className="container py-3">
                {/* <button className="btn btn-primary" onClick={basicLog} >log</button> */}
                <h3 className="text-center">{subject} {` | `} {chapter} {` | `} {type}{` | `} {lesson} </h3>
                <div className="row">
                    <InputWrapper col={3} label="Subject">
                        <select onChange={onSubjectChanged} className="custom-select" name="subject">
                            {/* <option value={subject}>{subject}</option> */}
                            {subjects && subjects.map(item => <option value={item.id} >{item.name} </option>)}

                        </select>
                    </InputWrapper>
                    <InputWrapper col={3} label="Chapter">
                        {/* <input type="num" onChange={(e)=> setChapter(e.target.value)} className="form-control"/> */}
                        <select onChange={onChapterChanged} className="custom-select" name="subject">
                            {/* <option value={chapter ? chapter : ""}>{chapter ? chapter : "Choose chapter.."}</option> */}
                            <option value="">Choose chapter</option>
                            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item => <option value={item} >{item} </option>)}
                        </select>
                    </InputWrapper>
                    <InputWrapper col={3} label="Type">
                        <select onChange={onTypeChanged} className="custom-select" name="type" >
                            <option value={type ? type : ""}>{type ? type : "Choose type.."}</option>
                            {videoType && videoType.map(item => <option value={item} >{item} </option>)}
                        </select>
                    </InputWrapper>
                    <InputWrapper col={2} label="Lesson">
                        <input onChange={(e) => setLesson(e.target.value)} type="text" className="form-control" name="lesson" placeholder="lesson..." />
                    </InputWrapper>
                </div>
            </div>
            <div className="container">
                {!vidLoading && !vidError && <div>
                    {courseVideos.length < 1 && <h2 className="text-center text-danger" >No video found</h2>}
                </div>}
                {vidLoading && <SectionSpinner />}
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {!vidLoading && !vidError && courseVideos.map((item, index) => <LessonPreview key={item.id} index={index} lesson={item} />)}
                    </div>
                </div>

            </div>
        </>
    );
};

export default CAViewAllVideos;

const LessonPreview = ({ lesson, index }) => {
    const videoColRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos");
    const handleDeleteExam = () => {
        videoColRef
            .doc(lesson.id)
            .delete()
            .then((res) => {
                console.log("deleted");
            });
    };
    const onVideoAdded = () => {
        videoColRef.doc(lesson.id).update({
            addedDB: !lesson.addedDB
        })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
    }
    return (
        <div className="lesson-container mb-3">
            <div className="row d-flex align-items-center">
                <div className="col-md-6 p-4 ">
                    <h4><span className="text-primary">{`(${index + 1}) `}</span> {lesson.name} </h4>
                    {/* <button onClick={() => console.log(lesson)} className="btn btn-sm btn-warning">Log</button> */}
                    <div className="d-flex mb-2">
                        <p><span className="btn btn-danger btn-sm mr-4">{lesson.type} </span> </p>
                        <p> {lesson.important ? <span className="btn btn-danger btn-sm">Important</span> : <span className="btn btn-primary btn-sm">Not Important</span>} </p>
                    </div>

                    <p>Subject: {lesson.subject} </p>
                    <p>Chapter: {lesson.chapter} </p>
                    <p className="mt-2">
                        <strong>Description:</strong>{lesson.description}
                    </p>
                    <div className="py-3 text-center">
                        <Link to={`/edit-lesson/${lesson.id}`} className="btn btn-primary mr-3" >Edit this lesson</Link>
                        <button onClick={() => handleDeleteExam()} className="btn-danger btn">Delete</button>
                    </div>
                </div>
                <div className={`col-md-6 p-4 ${lesson.addedDB ? "bg-danger" : "bg-primary"}`}>
                    <div className="text-center mb-3 d-flex justify-content-between">
                        <CopyToClipboard text={lesson.id}>
                            <button className="btn btn-sm btn-warning" >Click to copy id</button>
                        </CopyToClipboard>
                        <button onClick={() => onVideoAdded()} className="btn btn-dark">{lesson.addedDB ? "Already Added" : "Not Added"}</button>
                    </div>
                    <iframe title="Course Video" src={`https://player.vimeo.com/video/${lesson.vimeo_id}`} className="w-100" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

                </div>
            </div>
        </div>
    )
}