import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { emptyElementRemover } from '../../../../utils/useful-modules';
import { useCACourseModule } from '../useCACourseModule';
import PreviewShortTechnique from './PreviewShortTechnique';


const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const TypeShortTechnique = () => {
    const [count, setCount] = useState(1);
    const { register, handleSubmit } = useForm();
    const { currentSub, currentChap, currentType } = useCACourseModule()
    const moduleRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection(currentSub).doc(currentChap).collection("ShortTechnique").doc("shortTechnique");
    const onModuleSubmitted = (data, e) => {
        console.log(data);
        data.videos.shift();
        data.videos = emptyElementRemover(data.videos)
        if (currentSub.slice(0, 2) === currentChap.slice(0, 2)) {
            moduleRef.set(data).then(() => {
                console.log("updated");
                e.target.reset();
            })
        } else {
            // alert(`Subject: ${currentSub}; and Chapter: ${currentChap} don't match! `)
            alert("Please choose the right CHAPTER!");
        }

    }
    return (
        <>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-4 border-right">
                        <h4 className="text-left mb-4">You are adding <span className="bg-danger text-white px-3">short technique </span> videos</h4>
                        <form onSubmit={handleSubmit(onModuleSubmitted)}>
                            <div className="row px-3">
                                {createArrayWithNumbers(count).map(
                                    (index) => {
                                        return (

                                            <input
                                                className="form-control mb-2 "
                                                name={`videos[${index}]`}
                                                placeholder={
                                                    "Put video id here "
                                                }
                                                ref={register()}
                                            />

                                        );
                                    }
                                )}
                                <div className="col-12 text-right">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setCount(count + 1)} >
                                        Add more
                                    </button>
                                </div>
                                <div className="col-md-12">
                                    <button className="btn-primary btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-7">
                        <PreviewShortTechnique />
                        {/* <h4 className="mb-4">Lesson Preview</h4> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TypeShortTechnique;