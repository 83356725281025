import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { fireStoreDB } from '../../firebase/fire-app';

const ComponentCourseVideoPreview = ({ vidId }) => {
    const [vidLoading, setVidLoading] = useState(true);
    const [video, setVideo] = useState(null);
    let courseVideoCollection;
    if (vidId) {
        courseVideoCollection = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").doc(vidId);
    }
    useEffect(() => {
        // setVidLoading(true)
        if (vidId) {
            courseVideoCollection.onSnapshot((doc) => {
                setVideo(doc.data());
                setVidLoading(false);
            })
        }

    }, [courseVideoCollection, vidId])
    return (
        <>
            {vidId && <>
                { vidLoading && <Spinner animation="border" />}

                {video ? <iframe title="Course Video" src={`https://player.vimeo.com/video/${video.vimeo_id}`} className="mt-3" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> :
                    <h4 className="text-center text-danger">Video not found</h4>
                }
            </>}
        </>
    );
};

export default ComponentCourseVideoPreview;