import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import LiveExamCard from "../../Components/LiveExamList/LiveExamCard";
import PageSpinner from "../../Components/Spinner/PageSpinner";
import { fireStoreDB } from "../../firebase/fire-app";

const db = fireStoreDB;
const examCollectionRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam");

const LiveExamList = () => {
    const [liveExams, setLiveExams] = useState([]);
    const [loading, setLoading] = useState(true);
    document.title = "All Live Exams";
    useEffect(() => {
        const fetchLiveExams = async () => {
            const examsCollection = await examCollectionRef.orderBy("startAt", "desc").get();
            setLiveExams(
                examsCollection.docs.map((doc) => {
                    return { id: doc.id, data: doc.data() };
                })
            );
            setLoading(false);
        };
        fetchLiveExams();
    }, []);

    if (loading) {
        return <PageSpinner />;
    }
    return (
        <div className="mt-3">
            <Container>
                {liveExams.map((exam) => (
                    <LiveExamCard
                        key={exam.id}
                        id={exam.id}
                        examDetails={exam.data}
                    />
                ))}
            </Container>
        </div>
    );
};

export default LiveExamList;
