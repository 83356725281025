import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FetchAvatarImg from "../../Components/FetchAvatarImg/FetchAvatarImg";
import fireApp from "../../firebase/fire-app";
import "./UserCardComponent.scss";

const UserCardComponent = (props) => {
    const userId = props.userInfo.id;
    // let name, phone, email, district, college, collegeId;
    // if(props.userInfo.data){

    // }
    const {
        name,
        phone,
        email,
        district,
        college,
        collegeId,
        photoUrl
    } = props.userInfo;
    const [imgLink, setImgLink] = useState(null);


    return (
        <>
            {props.userInfo && <Card className="p-4 pr-0 mb-3">
                <Row>
                    <Col md={2}>
                        {/* <FetchAvatarImg imgName={userId} /> */}
                        {photoUrl ? (
                            <img alt={name}
                                className="avatar rounded-square"
                                src={photoUrl} />
                        ) : (
                                <span>no image</span>
                            )}
                        {/* {photoUrl ? (
                            <Image
                                className="avatar"
                                src={photoUrl}
                                roundedCircle
                            />
                        ) : (
                                <span>no image</span>
                            )} */}
                    </Col>
                    <Col md={4}>
                        <h4>{name}</h4>
                        <p className="font-weight-bold ">{userId}</p>
                        <p>{email} </p> <p>{phone} </p>
                        <p>District: {district} </p>
                    </Col>
                    <Col md={4}>
                        <h4>&nbsp;</h4>
                        <p>College: {college} </p>
                        <p>
                            College ID:<strong> {collegeId}</strong>
                        </p>
                    </Col>
                    <Col md={2}>
                        <h4>&nbsp;</h4>
                        <Link
                            className="btn btn-primary btn-sm btn-block"
                            to={"/users/details/" + userId}
                            size="sm"
                            block
                        >
                            View Profile
                        </Link>
                        <Link className="btn btn-sm btn-danger btn-block"
                            to={"/users/edit/" + userId}
                        >
                            Edit Profile
                        </Link>
                    </Col>
                </Row>
            </Card>}
        </>
    );
};

export default UserCardComponent;
