import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ComponentCourseVideoPreview from '../../../../Components/CourseVideoPreviewComponent/ComponentCourseVideoPreview';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { emptyElementRemover } from '../../../../utils/useful-modules';
import { useCourseModule } from '../useCourseModule';

const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const PreviewShortTechnique = () => {
    const [showPositionVideo, setShowPositionVideo] = useState(false);
    const { currentSub, currentChap, currentType } = useCourseModule();
    const { register, handleSubmit } = useForm()
    const { register: register1, handleSubmit: handleSubmit1 } = useForm();

    const boardQuestionsDocRef = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("ShortTechnique").doc("shortTechnique");
    const [loadingVideos, setLoadingVideos] = useState(true);
    const [sizeVideos, setSizeVideos] = useState(1)
    const [videos, setVideos] = useState(null);

    const onVideosUpdated = (data, e) => {
        data.videos = emptyElementRemover(data.videos);
        let oldVideos = data.videos;
        boardQuestionsDocRef.update({
            videos: data.videos
        }).then(() => {
            console.log("updated")
            setVideos(null);
            setSizeVideos(1)
            setLoadingVideos(true);
            e.target.reset();
            setShowPositionVideo(false);
            setTimeout(() => {
                setVideos(oldVideos);
                setSizeVideos(oldVideos.length)
                setLoadingVideos(false);
            }, 800)
        })
    }

    useEffect(() => {

        boardQuestionsDocRef.onSnapshot((doc) => {
            if (doc.data()) {
                setVideos(doc.data().videos);
                setSizeVideos(doc.data().videos.length)
                setLoadingVideos(false);
            }
        })
    }, [])
    const deleteLesson = () => {
        // console.log(lessonId);
        // theoryCollection.doc(lessonId).delete().then(() => {
        //     console.log("document deleted");
        // }).catch(error => {
        //     console.error("Error removing lesson: ", error)
        // })
    }
    const onVideoAddedPosition = (data, e) => {
        let oldVideos = videos;
        oldVideos.splice(data.position, 0, data.vId);
        console.log(oldVideos);
        boardQuestionsDocRef.update({
            videos: oldVideos
        }).then(() => {
            console.log("updated")
            setVideos(null);
            setSizeVideos(1)
            setLoadingVideos(true);
            e.target.reset();
            setShowPositionVideo(false);
            setTimeout(() => {
                setVideos(oldVideos);
                setSizeVideos(oldVideos.length)
                setLoadingVideos(false);
            }, 800)
        })
    }
    return (
        <>
            {loadingVideos && <Spinner animation="grow" variant="primary" />}
            <div className="row">
                <div className="col-10">
                    <hr />
                    <div className="d-flex justify-content-between">
                        <button onClick={() => deleteLesson()} className="btn-danger btn">Delete </button>
                        <button onClick={() => setShowPositionVideo(!showPositionVideo)} className="btn-dark btn">Insert video at position?</button>
                    </div>
                    {showPositionVideo && <form onSubmit={handleSubmit1(onVideoAddedPosition)}>
                        <div className="row">
                            <InputWrapper col="3" label="Position">
                                <input name="position" ref={register1({ required: true })} type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="6" label="V. Id">
                                <input name="vId" ref={register1({ required: true })} type="text" className="form-control" />
                            </InputWrapper>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </div>
                    </form>}
                </div>
                <div className="col-md-10">
                    {!loadingVideos && videos && <form onSubmit={handleSubmit(onVideosUpdated)}>
                        <div className="row">
                            <div className="col-10">
                                {createArrayWithNumbers(sizeVideos).map(
                                    (index) => {
                                        return (
                                            <>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-7">
                                                        <h4>{index - 1}</h4>
                                                        <input
                                                            className="form-control mb-2 d-inline-block "
                                                            name={`videos[${index}]`}
                                                            placeholder={
                                                                "Put video id here "
                                                            }
                                                            ref={register()}
                                                            defaultValue={videos[index - 1] ? videos[index - 1] : ""}
                                                        // defaultValue={topicVideos[index - 1]}
                                                        />
                                                    </div>
                                                    <div className="col-5">
                                                        {videos[index - 1] && <ComponentCourseVideoPreview vidId={videos[index - 1]} />}
                                                    </div>


                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>
                            <div className="col-10 text-right">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeVideos(sizeVideos + 1)} >
                                    Add more
                                </button>
                            </div>
                            <div className="col-4 pb-5">
                                <button type="submit" className="btn btn-primary">Update Lesson</button>
                            </div>
                        </div>
                    </form>}
                </div>
            </div>

        </>
    );
};

export default PreviewShortTechnique;