import React from 'react';
import { useParams } from 'react-router';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import ComponentSidebar from './Components/ComponentSidebar';
import TypeBoardQuestions from './Components/TypeBoardQuestions';
import TypeMathSolve from './Components/TypeMathSolve';
import TypeOutline from './Components/TypeOutline';
import TypeShortTechnique from './Components/TypeShortTechnique';
import TypeTheory from './Components/TypeTheory';
import "./Styles.scss"
import { useCACourseModule } from './useCACourseModule';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { useCollegeAdmissionCourse } from '../AddVideo/useCollegeAdmissionCourse';

const CAAddCourseModule = () => {
    const { subId } = useParams();
    const { currentType, setCurrentChap, currentChap, setCurrentSub } = useCACourseModule();

    const { setRequestedSubject, requestedChapters, reqChapLoading } = useCollegeAdmissionCourse();
    setRequestedSubject(subId);
    setCurrentSub(subId)
    const onChapterChanged = (e) => {
        console.log(e.target.value);
        setCurrentChap(e.target.value)
    }
    const updateTypeStatus = (e) => {
        console.log(e);
        console.log(currentChap);
    }
    return (
        <>
            <div className="d-flex" id="wrapper">
                <div className="bg-light border-right sidebar-wrapper">
                    <ComponentSidebar />
                </div>
                <div className="page-content-wrapper">
                    <div className="py-3 bg-light align-items-center border-bottom px-5 d-flex justify-content-between">
                        <div className="w-50">
                            <div className="row d-flex align-items-center" >
                                {/* <div className="col-md-3">

                                    <RadioGroup onChange={(e) => updateTypeStatus(e)} horizontal>
                                        <RadioButton pointColor="#075e54" rootColor="#CCCCCC" value="true">
                                            Active
                                        </RadioButton>
                                        <RadioButton pointColor="#075e54" rootColor="#CCCCCC" value="cq">
                                            No
                                        </RadioButton>
                                    </RadioGroup>

                                </div> */}
                                <div className="col-md-6"><h4>Course: {subId} </h4></div>
                            </div>

                        </div>
                        <div className="w-50 pr-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <select onChange={onChapterChanged} className="custom-select" name="subject">
                                        <option value=""> Choose subject..</option>
                                        {requestedChapters && requestedChapters.map(item => <option value={item.id} >{item.name} </option>)}
                                    </select>
                                </div>
                            </div>

                            {/* <select name="chapter" className="">
                                <option value="CH01">CH01</option>
                                <option value="CH01">PH01</option>
                                <option value="CH01">MT01</option>
                            </select> */}
                        </div>

                    </div>
                    {currentType === "Outline" && <TypeOutline />}
                    {currentType === "ShortTechnique" && <TypeShortTechnique />}
                    {currentType === "BoardQuestions" && <TypeBoardQuestions />}
                    {currentType === "Theory" && <TypeTheory />}
                    {currentType === "MathSolve" && <TypeMathSolve />}
                </div>

            </div>

        </>
    );
};

export default CAAddCourseModule;