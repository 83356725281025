import React from 'react';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { fireStoreDB } from '../../../firebase/fire-app';
import CompSubjects from './CompSubjects';
import CompVideoDetails from './CompVideoDetails';
import { useCourseVideo } from './useCourseVideo';




const AddCourseVideo = () => {

    const { subject, setSubject, chapter, setChapter, chapters, chapLoading } = useCourseVideo();
    const basicLog = () => {
        console.log(chapters);
    }

    return (
        <>
            <CompSubjects />
            {!chapLoading && <CompVideoDetails />}
        </>
    );
};

export default AddCourseVideo;