import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FetchAvatarImg from "../Components/FetchAvatarImg/FetchAvatarImg";
import PageSpinner from "../Components/Spinner/PageSpinner";
import { fireStoreDB } from "../firebase/fire-app";
import { useUsers } from "../Hooks/useUsers";
const db = fireStoreDB;
const EditUser = () => {
    const { userId } = useParams();
    document.title = "Edit user: " + userId;
    // hook form
    const [user, setUser] = useState(null);
    useEffect(() => {
        const fetchUser = async () => {
            const userInfo = await db
                .collection("metadata")
                .doc("userMetadata")
                .collection("userInfo")
                .doc(userId)
                .get();

            setUser(userInfo.data());
        };
        fetchUser();
    }, []);

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data) => {
    };
    if (!user) {
        return <PageSpinner />;
    }

    return (
        <div>
            <Container>
                <Row className="d-flex align-items-center py-4">
                    <Col>
                        <h4>Edit User: {userId} </h4>
                    </Col>
                    <Col md={3}>
                        {user.photoUrl ? (
                            <img alt={user.name}
                                className="avatar rounded-square"
                                src={user.photoUrl} />
                        ) : (
                                <span>no image</span>
                            )}
                        {/* <FetchAvatarImg imgName={userId} /> */}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <Row>
                            <InputWrapper col={6} label="Name : ">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    defaultValue={user.name}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Date of Birth : ">
                                <input
                                    type="text"
                                    name="dob"
                                    className="form-control"
                                    defaultValue={user.dob}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Email : ">
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    defaultValue={user.email}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Phone : ">
                                <input
                                    type="tel"
                                    name="phone"
                                    className="form-control"
                                    defaultValue={user.phone}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="District : ">
                                <input
                                    type="text"
                                    name="district"
                                    className="form-control"
                                    defaultValue={user.district}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="SSC : ">
                                <input
                                    type="text"
                                    name="ssc"
                                    className="form-control"
                                    defaultValue={user.ssc}
                                    ref={register({})}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="HSC : ">
                                <input
                                    type="text"
                                    name="hsc"
                                    className="form-control"
                                    defaultValue={user.hsc}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="College : ">
                                <input
                                    type="text"
                                    name="college"
                                    className="form-control"
                                    defaultValue={user.college}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="College ID : ">
                                <input
                                    type="text"
                                    name="collegeId"
                                    className="form-control"
                                    defaultValue={user.collegeId}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                        </Row>
                        <div className="text-center">
                            <Button type="submit" center>
                                Submit Form
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default EditUser;

export const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};
