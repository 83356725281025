import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import CrashCheatSheetCard from '../../Components/CrashCourses/CrashCheatSheetCard';
import CrashLiveClassCard from '../../Components/CrashCourses/CrashLiveClassCard';
import CrashLiveExamCard from '../../Components/CrashCourses/CrashLiveExamCard';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { medicalCourseContentCollection } from '../../firebase/fire-app';

const MedicalAllCourseContent = () => {
    const [contents, loading, error] = useCollectionData(medicalCourseContentCollection.orderBy("serialNumber"), { idField: "id" });
    return (
        <>
            <section className="py-3 bg-light">
                <p className="mb-0 text-left font-14">Medical Admission/<b>Course Content</b></p>
            </section>
            <section className="py-4">
                <div className="container">
                    <div className="row">
                        {contents && contents.length > 0 && contents.map(item => <MedicalContentCard data={item} key={item.id}/>  )}
                    </div>
                </div>
            </section>
            {
                loading && <SectionSpinner/>
            }
        </>
    );
};

export default MedicalAllCourseContent;

const MedicalContentCard = ({ data }) => {
    return (
        <>
            {data.type === "Live Exam" && <CrashLiveExamCard data={data} />}
            {data.type === "Live Class" && <CrashLiveClassCard data={data} />}
            {data.type === "Cheatsheet" && <CrashCheatSheetCard data={data} />}
        </>
    )
}