import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../firebase/fire-app';
import { courseSubjects } from '../AddVideo/data';
import Switch from "react-bootstrap-switch"
import "./bootstrap-switch.scss"

const UpdateCourseChapters = () => {
    const [subject, setSubject] = useState("CH01");
    const [chapter, setChapter] = useState(null);

    const { register, handleSubmit } = useForm();


    const chapterCollectionRef = fireStoreDB.collection("Courses").doc("Subjects").collection(subject);

    const [chapters, chapLoading, chapError] = useCollectionData(chapterCollectionRef, { idField: "id" });

    const onSubjectChanged = (e) => {
        console.log(e.target.value);
        setSubject(e.target.value)
    }
    const onChapterChanged = (e) => {
        setChapter(null)
        const newChap = e.target.value;
        const newChapDetails = chapters.find(item => item.id === newChap)
        setTimeout(() => {
            setChapter({ ...newChapDetails });
            console.log(newChapDetails);
        }, 500)

    }
    const onChapterUpdated = (data, e) => {
        if (data.isPublished == "true") {
            data.isPublished = true
        } else {
            data.isPublished = false;
        }
        data.tabs = chapter.tabs;
        data.chapterNum = Number(data.chapterNum);
        data.totalHours = Number(data.totalHours);
        data.totalTopics = Number(data.totalTopics);
        data.totalVideos = Number(data.totalVideos);
        delete data.id;
        console.log(chapter.id);
        chapterCollectionRef.doc(chapter.id).set(data).then(() => {
            console.log("updated");
        }).catch((err) => {
            console.error(err)
        })
    }
    const handleSwitch=(sw, tab) =>{
        console.log('handleSwitch. elem:', sw.state.value);
        console.log('new state:', tab);
        const stat = sw.state.value;
        // chapterCollectionRef.doc(chapter.id).update({
        //     "tabs":{
        //     tab: stat
        //     }
        // }, {merge: true}).then(()=>{
        //     console.log("Updated ", tab, " to: ", stat);
        // })
        // chapterCollectionRef.doc(chapter.id).update({
        //     [`tabs.${tab}`]: true
        // })
        
        chapterCollectionRef.doc(chapter.id).set({
            tabs: {[tab]: stat}
        }, {merge: true});
      }
    return (
        <>
            <div className="jumbotron">
                <h3 className="text-center">
                    Update Chapter info
                </h3>
            </div>
            <div className="container py-5">
                <div className="row">
                    <InputWrapper col={3} label="Subject">
                        <select onChange={onSubjectChanged} className="custom-select" name="subject">
                            {/* <option value={subject}>{subject}</option> */}
                            <option value="">Choose subject</option>
                            {courseSubjects.map(item => <option value={item} >{item} </option>)}
                        </select>
                    </InputWrapper>
                    
                    <InputWrapper col={3} label="Chapter">
                        {/* <input type="num" onChange={(e)=> setChapter(e.target.value)} className="form-control"/> */}
                        <select onChange={onChapterChanged} className="custom-select" name="subject">
                            {/* <option value={chapter ? chapter : ""}>{chapter ? chapter : "Choose chapter.."}</option> */}
                            <option value="">Choose chapter</option>
                            {chapters && chapters.map(item => <option value={item.id} >{item.name} </option>)}
                        </select>
                    </InputWrapper>
                </div>
                {chapter && <form onSubmit={handleSubmit(onChapterUpdated)} >
                    <div className="row">
                        <InputWrapper col={2} label="Chapter No.">
                            <input ref={register} type="num" name="chapterNum" defaultValue={chapter.chapterNum} className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={5} label="Chapter Name">
                            <input ref={register} type="text" name="name" defaultValue={chapter.name} className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={2} label="Code">
                            <input disabled type="text" name="code" defaultValue={chapter.id} className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={3} label="Published?">
                            <select ref={register} name="isPublished" className="custom-select">
                                <option default value={chapter.isPublished}>{chapter.isPublished ? "True" : "False"}</option>
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </InputWrapper>
                        <InputWrapper col={4} label="Topics">
                            <input ref={register} type="num" name="totalTopics" defaultValue={chapter.totalTopics} className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={4} label="Videos">
                            <input ref={register} type="num" name="totalVideos" defaultValue={chapter.totalVideos} className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={4} label="Hours">
                            <input ref={register} type="num" name="totalHours" defaultValue={chapter.totalHours} className="form-control" />
                        </InputWrapper>
                        <div className="col-12 text-center pb-5">
                            <button type="submit" className="btn btn-danger">Update</button>
                        </div>
                        <div className="text-center col-12 mb-4">
<Switch defaultValue={chapter.tabs["Outline"]} labelText="Outline" wrapperClass="mr-4"  onChange={(el, state)=> handleSwitch(el, "Outline") } baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Outline" />
<Switch defaultValue={chapter.tabs["Theory"]} labelText="Theory" wrapperClass="mr-4"  onChange={(el, state)=> handleSwitch(el, "Theory") } baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Theory" />
<Switch defaultValue={chapter.tabs["Math Solve"]} labelText="Math Solve" wrapperClass="mr-4"  onChange={(el, state)=> handleSwitch(el, "Math Solve") } baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Math Solve" />
<Switch defaultValue={chapter.tabs["Board Questions"]} labelText="Board Questions" wrapperClass="mr-4"  onChange={(el, state)=> handleSwitch(el, "Board Questions") } baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Board Questions" />
<Switch defaultValue={chapter.tabs["Short Technique"]} labelText="Short Technique" wrapperClass=""  onChange={(el, state)=> handleSwitch(el, "Short Technique") } baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Short Technique" />
                    </div>
                       
                    </div>
                </form>}
            </div>

        </>
    );
};

export default UpdateCourseChapters;