import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { fireStoreDB } from '../firebase/fire-app';
import PageSpinner from './Spinner/PageSpinner';
import { useCollectionData } from 'react-firebase-hooks/firestore';


const notificationCollectionRef = fireStoreDB.collection("PushNotification");
const NotificationList = () => {
    // const [allNotifications, setAllNotifications] = useState([])
    // useEffect(() => {
    //     const fetchNotifications = async () => {
    //         const notificationCollection = await notificationCollectionRef.orderBy('createdAt', 'desc').limit(10).get()
    //         setAllNotifications(
    //             notificationCollection.docs.map(doc => {
    //                 return { id: doc.id, ...doc.data() }
    //             })
    //         )
    //     }
    //     fetchNotifications()

    // }, [])
    const [values, loading, error] = useCollectionData(notificationCollectionRef.orderBy('createdAt', "desc").limit(30), { idField: "id" })
    return (
        <div>
            <Container className="py-5">
                {
                    values ? values.map((item, index) => <NotificationCard serial={index} notification={item} key={item.id} />)
                        : <PageSpinner />
                }
            </Container>
        </div>
    );
};

export default NotificationList;

const NotificationCard = ({ notification, serial }) => {
    const date = notification.createdAt
    const deleteNotification = () => {
        console.log(notification);
        notificationCollectionRef.doc(notification.id).delete().then(success => {
            alert("Great! Notification Deleted!")
        }).catch(err => {
            alert(err)
        })
    }

    const logNotification =()=>{
        alert(JSON.stringify(notification));
    }
    return (
        <Card className="mb-3 p-4">
            <Row className="d-flex align-items-center">
                <Col md={1}><strong>#{serial + 1}</strong> </Col>
                <Col md={3}>
                    <p>Title: <span className="font-bold"> {notification.title}</span> </p>
                    <p>Date: <span className="font-bold"> {date && new Date(date.seconds * 1000).toDateString()}</span> </p>
                    <p>{notification.id} </p>
                </Col>
                <Col md={2}>
                    <p>User Group:  <span className="font-bold"> {notification.userGroup}</span></p>
                    <p>Notification Click:  <span className="font-bold"> {notification.notificationClicked}</span></p>
                </Col>
                <Col md={4}><p>Body: <span className="font-bold"> {notification.body} </span></p></Col>
                <Col md={2} className="text-center" >
                    <Button onClick={() => deleteNotification()} variant="danger" className="mr-2" size="sm" >Delete</Button>
                    <Button onClick={logNotification} variant="dark" size="sm" >Info</Button>
                </Col>
            </Row>
        </Card>
    )
}