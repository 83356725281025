import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PageSpinner from "../Components/Spinner/PageSpinner";
import { fireStoreDB } from "../firebase/fire-app";

const db = fireStoreDB;

const UsersContext = createContext();

export const UsersContextProvider = (props) => {
    const usersManager = UsersManager();
    // if (usersManager.isLoading) {
    //     return (
    //         <>
    //             <PageSpinner />
    //         </>
    //     );
    // }
    return (
        <UsersContext.Provider value={usersManager}>
            {props.children}
        </UsersContext.Provider>
    );
};

export const useUsers = () => useContext(UsersContext);
const userInfoCollectionRef = db
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");

const UsersManager = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = await userInfoCollectionRef.get();
            setUsers(
                usersCollection.docs.map((doc) => {
                    return { id: doc.id, data: doc.data() };
                })
            );
            setIsLoading(false);
        };
        fetchUsers();
    }, []);
    const findUserById = (id) => {
        return userInfoCollectionRef
            .doc(id)
            .get()
            .then((doc) => {
                return { id: doc.id, ...doc.data() };
            });
    };
    const updateUser = (id, data) => {
        return userInfoCollectionRef
            .doc(id)
            .update(data)
            .then((res) => {
                return data;
            });
    };
    return {
        users,
        isLoading,
        findUserById,
        updateUser,
    };
};
