import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import ChallengeCard from '../Components/ChallengeCard';
import PageSpinner from '../Components/Spinner/PageSpinner';
import { fireStoreDB } from '../firebase/fire-app';


const challengesCollectionRef = fireStoreDB.collection("ExamOrQuiz").doc("Results").collection("Challenges");
const Challenges = () => {
    // const [allChallenges, setAllChallenges] = useState();
    const [allChallenges, loading, error] = useCollectionData(challengesCollectionRef, { idField: "id" })
    // useEffect(() => {
    //     const fetchChallenges = () => {
    //         challengesCollectionRef.orderBy("date", 'desc').limit(20).get().then(res => {
    //             setAllChallenges(
    //                 res.docs.map(doc => {
    //                     return { id: doc.id, ...doc.data() }
    //                 })
    //             )
    //         })
    //     }
    //     fetchChallenges()
    // }, [])

    document.title = "Challenges"
    return (
        <>
            <div className="bg-light text-center py-4 mb-4">
                <Container>
                    <h3 className="text-center text-primary ">Challenges</h3>
                </Container>
            </div>
            <Container>
                {allChallenges ? allChallenges.map(item => <ChallengeCard details={item} key={item.id} />) : <PageSpinner />}
            </Container>
        </>
    );
};

export default Challenges;