import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { fireStoreDB } from "../../../firebase/fire-app";
import { useCollectionData } from 'react-firebase-hooks/firestore';

const ModuleContext = createContext();

export const ModuleContextProvider = (props) => {
    const moduleManager = ModuleManager();
    return (
        <ModuleContext.Provider value={moduleManager}>
            {props.children}
        </ModuleContext.Provider>
    )
}

export const useCourseModule = () => useContext(ModuleContext);

const ModuleManager = () => {
    const [currentType, setCurrentType] = useState("Theory");
    const [currentSub, setCurrentSub] = useState("PH01")
    const [currentChap, setCurrentChap] = useState("PH01C01")

    return {
        currentSub, currentChap, setCurrentChap, setCurrentSub, currentType, setCurrentType
    }

}