import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import ComponentCourseVideoPreview from '../../../../Components/CourseVideoPreviewComponent/ComponentCourseVideoPreview';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { emptyElementRemover } from '../../../../utils/useful-modules';
import { useCourseModule } from '../useCourseModule';
const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const PreviewMathSolve = ({ bookID }) => {
    const { register, handleSubmit } = useForm();
    const { currentSub, currentChap, currentType } = useCourseModule();
    const mathsCollection = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("MathSolve");
    //    states
    const [lessonsLoading, setLessonsLoading] = useState(true);
    const [bookDetails, setBookDetails] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [sizeVideos, setSizeVideos] = useState(1);
    useEffect(() => {
        setLessonsLoading(true)
        mathsCollection.doc(bookID).onSnapshot((doc) => {
            console.log({ id: doc.id, ...doc.data() });
            setBookDetails({ id: doc.id, ...doc.data() });

            setLessons([...doc.data().lessons])
            setLessonsLoading(false)
        })
    }, [bookID])
    const basiLog = () => {
        console.log(lessons);
    }
    const lessonClicked = (item, index) => {
        setCurrentLesson(null);
        setSizeVideos(1);
        setTimeout(function () {
            console.log({ index: index, ...item });
            setCurrentLesson({ index: index, ...item })
            setSizeVideos(item.videos.length);
        }, 1000);

    }
    const deleteLesson = () => {
        console.log(currentLesson);
        let updatedLessons = lessons;
        updatedLessons[currentLesson.index] = "";
        if (updatedLessons) {
            updatedLessons = updatedLessons.filter(Boolean);
        } else {
            updatedLessons = []
        }
        setCurrentLesson(null)
        setLessons([...updatedLessons])
        mathsCollection.doc(bookID).update({
            lessons: [...updatedLessons]
        })
    }
    const onLessonUpdated = (data) => {
        data.videos = emptyElementRemover(data.videos);
        let newLessons = lessons;

        newLessons[currentLesson.index] = data;
        setLessons([...newLessons])
        console.log(newLessons);
        mathsCollection.doc(bookID).update({
            lessons: [...newLessons]
        })
    }
    return (
        <>
            {lessonsLoading && <Spinner animation="grow" variant="primary" />}
            {/* <button onClick={basiLog} className="btn btn-warning">Log</button> */}
            <div className="row">
                <div className="col-12">
                    {lessons && lessons.map((item, index) => <button onClick={() => lessonClicked(item, index)} className="btn btn-dark m-1 btn-sm">{`${index}. ${item.name}`} </button>)}
                </div>
                {currentLesson && <div className="col-10">
                    <hr />
                    <button onClick={() => deleteLesson()} className="btn-danger btn">Delete {`${currentLesson.index}. ${currentLesson.name} ?`}</button>
                    <hr />
                    <form onSubmit={handleSubmit(onLessonUpdated)}>
                        <div className="row">
                            {/* <InputWrapper col="6" label="Sl. No.">
                                <input disabled defaultValue={currentLesson.index} ref={register({
                                    required: true,
                                })} type="num" name="serialNumber" placeholder="Serial no.." className="form-control" />
                            </InputWrapper> */}
                            <InputWrapper col="10" label="Topic name">
                                <input defaultValue={currentLesson.name} ref={register({
                                    required: true,
                                })} type="text" name="name" placeholder="Topic name" className="form-control" />
                            </InputWrapper>
                            <div className="col-12">
                                <h5 className="mt-4">Add videos here</h5>
                            </div>
                            <div className="col-md-10" >
                                {createArrayWithNumbers(sizeVideos).map(
                                    (index) => {
                                        return (
                                            <>
                                                <div className="mt-3">
                                                    {currentLesson.videos[index - 1] && <ComponentCourseVideoPreview vidId={currentLesson.videos[index - 1].videoID} />}
                                                    <input
                                                        className="form-control mb-2 d-inline-block "
                                                        name={`videos[${index}].name`}
                                                        placeholder={
                                                            "Put video name here "
                                                        }
                                                        ref={register()}
                                                        defaultValue={currentLesson.videos[index - 1] ? currentLesson.videos[index - 1].name : ""}
                                                    // defaultValue={topicVideos[index - 1]}
                                                    />
                                                    <input
                                                        className="form-control mb-3 d-inline-block "
                                                        name={`videos[${index}].videoID`}
                                                        placeholder={
                                                            "Put video id here "
                                                        }
                                                        ref={register()}
                                                        defaultValue={currentLesson.videos[index - 1] ? currentLesson.videos[index - 1].videoID : ""}
                                                    // defaultValue={topicVideos[index - 1]}
                                                    />
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>
                            <div className="col-10 text-right">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => setSizeVideos(sizeVideos + 1)} >
                                    Add more
                                </button>
                            </div>

                            <div className="col-4">
                                <button type="submit" className="btn btn-primary">Update Lesson</button>
                            </div>
                        </div>
                    </form>
                </div>}
            </div>
        </>
    );
};

export default PreviewMathSolve;