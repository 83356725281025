import React from 'react';
import { Button, Container, Row } from "react-bootstrap";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import { zoomClassesCollection } from '../../firebase/fire-app';
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { useForm } from 'react-hook-form';
import Switch from "react-bootstrap-switch"
import ClassStudentDetails from './ClassStudentDetails';


const classSubjects = ["Physics-I", "Physics-II", "Chemistry-I", "Chemistry-II", "Math-1", "Math-II", "Biology-I", "Biology-II", "English"]


const EditZoomClass = () => {
    const { classId } = useParams();
    document.title = classId;
    const [classDetails, loading, error] = useDocumentData(zoomClassesCollection.doc(classId), { idField: "id" })
    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = (data, e) => {
        data.totalSit = Number(data.totalSit);
        data.startAt = new Date(data.startAt ? data.startAt : classDetails.startAt.seconds * 1000);
        data.createdAt = new Date(classDetails.createdAt.seconds * 1000)
        console.log(data)

        zoomClassesCollection.doc(classId).set(data, { merge: true }).then(docRef => {
            console.log("updated")
        })
    }

    const handleSwitch = (sw, tab) => {
        const value = sw.state.value;
        console.log('handleSwitch. elem:', value);
        console.log(tab, " : ", value);
        const tabUpdate = {}
        tabUpdate[`${tab}`] = value;
        console.log(tabUpdate);
        zoomClassesCollection.doc(classId).update(tabUpdate)
    }

    
    return (
        <>
            <div className="py-5 bg-light text-center">
                <h5>Class ID: {classId}</h5>
            </div>
            {loading && <SectionSpinner />}
            {classDetails && <Container className="mt-3" >
                <Switch defaultValue={classDetails.isPublished} labelText="Published" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "isPublished")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Publish" />
                <Switch defaultValue={classDetails.isLive} labelText="Live" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "isLive")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Publish" />
                <Switch defaultValue={classDetails.isFinished} labelText="Finished" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "isFinished")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Publish" />
                <Switch defaultValue={classDetails.isPremium} labelText="Premium" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "isPremium")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="Publish" />

            </Container>}

            {classDetails && <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-3">
                        <Row>
                            <InputWrapper col={4} label="Title">
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    defaultValue={classDetails.title}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Subject">
                                <select
                                    className="custom-select"
                                    name="subject"
                                    ref={register()}
                                >
                                    <option value={classDetails.subject} selected>{classDetails.subject}</option>
                                    {classSubjects.map(item => <option value={item}>{item}</option>)}
                                </select>
                            </InputWrapper>
                            <InputWrapper col={4} label="Level">
                                <select
                                    className="custom-select"
                                    name="level"
                                    id="level"
                                    ref={register({
                                        required: true,
                                    })}
                                >
                                    <option value={classDetails.level} selected>{classDetails.level}</option>
                                    <option value="HSC" >HSC </option>
                                    <option value="Varsity">Varsity</option>
                                    <option value="Medical">Medical</option>
                                    <option value="Engineering">Engineering</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={12} label="Class Link">
                                <input
                                    name="classLink"
                                    type="text"
                                    defaultValue={classDetails.classLink}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={12} label="Short description">
                                <input
                                    name="shortDescription"
                                    type="text"
                                    defaultValue={classDetails.shortDescription}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Starts at">
                                <input
                                    type="text"
                                    name="startAt"
                                    className="form-control"
                                    defaultValue={new Date(classDetails.startAt.seconds * 1000)}
                                    disabled
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Change to">
                                <input
                                    type="datetime-local"
                                    name="startAt"
                                    className="form-control"
                                    ref={register()}
                                />
                            </InputWrapper>

                            <InputWrapper col={4} label="Total Seat">
                                <input
                                    name="totalSit"
                                    type="number"
                                    defaultValue={classDetails.totalSit}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Tutor ID">
                                <input
                                    name="tutorId"
                                    type="text"
                                    defaultValue={classDetails.tutorId}
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="VideoID">
                                <input
                                    name="videoId"
                                    type="text"
                                    defaultValue={classDetails.videoId ? classDetails.videoId : ""}
                                    className="form-control"
                                    ref={register()}
                                />
                            </InputWrapper>
                        </Row>
                        <div className="text-center mt-4">
                            <Button type="submit" center>
                                Update Class
                            </Button>
                        </div>
                    </div>
                </form>
                {classDetails.students && <div className="row">
                    {classDetails.students.map(item => <ClassStudentDetails userId={item} key={item} /> )}
                </div>}
            </Container>
            }
        </>
    );
};

export default EditZoomClass;

const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};