import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AllTimeLeaderboardCard from '../../Components/AlltimeLeaderboardCard';
import LeaderBoardCard from '../../Components/LeaderBoardCard';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../firebase/fire-app';

const userCollectionRef = fireStoreDB
    .collection("ExamOrQuiz")
    .doc("Results")
    .collection("Users");

const OverallLeaderBoard = () => {
    const [users, loadUsers, userError] = useCollectionData(userCollectionRef.orderBy("points", "desc").limit(10), { idField: "id" })
    return (
        <>
            <h4 className="text-center">Over all leader board</h4>
            {loadUsers && <SectionSpinner />}
            {!userError && users && users.map((usr, index) => <AllTimeLeaderboardCard usr={usr} key={usr.id} rank={index + 1} />)}
        </>
    );
};

export default OverallLeaderBoard;


