import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fireStoreDB } from '../../firebase/fire-app';

const challengesCollectionRef = fireStoreDB.collection("ExamOrQuiz").doc("Results").collection("Challenges");


const UserDetailsChallengeCard = ({ id }) => {
    const [details, setDetails] = useState(null);
    useEffect(() => {
        const fetchChallenge = () => {
            challengesCollectionRef.doc(id).get().then(doc => {
                setDetails({ id: doc.id, ...doc.data() });
            })
        }
        fetchChallenge()
    }, [])
    return (
        <>
            {details && <Card className="p-4 mb-4">
                <Row className="d-flex align-items-center">
                    <Col className="text-left" md={5}>
                        <Link to={"/users/details/" + details.participant_1.uid}> <h6>{details.participant_1.name} </h6></Link>
                        <p>Accepted: {details.participant_1.taken ? <strong className="text-primary">Yes</strong> : <strong className="text-danger">No</strong>}</p>
                        <p>Correct Ans: {details.participant_1.correctAns} </p>
                        <p>Wrong Ans: {details.participant_1.wrongAns} </p>
                        <p>Score: {details.participant_1.gotNumber} </p>
                    </Col>
                    <Col className="text-center" md={2}>
                        <Avatar size="80px" textSizeRatio={2.5} maxInitials={2} fgColor="#FFFFFF" color={details.accepted ? "#075E54" : "#FF0000"} round={true} name="V S" />
                        <p>{details.accepted ? <strong className="text-primary">Accepted</strong> : <strong className="text-danger">Denied</strong>}</p>
                        <p>{details.subject} </p>
                        <p>{new Date(details.date.seconds * 1000).toDateString()} </p>

                    </Col>
                    <Col className="text-right" md={5}>
                        <Link to={"/users/details/" + details.participant_2.uid}><h6>{details.participant_2.name} </h6></Link>
                        <p>Accepted: {details.participant_2.taken ? <strong className="text-primary">Yes</strong> : <strong className="text-danger">No</strong>}</p>
                        <p>Correct Ans: {details.participant_2.correctAns} </p>
                        <p>Wrong Ans: {details.participant_2.wrongAns} </p>
                        <p>Score: {details.participant_2.gotNumber} </p>
                    </Col>

                </Row>
            </Card>}
        </>
    );
};

export default UserDetailsChallengeCard;