import React from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { useCACourseModule } from '../useCACourseModule';

const ComponentAddBook = ({ hide, sub, chap }) => {
    const moduleRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection(sub).doc(chap).collection("MathSolve");
    const { register, handleSubmit } = useForm();
    const onBookAdded = (data, e) => {
        data.lessons = [];
        data.serialNumber = Number(data.serialNumber);
        console.log(data);
        moduleRef.add(data).then(() => {
            hide(false)
        })
    }
    return (
        <>
            <form onSubmit={handleSubmit(onBookAdded)} >
                <div className="row">
                    <InputWrapper col="3" label="Sl.">
                        <input name="serialNumber" ref={register({
                            required: true,
                        })} type="num" placeholder="Book serial" className="form-control" />
                    </InputWrapper>
                    <InputWrapper col="6" label="Name">
                        <input name="name" ref={register({
                            required: true,
                        })} type="text" placeholder="Book name" className="form-control" />
                    </InputWrapper>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-primary">Add</button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ComponentAddBook;