import React, { useEffect, useState } from 'react';
// import { useCourseVideo } from './useCourseVideo';
import { useForm } from "react-hook-form";
import { Row, Spinner } from 'react-bootstrap';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import { videoType } from './data';
import { fireStoreDB } from '../../../firebase/fire-app';
import { useParams } from 'react-router';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import SectionSpinner from '../../../Components/Spinner/SectionSpinner';

const CAEditCourseVideo = () => {
    const { lessonId } = useParams();
    const [type, setType] = useState(null)
    const [updating, setUpdating] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const [videoId, setVideoId] = useState(null)
    const videoDocRef = fireStoreDB.collection("Courses").doc("Videos").collection("courseVideos").doc(lessonId);

    const [lessonDetails, lessonLoading, lessonError] = useDocumentData(videoDocRef);
    useEffect(() => {
        if (lessonDetails) {
            setVideoId(lessonDetails.vimeo_id);
            setType(lessonDetails.type)
        }
    }, [lessonDetails])
    const onTypeChanged = (e) => {
        setType(e.target.value)

    }
    const onFormSubmitted = (data, e) => {
        setUpdating(true)
        data.subject = lessonDetails.subject;
        data.chapter = lessonDetails.chapter;
        data.type = type;
        data.level = "CollegeAdmission"
        if (data.important === "true") {
            data.important = true
        } else {
            data.important = false;
        }
        const currentTime = new Date();
        data.updatedAt = currentTime;
        data.createdAt = new Date(lessonDetails.createdAt.seconds * 1000);
        videoDocRef.set(data).then(() => {
            console.log(data);
            // e.target.reset();
            // setVideoId(null);
            setUpdating(false)
        })
        console.log(data);
    }
    const basicLog = () => {
        console.log(lessonId);
        console.log(lessonDetails);
    }
    return (
        <>
            {updating && <section className="text-center py-4">
                <h3 className="text-center text-primary">Updating...<Spinner animation="border" variant="danger" /></h3>
                {/* <button onClick={basicLog} className="btn btn-danger">Log</button> */}
            </section>}
            {
                lessonLoading ? <SectionSpinner /> :
                    <div className="container mt-4">
                        <p><span className="font-weight-bold">Subject:</span> {lessonDetails.subject} </p>
                        <p><span className="font-weight-bold">Chapter: </span>{lessonDetails.chapter} </p>
                        {/* <p><span className="font-weight-bold"></span> </p>
                        <p><span className="font-weight-bold"></span> </p> */}
                        <form className="mt-0" onSubmit={handleSubmit(onFormSubmitted)}>
                            <Row>
                                <InputWrapper col="5" label="Name" labelClass="bg-danger" >
                                    <input defaultValue={lessonDetails.name} type="text" name="name" className="form-control" placeholder="Name of the video"
                                        ref={register({
                                            required: true,
                                        })} />
                                </InputWrapper>

                                <InputWrapper col={3} label="Important?" labelClass="bg-danger">
                                    <select className="custom-select" name="important" ref={register} >
                                        <option value={lessonDetails.important}>{lessonDetails.important.toString()} </option>
                                        <option value={false}>False</option>
                                        <option value={true} >True</option>
                                    </select>
                                </InputWrapper>
                                {/* type */}
                                <InputWrapper col={4} label="Type">
                                    <select onChange={onTypeChanged} className="custom-select" name="type" >
                                        <option value={lessonDetails.type ? lessonDetails.type : ""}>{lessonDetails.type ? lessonDetails.type : "Choose type.."}</option>
                                        {videoType && videoType.map(item => <option value={item} >{item} </option>)}
                                    </select>
                                </InputWrapper>
                                {/* description */}
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        <InputWrapper col="12" label="Vimeo id" labelClass="bg-danger">
                                            <input defaultValue={lessonDetails.vimeo_id} onChange={(e) => setVideoId(e.target.value)} type="text" name="vimeo_id" className="form-control" placeholder="Vimeo id" ref={register({
                                                required: true,
                                            })} />
                                        </InputWrapper>
                                        <InputWrapper col="12" label="Lesson" labelClass="bg-danger">
                                            <input type="text" name="lesson" className="form-control" placeholder="lesson " ref={register()} />
                                        </InputWrapper>
                                        <div className="col-md-12">
                                            <p className="font-weight-bold mb-2 mt-3">Video Description:</p>
                                            <textarea defaultValue={lessonDetails.description} ref={register()} name="description" rows="10" className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <iframe src={`https://player.vimeo.com/video/${videoId}`} className="w-100" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                </div>

                                <div className="col-12 mt-3 text-center">
                                    <button type="submit" className="btn-lg btn btn-primary">Update this video</button>
                                </div>

                            </Row>
                        </form>
                    </div>
            }
        </>
    );
};

export default CAEditCourseVideo;