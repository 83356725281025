import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Main from "./Components/Main";
import { CollegesContextProvider } from "./Hooks/useColleges";
import { UsersContextProvider } from "./Hooks/useUsers";
import { AuthContextProvider } from "./Hooks/useAuth";
import { UserProgressContextProvider } from "./Hooks/useUserProgress";
import { PlayerContextProvider } from "./Hooks/useGamePlayers";
import { VideoContextProvider } from "./Pages/HSCCourses/AddVideo/useCourseVideo";
import { ModuleContextProvider } from "./Pages/HSCCourses/AddCourseModule/useCourseModule";
import { CollegeAdmissionContextProvider } from "./Pages/CollegeAdmission/AddVideo/useCollegeAdmissionCourse";
import { CAModuleContextProvider } from "./Pages/CollegeAdmission/AddCourseModule/useCACourseModule";

function App() {
    return (
        <>
        <CAModuleContextProvider>
            <ModuleContextProvider>
                <VideoContextProvider>
                    <PlayerContextProvider>
                        <AuthContextProvider>
                            <CollegeAdmissionContextProvider>
                                <CollegesContextProvider>
                                    <UsersContextProvider>
                                        <UserProgressContextProvider>
                                            <Main />
                                        </UserProgressContextProvider>
                                    </UsersContextProvider>
                                </CollegesContextProvider>
                            </CollegeAdmissionContextProvider>
                        </AuthContextProvider>
                    </PlayerContextProvider>
                </VideoContextProvider>
            </ModuleContextProvider>
            </CAModuleContextProvider>
        </>
    );
}

export default App;
