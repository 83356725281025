import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ComponentDailyUserCard = ({ details, user }) => {

    const { challenges, date, id, liveExams, points, practiceExams } = details;
    return (
        <div className="card mb-3 p-4">
            <div className="row">
                <div className="col-md-3">
                    <p>Date: <span className="font-weight-bold">{id}</span> </p>
                    <p>Obtained Score: <span className="font-weight-bold">{points}</span> </p>
                </div>
                <div className="col-md-4">
                    <p>PracticeExam taken: <span className="font-weight-bold">{practiceExams ? practiceExams.length : 0} </span></p>
                    <p>LiveExams Taken: <span className="font-weight-bold">{liveExams ? liveExams.length : 0}</span> </p>
                </div>
                <div className="col-md-3">
                    <p>Challenges Taken: <span className="font-weight-bold">{challenges ? challenges.length : 0}</span> </p>

                </div>
                <div className="col-md-2">
                    <Link className='btn btn-primary' size="sm" to={`/users/details/${user}/${id}`}>Details</Link>
                </div>
            </div>
        </div>
    );
};

export default ComponentDailyUserCard;