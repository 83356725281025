import React, { useContext, useCallback } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "./LoginPage.scss";
import { withRouter, Redirect, useHistory, useLocation } from "react-router";
import { AuthContext } from "../../Hooks/useAuth";
import fireApp from "../../firebase/fire-app";

const LoginPage = () => {
    const [returningUser, setReturningUser] = useState(false);
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const { register, handleSubmit, watch, errors, reset } = useForm();
    const onSubit = (data, e) => {
        if (data.email && data.password) {
            fireApp
                .signIn(data.email, data.password)
                .then(history.replace(from));

        }
        e.target.reset();
    };
    const onSubmit = useCallback(
        async (data) => {
            console.log(data);
            const { email, password } = data;
            try {
                await fireApp
                    .auth()
                    .signInWithEmailAndPassword(email, password);
                history.replace(from);
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );
    document.title = "Login"
    const { currentUser } = useContext(AuthContext);
    if (
        (currentUser && currentUser.email === "questions@prottoy.com.bd") ||
        (currentUser && currentUser.email === "admin@prottoy.com.bd")
    ) {
        return <Redirect to="/" />;
    } else {
        fireApp.auth().signOut();
    }

    return (
        <>
            <div className="login-container py-5">
                <div className="card login-form">
                    <div className="card-body">
                        <h3 className="card-title text-center">
                            Log in to upload questions
                        </h3>

                        <div className="card-text">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label for="email">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control form-control-sm"
                                        name="email"
                                        ref={register({ required: true })}
                                    />
                                    {errors.email && (
                                        <span className="error">
                                            Email is required
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <a
                                        href="tel:+8801753727388"
                                        style={{
                                            float: "right",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Forgot password?
                                    </a>
                                    <input
                                        type="password"
                                        className="form-control form-control-sm"
                                        name="password"
                                        ref={register({ required: true })}
                                    />
                                    {errors.password && (
                                        <span className="error">
                                            Password is required
                                        </span>
                                    )}
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                >
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(LoginPage);
