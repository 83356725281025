import React from 'react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PageSpinner from '../Components/Spinner/PageSpinner';
import SectionSpinner from '../Components/Spinner/SectionSpinner';
import fireApp, { fireStoreDB } from '../firebase/fire-app';
import { InputWrapper } from './EditUser';
import imageCompression from 'browser-image-compression';


const notificationCollectionRef = fireStoreDB.collection("PushNotification").doc();
const storageRef = fireApp.storage().ref().child("notificationImages")

const PushNewNotification = () => {
    const notificationId = notificationCollectionRef.id;
    const { register, handleSubmit, watch, errors } = useForm();
    const [showLoad, setShowLoad] = useState(false);
    const [examImage, setExamImage] = useState(null);
    const [imgUrl, setImgUrl] = useState(null)

    const onImageUpload = async (e) => {
        const fileRef = storageRef.child(notificationId);

        const file = e.target.files[0];
        const options = {
            maxSizeMB: .1,
            maxWidthOrHeight: 1180,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(file, options);
            setExamImage(compressedFile);

            fileRef.put(compressedFile).then((snapShot) => {
                snapShot.ref.getDownloadURL().then(res => {
                    setImgUrl(res);
                })
            })
        } catch (error) {
            alert(error)
        }

    }

    const onPushNotification = (data, e) => {
        data.createdAt = new Date()
        setShowLoad(true);
        data.notificationClicked = 0;
        alert(JSON.stringify(data));
        const fileRef = storageRef.child(notificationId);

        const notificationData = {
            notification: {
                body: data.body,
                title: data.title,
            },
            data: {
                notification_id: notificationId,
                notification_type: "notification",
                body: data.body,
                title: data.title,
            },
            to: `/topics/${data.userGroup}`,
        }
        if (imgUrl) {
            data.image = imgUrl
            notificationData.notification.icon = imgUrl;
            notificationData.notification.image = imgUrl;
            notificationCollectionRef.set(data).then(docRef => {
                setShowLoad(false);
                e.target.reset();
                pushNotificationToServer(notificationData)
            })
        } else {
            data.image = "";

            console.log(data)
            notificationCollectionRef.set(data).then(docRef => {
                setShowLoad(false);
                e.target.reset();
                pushNotificationToServer(notificationData)
            })
        }


    }

    const userLevel = ["all", "Admin", "SSC", "HSC", "Varsity", "Engineering", "Medical", "CollegeAdmission"];
    const notificationTypes = ["LiveExam", "Course", "Subscription", "LiveClass", "AskQuestion"];

    const pushNotificationToServer = (data) => {
        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "key=AAAAGF96vDE:APA91bH_VPmFbzKINkMMBtyXcpvMRF8v-YCm01GIazhInXcthQ_wUT5JMHQDGqH9u8Ft8mQk8mvtLk6rp4b4LSNcNAOFUpePI3Qd9zNfstpKjdiXVtnf4fUG22sILoRb3nVYOBWoTFUG"
            },
            body: JSON.stringify(data)
        }).then(() => {
            alert("New Notification Sent!");
        })
    }

    return (
        <>
            <div className="jumbotron">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="text-left">Create {"&"} Push new Notification</h3>
                            {showLoad && <SectionSpinner />}
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                <form className="mt-0" onSubmit={handleSubmit(onPushNotification)}>
                    <Row>
                        <InputWrapper col={6} label="Title (65): ">
                            <input type="text" maxLength="65" name="title" className="form-control" placeholder="Notification title..."
                                ref={register({
                                    required: true,
                                })} />
                        </InputWrapper>
                        <InputWrapper col={6} label="Text Body (240): ">
                            <input type="text" maxLength="240" name="body" className="form-control" placeholder="Notification body..."
                                ref={register()} />
                        </InputWrapper>

                        <InputWrapper col="6" label="CTA">
                            <select className="custom-select" name="notificationType" ref={register()}  >
                                <option value="">Select notification type</option>
                                {notificationTypes.map(item => <option value={item}>{item} </option>)}
                            </select>
                        </InputWrapper>
                        <InputWrapper col="6" label="Level">
                            <select className="custom-select" name="userGroup" ref={register()} >
                                <option value="">Select level</option>
                                {userLevel.map(item => <option value={item}>{item} </option>)}
                            </select>
                        </InputWrapper>

                        <InputWrapper col={6} label="Image: ">
                            <input type="file" name="image" onChange={onImageUpload} />
                            {imgUrl && <img src={imgUrl} alt="" className="img-fluid" />}
                        </InputWrapper>
                    </Row>
                    <Col md={12} className="text-center">
                        <Button center type="submit">Push new notification</Button>
                    </Col>
                </form>
            </Container>
        </>
    );
};

export default PushNewNotification;
// const CustomFileUpload =(props)=>{
//     return(
//         <div  className={
//             "input-group mb-3 custom-file col-md-" + props.col + " " + props.addClass
//         }>
//        <label className="custom-file-label" htmlFor={props.inputName}>Choose file...</label>
// {props.children}

//         </div>
//     )
// }