export const timeStampConverter = (timestamp) => {
    const milliSeconds = timestamp.seconds * 1000;
    const date = new Date(milliSeconds).toDateString();
    return date;
}

export const emptyElementRemover = (arr) => {
    let arrInput;
    if (arr) {
        arrInput = arr.filter(Boolean)
    } else {
        arrInput = []
    }
    return arrInput
}

export const emptyElementRemoverLinks = (arr) => {
    let arrInput;
    if (arr) {
        arrInput = arr.filter(item => item.link.length > 1)
    } else {
        arrInput = []
    }
    return arrInput
}