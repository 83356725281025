import React from 'react';
import { Table } from 'react-bootstrap';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { usePlayers } from '../../Hooks/useGamePlayers';

const WordGameResult = () => {
    const { playersOne, playersTwo, loading1, loading2 } = usePlayers()
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Game 1</th>
                                    <th>Game 2</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading1 ? playersOne.map((item, index) => <PlayerRow user={item} name={item.name} index={index} mobile={item.mobile} score={item.score} />) : <SectionSpinner />}
                            </tbody>
                        </Table>
                    </div>
                    {/* <div className="col-md-6">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading2 ? playersTwo.map((item, index) => <PlayerRow user={item} name={item.name} index={index} mobile={item.mobile} score={item.score2} />) : <SectionSpinner />}
                            </tbody>
                        </Table>
                    </div> */}
                </div>
            </div>

        </section>
    );
};

export default WordGameResult;

const PlayerRow = ({ user, name, mobile, score, index }) => {
    return (
        <tr>
            <td>{index + 1} </td>
            <td>{name} </td>
            <td>{mobile} </td>
            <td>{user.score} </td>
            <td>{user.score2} </td>
            <td>{user.score + user.score2} </td>
        </tr>
    )
}