import { Modal } from 'react-bootstrap';
import React from 'react';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import { Button } from 'react-bootstrap';
import { fireStoreDB } from '../../../firebase/fire-app';
import { useForm } from 'react-hook-form';

const CompAddSubject = (props) => {
    const chapterCollectionRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection("CollegeAdmission");
    const { register, handleSubmit, watch, errors } = useForm();

    const onChapterAdded = (data, e) => {
        console.log(data);
        let tabs = new Map();
        tabs.set("Board Questions", true);
        tabs.set("Math Solve", true);
        tabs.set("Outline", true);
        tabs.set("Short Technique", true);
        tabs.set("Theory", true);
        let obj = Array.from(tabs).reduce((obj, [key, value]) => (
            Object.assign(obj, { [key]: value })
        ), {});
        const newChapter = {
            name: data.name,
            level: "CollegeAdmission",
            serialNumber: Number(data.chapterNum),
            tabs: obj,
            totalHours: 0,
            totalTopics: 0,
            totalVideos: 0,
            isPublished: true
        }
        console.log(newChapter);

        chapterCollectionRef.doc(data.chapter).set(newChapter).then(res => {
            console.log("added");
            props.setModalShow(false)
        })
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add new subject  to {props.subject}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onChapterAdded)}>
                        <div className="row">
                            <InputWrapper col="3" label="Serial no.">
                                <input ref={register()} type="num" name="chapterNum" className="form-control"  />
                            </InputWrapper>
                            <InputWrapper col="4" label="Subject">
                                <input ref={register()} type="text" name="chapter" className="form-control" placeholder={"SSC-PH"} />
                            </InputWrapper>

                            <InputWrapper col="5" label="Name">
                                <input ref={register()} type="text" name="name" className="form-control" placeholder="Subject name" />
                            </InputWrapper>
                            <div className="col-12  text-center">
                                <button type="submit" className="btn btn-primary">Add Subject</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default CompAddSubject;