import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageSpinner from '../../Components/Spinner/PageSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import YouTubeActivityCard from './YouTubeActivityCard';

const youTubeCollectionRef = fireStoreDB.collection("YouTubeActivity");

const YouTubeActivityPreview = () => {
    // const [activities, setActivities] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [activities, loading, error] = useCollectionData(youTubeCollectionRef.orderBy('serialNumber', 'asc'), { idField: "id" })
    // useEffect(()=>{
    //     const fetchActivities =async()=>{
    //         const activitiesCollection = await youTubeCollectionRef.orderBy("date", "desc").get();
    //         setActivities(
    //             activitiesCollection.docs.map(doc => {
    //                 return {id: doc.id, ...doc.data()}
    //             })
    //         )
    //         setLoading(false)
    //     }
    //     fetchActivities()
    // },[])
    document.title = "YouTube Activities"
    if (loading) {
        return <PageSpinner />
    }
    return (
        <div>
            <Container className="py-4">
                {
                    activities.map(item => <YouTubeActivityCard details={item} key={item.key} />)
                }
            </Container>
        </div>
    );
};

export default YouTubeActivityPreview;