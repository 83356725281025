import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import MathJax from 'react-mathjax-preview'
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { fireStoreDB } from '../../firebase/fire-app';

const youTubeCollectionRef = fireStoreDB.collection("YouTubeActivity")

const YouTubeActivityCard = ({ details }) => {
    const { title, description, videoId, date } = details;
    const opts = {
        width: "100%"
    }
    const dateInMillis = date.seconds * 1000;
    const JSDate = new Date(dateInMillis).toDateString();
    const deleteVideo = () => {

        youTubeCollectionRef.doc(details.id).delete();
    }
    return (
        <Card className="mb-4 p-4">
            <Row>
                <Col md={6}>
                   
                    <MathJax math={description} />

                    
                </Col>
                <Col md={6}>
                <h5><span className="font-weight-bold">({details.serialNumber && details.serialNumber}) </span> {title} </h5>
                    <p className="mb-3"><>Date: {JSDate} </> </p>
                    <YouTube opts={opts} videoId={videoId} />
                    <Link to={`/youTubeActivity/edit/${details.id}`} className="btn btn-primary mt-3">Edit</Link>
                    <button onClick={deleteVideo} className="btn btn-danger mt-3 ml-4">Delete</button>
                    </Col>
            </Row>
        </Card>
    );
};

export default YouTubeActivityCard;