import React from 'react';
import { useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import PageSpinner from '../../Components/Spinner/PageSpinner';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import { InputWrapper } from '../EditUser';
const youTubeCollectionRef = fireStoreDB.collection("YouTubeActivity");



const EditYoutubeActivity = () => {
    const { vidId } = useParams();
    const [details, loading, error] = useDocumentData(youTubeCollectionRef.doc(vidId))
    const { register, handleSubmit, watch, errors } = useForm();
    const [showSpinner, setShowSpinner] = useState(false)
    const onSubmit = (data, e) => {
        data.date = data.date ? data.date : new Date(details.date.seconds * 1000);
        console.log(data);
        data.serialNumber = Number(data.serialNumber)
        youTubeCollectionRef.doc(vidId).update(data).then(res => {
            console.log("Added successfully.");
        })
    }
    document.title = "Edit YouTube activity"
    return (
        <>
            <Container>
                <h4 className="text-center my-4">Edit {vidId} </h4>
                {loading && <SectionSpinner />}
                {details && <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <InputWrapper col={6} label="Title">
                            <input type="text" defaultValue={details.title} name="title" id="" className="form-control" ref={register({
                                required: true,
                            })} />
                        </InputWrapper>
                        <InputWrapper col={6} label="Video ID: ">
                            <input type="text" defaultValue={details.videoId} name="videoId" id="" className="form-control" ref={register({
                                required: true,
                            })} />
                        </InputWrapper>
                        <InputWrapper col={6} label="Added:">
                            <input type="text" disabled defaultValue={new Date(details.date.seconds * 1000)} name="previous-date" id="" className="form-control" />
                        </InputWrapper>
                        <InputWrapper col={6} label="serialNumber">
                            <input type="num" defaultValue={details.serialNumber && details.serialNumber} name="serialNumber" id="" className="form-control" ref={register()} />
                        </InputWrapper>
                        <InputWrapper col={12} label="Description">
                            <textarea name="description" defaultValue={details.description} className="form-control" cols="30" rows="10" ref={register({
                                required: true,
                            })}></textarea>
                        </InputWrapper>

                    </Row>
                    <div className="text-center">
                        <Button type="submit" center>
                            Update Activity
                    </Button>
                    </div>
                </form>}
            </Container>
        </>
    );
};

export default EditYoutubeActivity;