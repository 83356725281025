import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner, Image } from "react-bootstrap";
import fireApp from "../../firebase/fire-app";
import SectionSpinner from "../Spinner/SectionSpinner";
import "./FetchAvatarImg.scss";

const FetchAvatarImg = ({ imgName, width }) => {
    const [imgLink, setImgLink] = useState(null);
    const [loadingImg, setLoadingImg] = useState(true)
    const storageRef = fireApp.storage().ref();
    const widthMax = width ? width : "115px"
    useEffect(() => {
        if (imgName) {
            storageRef
                .child("userMetadata")
                .child("profilePic" + imgName + ".jpg")
                .getDownloadURL()
                .then((res) => {
                    setImgLink(res);
                    setLoadingImg(false)
                }).catch(err => {
                    setLoadingImg(false);
                })
        }
    }, []);
    // if (!imgLink) {
    //     return (
    //         <>
    //             <div style={{ height: widthMax }} className="d-flex align-items-center justify-content-between" >
    //                 <Spinner animation="grow" variant="primary" />
    //             </div>
    //         </>
    //     );
    // }
    return (
        <div>
            {loadingImg && <SectionSpinner />}
            {!loadingImg && !imgLink && <Image style={{ maxWidth: widthMax }} className="avatar" alt={"no-img"} />}
            {!loadingImg && imgLink && <Image style={{ maxWidth: widthMax }} className="avatar" src={imgLink} roundedCircle />
            }
            {/* <img style={{ width: "70%" }} src={imgLink} alt="" /> */}
        </div>
    );
};

export default FetchAvatarImg;
