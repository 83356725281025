import React, { useState } from 'react';
import { Button, Container, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { zoomClassesCollection } from '../../firebase/fire-app';

const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};
const classSubjects = ["Physics-I", "Physics-II", "Chemistry-I", "Chemistry-II", "Math-1", "Math-II", "Biology-I", "Biology-II", "English"]
const AddLiveZoomClass = () => {
    document.title = "Add New Live Class";
    const { register, handleSubmit, watch, errors } = useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onSubmit = (data, e) => {
        data.totalSit = Number(data.totalSit);
        data.isFinished = false;
        data.isLive = false;
        data.isPremium = false;
        data.isPublished = false;
        data.startAt = new Date(data.startAt);
        data.createdAt = new Date()
        console.log(data)

        zoomClassesCollection.add(data).then(docRef => {
            history.push("/live-classes/edit/" + docRef.id)
        })
    }

    return (
        <>
            <Container>
                {loading && <SectionSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-5">
                        <Row>
                            <InputWrapper col={4} label="Title">
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    placeholder="Exam title"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Subject">
                                <select
                                    className="custom-select"
                                    name="subject"
                                    ref={register()}
                                >
                                    <option value="" selected>Select one ... </option>
                                    {classSubjects.map(item => <option value={item}>{item}</option>)}
                                </select>
                            </InputWrapper>
                            <InputWrapper col={4} label="Level">
                                <select
                                    className="custom-select"
                                    name="level"
                                    id="level"
                                    ref={register({
                                        required: true,
                                    })}
                                >
                                    <option value="HSC" selected>HSC </option>
                                    <option value="Varsity">Varsity</option>
                                    <option value="Medical">Medical</option>
                                    <option value="Engineering">Engineering</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={12} label="Class Link">
                                <input
                                    name="classLink"
                                    type="text"
                                    placeholder="class link (zoom/meet)"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={12} label="Short description">
                                <input
                                    name="shortDescription"
                                    type="text"
                                    placeholder="short description"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Starts at">
                                <input
                                    type="datetime-local"
                                    name="startAt"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Total Seat">
                                <input
                                    name="totalSit"
                                    type="number"
                                    placeholder="total seat"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Tutor ID">
                                <input
                                    name="tutorId"
                                    type="text"
                                    placeholder="tutor userID"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="VideoID">
                                <input
                                    name="videoId"
                                    type="text"
                                    placeholder="optional"
                                    className="form-control"
                                    ref={register()}
                                />
                            </InputWrapper>
                        </Row>
                        <div className="text-center mt-4">
                            <Button type="submit" center>
                                Submit Form
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </>
    );
};

export default AddLiveZoomClass;
const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};