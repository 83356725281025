import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import { usePlayers } from '../../Hooks/useGamePlayers';

const usersColRef = fireStoreDB.collection("Marketing").doc("memory-test").collection("users");


const updateSingleUser = (user, id) => {
    let points = 0;
    let vidPoints = 0;
    let textPoints = 0;
    let minutesText = 60;
    let secondsText = 60;

    let minutesVideo = 60;
    let secondsVideo = 60;


    const calculateVideoTime = (arr) => {
        if (arr) {
            arr.forEach(item => {
                if (item.seconds >= 1) {
                    if (item.minutes < minutesVideo) {
                        minutesVideo = item.minutes;
                    }
                    if (item.seconds < secondsVideo) {
                        secondsVideo = item.seconds;
                    }
                } else {
                    minutesVideo = 0;
                    secondsVideo = 0;
                }
            })
        } else {
            minutesVideo = 0;
            secondsVideo = 0;
        }

    }
    const calculateTextTime = (arr) => {
        if (arr) {

            arr.forEach(item => {
                if (item.seconds >= 1) {
                    if (item.minutes < minutesText) {
                        minutesText = item.minutes;
                    }
                    if (item.seconds < secondsText) {
                        secondsText = item.seconds;
                    }
                } else {
                    minutesText = 0;
                    secondsText = 0;
                }


            })
        } else {
            minutesText = 0;
            secondsText = 0;

        }
    }
    const calculateArray = (arr, cat) => {
        if (arr) {
            if (cat == "vid") {
                arr.forEach(item => {
                    if (item.ans == item.userAns) {
                        vidPoints = vidPoints + 1;
                    } else if (!item.ans) {
                        vidPoints = vidPoints + .25;
                    } else {
                        vidPoints = vidPoints - .25;
                    }

                })
            } else if (cat == "text") {
                arr.forEach(item => {
                    if (item.ans == item.userAns) {
                        textPoints = textPoints + 1;
                    } else if (!item.ans) {
                        textPoints = textPoints + .25;
                    } else {
                        textPoints = textPoints - .25;
                    }

                })
            }

        }
    }
    const prepareResult = () => {
        calculateArray(user.textAnswers, "text");
        calculateArray(user.videoAnswers, "vid");
        calculateTextTime(user.textAnswers);
        calculateVideoTime(user.videoAnswers);

        const totalScore = vidPoints + textPoints;
        user.videoScore = vidPoints;
        user.textScore = textPoints;
        user.videoTime = (minutesVideo * 60 + secondsVideo) / 60
        user.textTime = (minutesText * 60 + secondsText) / 60
        // console.log(totalScore, "total");
        user.score = totalScore;
        // console.log("id", user.id);
        usersColRef.doc(user.id).set(user).then(() => {
            console.log("updated");
        })
    }
    prepareResult();
}

const MnemoManiaResult = () => {
    const { mnemoMania, loading3 } = usePlayers();
    const updateAllUsers = (allUsers) => {
        allUsers.forEach(item => {
            updateSingleUser(item)
        })
    }
    return (
        <>
            <section className="pt-4 text-center bg-light">
                <h3 className="text-center text-primary">Mnemo-Mania Result</h3>
                {/* <button onClick={() => updateAllUsers(mnemoMania)} className="btn btn-primary">log</button> */}
            </section>
            <section className="py-4 text-center bg-light">
                <div className="container">
                    <Table size="sm" striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>
                                <th>Name</th>
                                {/* <th>Mobile</th> */}
                                <th>Text</th>
                                <th>Time needed</th>
                                <th>Video</th>
                                <th>Time needed</th>
                                <th>Score</th>
                                {/* <th>Status</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {!loading3 ? mnemoMania.map((item, index) => <UpdateUser allUsers={mnemoMania} user={item} name={item.name} index={index} mobile={item.mobile} score={item.score} id={item.id} />) : <SectionSpinner />}
                        </tbody>
                    </Table>
                </div>
            </section>
        </>
    );
};

export default MnemoManiaResult;


const UpdateUser = ({ allUsers, user, id, name, mobile, score, index }) => {


    return (
        <tr>
            <td>{index + 1} </td>
            <td>{id} </td>
            <td>{name} </td>
            {/* <td>{mobile} </td> */}
            <td>{user.textScore} </td>
            <td>  {Math.floor(25 - user.textTime)} {`(min)`} </td>
            <td>{user.videoScore} </td>
            <td>  {Math.floor(25 - user.videoTime)} {`(min)`} </td>
            <td>{score} </td>
            {/* <td>{score ?
                <button className="btn btn-sm btn-primary">Updated!</button> :
                <button disabled className="btn btn-sm btn-danger">Update</button>
            } </td> */}
        </tr>
    )
}