import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { fireStoreDB } from '../../firebase/fire-app';
import { InputWrapper } from '../EditUser';

const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const questionsColRef = fireStoreDB.collection("Marketing").doc("MemoryTest").collection("questions");

const MemoryAddQuestion = () => {
    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const [questionCount, setQuestionCount] = useState(1);
    const location = useLocation();
    const history = useHistory();

    const onQuestionAdded = (data, e) => {
        console.log(data);
    }

    return (
        <>
            <div className="jumbotron">
                <h2 className="text-center">Add Question</h2>
            </div>
            <div className="container">
                <form onSubmit={handleSubmit(onQuestionAdded)} >
                    <div className="form-group mt-5">
                        <h5>Text Document</h5>
                        <textarea className="form-control" name="textDoc" cols="30" rows="10"
                            placeholder="Text Document"
                            ref={register()}></textarea>
                    </div>
                    <div className="row">
                        <InputWrapper col="4" label="Total Number of Questions">
                            <input className="form-control" type="text" name="questionCount" onChange={(e) => {
                                setQuestionCount(e.target.value)
                            }} />
                        </InputWrapper>
                        {createArrayWithNumbers(questionCount).map(
                            (index) => {
                                return (
                                    <>
                                        <InputWrapper
                                            addClass=" offset-md-2"
                                            col={8}
                                            label={"ID for question: " + index}
                                        >
                                            <input
                                                className="form-control"
                                                name={`question${index}.body`}
                                                placeholder={
                                                    "Write down details of question " +
                                                    index
                                                }
                                                ref={register({
                                                    required: true,
                                                })}
                                            />
                                        </InputWrapper>
                                        <InputWrapper
                                            addClass=" offset-md-2 mb-5"
                                            col={8}
                                            label={"Answer for question: " + index}
                                        >
                                            <input
                                                className="form-control"
                                                name={`question${index}.body`}
                                                placeholder={
                                                    "Write down answer for question " +
                                                    index
                                                }
                                                ref={register({
                                                    required: true,
                                                })}
                                            />
                                        </InputWrapper>
                                    </>
                                );
                            }
                        )}

                    </div>
                    <div className="text-center">
                        <Button type="submit" center>
                            Submit Form
                            </Button>
                    </div>

                </form>
            </div>
        </>
    );
};

export default MemoryAddQuestion;