import React from 'react';
import Avatar from 'react-avatar';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ChallengeCard = ({ details }) => {
    const {
        chapters,
        dateTime,
        id,
        isAccepted,
        isDenied,
        level,
        participant1,
        participant2,
        participants,
        questionList,
        subject,
    } = details;
    const dateInMil = dateTime.seconds * 1000
    return (
        <>
            <Card className="p-4 mb-4">
                {/* <button onClick={() => console.log(details)} className="btn btn-danger">Log details</button> */}
                <Row className="d-flex align-items-center">
                    <Col className="text-left" md={5}>
                        <Link to={"/users/details/" + participant1.uid}> <h6>{participant1.name} </h6></Link>
                        <p>Accepted: {participant1.taken ? <strong className="text-primary">Yes</strong> : <strong className="text-danger">No</strong>}</p>
                        <p>Correct Ans: {participant1.correctAns} </p>
                        <p>Wrong Ans: {participant1.wrongAns} </p>
                        <p>Score: {participant1.gotNumber} </p>
                    </Col>
                    <Col className="text-center" md={2}>
                        <Avatar size="80px" textSizeRatio={2.5} maxInitials={2} fgColor="#FFFFFF" color={isAccepted ? "#075E54" : "#FF0000"} round={true} name="V S" />
                        <p>{isAccepted ? <strong className="text-primary">Accepted</strong> : <strong className="text-danger">Denied</strong>}</p>
                        <p>{subject} </p>
                        <p>{new Date(dateInMil).toDateString()} </p>

                    </Col>
                    <Col className="text-right" md={5}>
                        <Link to={"/users/details/" + participant2.uid}><h6>{participant2.name} </h6></Link>
                        <p>Accepted: {participant2.taken ? <strong className="text-primary">Yes</strong> : <strong className="text-danger">No</strong>}</p>
                        <p>Correct Ans: {participant2.correctAns} </p>
                        <p>Wrong Ans: {participant2.wrongAns} </p>
                        <p>Score: {participant2.gotNumber} </p>
                    </Col>

                </Row>
            </Card>
        </>
    );
};

export default ChallengeCard;