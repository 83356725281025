import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import LeaderBoardCard from '../../Components/LeaderBoardCard';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import { Table } from "react-bootstrap";
import CompLeaderReportRow from './CompLeaderReportRow';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const db = fireStoreDB;
const examCollectionRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam");
const LiveExamLeaderBoard = () => {
    const { examId } = useParams();
    // const [examParticipants, setExamParticipants] = useState([])
    const examRef = examCollectionRef.doc(examId);
    const [updated, setUpdated] = useState(1);
    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchExam = () => {
            examRef
                .get()
                .then((doc) => {
                    setExamDetails(doc.data());
                    setLoading(false);
                })
                .catch((error) => console.log(error));
        };
        fetchExam();
    }, [])
    const [examParticipants, loadingParticipants, errParticipants] = useCollectionData(examRef.collection("participants").orderBy("points", "desc").limit(100), { idField: "id" })
    document.title = "LeaderBoard - " + examId
    const updateRank = (uid, newRank) => {
        examRef.collection("participants").doc(uid)
            .update({ rank: newRank })
            .then(res => {
                console.log("updated: ", res);
            })
    }
    const updateUsersRank = () => {
        console.log("Updated");
        examParticipants.forEach((usr, index) => {
            updateRank(usr.id, index + 1)
        })
        setUpdated(updated + 1)
    }

    return (
        <>
            <div className="jumbotron text-center">
                <h4 className="text-center mb-4">
                    Live Exam LeaderBoard: {examId}
                </h4>
                {loading && <SectionSpinner />}
                {examDetails && <ExamInformation examId={examId} examDetails={examDetails} />}

                <Button onClick={updateUsersRank}>Update Users' Rank</Button>
            </div>
            <Container>
                <div className="text-right" >
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-danger"
                    table="table-to-xls"
                    filename={examDetails && examDetails.title}
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                </div>
                <Table id="table-to-xls" responsive bordered hover variant="primary">
                    <thead>
                        <tr className="">
                        <th>Rank</th>
                            <th>Name</th>
                            <th>Points</th>
                            <th>Phone</th>
                            <th>District</th>
                            <th>UID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            examParticipants && examParticipants.map((item, index) => <CompLeaderReportRow gotNumber={item.points} rank={item.rank} userId={item.id} key={item.uid} />)
                        }
                    </tbody>
                </Table>
            </Container>
            {/* <Container>
                {
                    examParticipants && examParticipants.map((item, index) => <LeaderBoardCard gotNumber={item.points} rank={item.rank} userId={item.id} key={item.uid} />)
                }
            </Container> */}
        </>
    );
};

export default LiveExamLeaderBoard;

const ExamInformation = ({ examId, examDetails }) => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <p>
                        ID: <strong>{examId} </strong>
                    </p>
                    <p>Title: {examDetails.title} </p>
                    <p>Subtitle: {examDetails.subTitle}</p>
                </Col>
                <Col md={3}>
                    <p>Level: {examDetails.level}</p>
                    <p>
                        Number of Questions: {examDetails.numberOfQuestions}{" "}
                    </p>
                    <p>
                        Duration:
                        {examDetails.timeInMunitesForEachQuestion *
                            examDetails.numberOfQuestions}{" "}
                    </p>
                </Col>
                <Col md={3}>
                    <p>Positive mark: {examDetails.positiveMarks}</p>
                    <p>Negative mark: {examDetails.negativeMarks} </p>
                    <p>Start: date </p>
                    <p>End: date </p>
                </Col>

                <Col md={3} className="d-flex align-items-center">
                    <div style={{ width: "100%" }}>
                        <Link className="mt-0 btn btn-primary btn-block" to={"/liveExams/edit/" + examId}>
                            Edit
                        </Link>
                        {/* <Button size="sm" variant="danger" block>
                            Delete
                </Button> */}
                    </div>
                </Col>
            </Row>
            <hr />
        </>
    )
}
