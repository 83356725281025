import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import QuestionCard from "../../Components/QuestionCard/QuestionCard";
import PageSpinner from "../../Components/Spinner/PageSpinner";
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { fireStoreDB } from "../../firebase/fire-app";
import { timeStampConverter } from "../../utils/useful-modules";
import "./ExamDetails.scss";
import FetchExamImage from "./FetchExamImage";
const db = fireStoreDB;
const examCollectionRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam");

const ExamDetails = () => {
    const history = useHistory();
    const { examId } = useParams();
    const examRef = examCollectionRef.doc(examId);
    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchExam = () => {
            examRef
                .get()
                .then((doc) => {
                    setExamDetails(doc.data());
                    setLoading(false);
                    console.log(doc.data());
                })
                .catch((error) => console.log(error));
        };
        fetchExam();
    }, []);
    const onDelete =()=>{
        examCollectionRef.doc(examId).delete().then(()=>{
            history.push('/')
        })
    }
    document.title = examId;
    // if (loading) {
    //     return (
    //         <>
    //             <PageSpinner />{" "}
    //         </>
    //     );
    // }

    return (
        <>
            {loading ? <SectionSpinner /> :
                <Container className="mt-3">
                    <Row>
                        {examDetails.img && <FetchExamImage imgName={examDetails.img} />}
                        <Col md={3}>
                            <p>
                                ID: <strong>{examId} </strong>
                            </p>
                            <p>Title: {examDetails.title} </p>
                            <p>Subtitle: {examDetails.subTitle}</p>
                        </Col>
                        <Col md={3}>
                            <p>Level: {examDetails.level}</p>
                            <p>
                                Number of Questions: {examDetails.numberOfQuestions}{" "}
                            </p>
                            <p>
                                Duration: {" "}
                                {examDetails.timeInMinutesForEachQuestion *
                                    examDetails.numberOfQuestions}{" minutes"}
                            </p>
                            <p>Is Published: {examDetails.isPublished ? "True" : "False"} </p>
                        </Col>
                        <Col md={3}>
                            <p>Positive mark: {examDetails.positiveMarks}</p>
                            <p>Negative mark: {examDetails.negativeMarks} </p>
                            <p>Start: {timeStampConverter(examDetails.startAt)} </p>
                            <p>End: {timeStampConverter(examDetails.endAt)} </p>
                        </Col>

                        <Col md={3} className="d-flex align-items-center">
                            <div style={{ width: "100%" }}>
                                <Link className="mt-0 btn btn-primary btn-block" to={"/liveExams/edit/" + examId}>
                                    Edit
                            </Link>
                                <Button onClick={onDelete} size="sm" variant="danger" block>
                                    Delete
                            </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    {examDetails.questionList.map((question, index) => (
                        <QuestionCard
                            qID={question}
                            key={index}
                            count={index + 1}
                        />
                    ))}
                </Container>}
        </>
    );
};

export default ExamDetails;

