import React, { useState } from 'react';
import { useCourseModule } from '../useCourseModule';

const ComponentSidebar = () => {
    const { currentType, setCurrentType } = useCourseModule();
    const [outline, setOutline] = useState(false);
    const [theory, setTheory] = useState(false);
    const [mathSolve, setMathSolve] = useState(false);
    const [boardQuestions, setBoardQuestions] = useState(false);
    const [shortTechnique, setShortTechnique] = useState(false);
    const updateType = (type) => {
        setCurrentType(type);

    }

    return (
        <>
            <div className="sidebar-heading">Choose the Type</div>
            <div className="list-group list-group-flush">
                <button onClick={() => updateType("Outline")} className={"list-group-item list-group-item-action bg-light "}>
                    Outline
                </button>
                <button onClick={() => updateType("Theory")} className={"list-group-item list-group-item-action bg-light "}>
                    Theory
                </button>
                <button onClick={() => updateType("MathSolve")} className={"list-group-item list-group-item-action bg-light "}>
                    Math Solve
                </button>
                <button onClick={() => updateType("BoardQuestions")} className={"list-group-item list-group-item-action bg-light "}>
                    Board Questions
                </button>
                <button onClick={() => updateType("ShortTechnique")} className={"list-group-item list-group-item-action bg-light "}>
                    Short Technique
                </button>

            </div>
        </>
    );
};

export default ComponentSidebar;