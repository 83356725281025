import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { fireStoreDB } from "../../firebase/fire-app";
import { useUsers } from "../../Hooks/useUsers";
import { InputWrapper } from "../EditUser";
import UserCardComponent from "./UserCardComponent";

const AllUsersPage = () => {
    const [userName, setUserName] = useState(null);
    let userInfoCollectionRef = fireStoreDB
        .collection("metadata")
        .doc("userMetadata")
        .collection("userInfo").limit(10);
    if (userName) {
        userInfoCollectionRef = fireStoreDB
            .collection("metadata")
            .doc("userMetadata")
            .collection("userInfo").where("email", ">=", userName);
    }
    const [searchUsers, searchLoading, searchErr] = useCollectionData(userInfoCollectionRef, { idField: "id" })
    const onSearchChanged = (e) => {
        let nm = e.target.value.toUpperCase();
        setUserName(nm)
    }


    document.title = userName ? userName : "Users";

    return (
        <>
            <div className="jumbotron text-center">
                {/* <h2 className="text-center">{userName} </h2> */}
                {/* <button onClick={() => console.log(searchUsers)} className="btn btn-danger">Log users</button> */}
                <div className="container">
                    <div className="row">
                        <InputWrapper label="name" col="6" addClass=" offset-md-3">
                            <input onChange={onSearchChanged} placeholder="search here.." type="text" className="form-control" />
                        </InputWrapper>
                    </div>
                </div>
            </div>
            <div className="custom-container">
                {!searchLoading && !searchErr && searchUsers.map((item) => (
                    <UserCardComponent userInfo={item} />
                ))}
            </div>
        </>
    );
};

export default AllUsersPage;
