import React, { createContext, useContext, useEffect, useState } from "react";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { fireStoreDB } from "../firebase/fire-app";


const PlayerContext = createContext();
const player1Ref = fireStoreDB.collection("Marketing").doc("memory-game").collection("users").orderBy("score", "desc").limit(50);
const player2Ref = fireStoreDB.collection("Marketing").doc("memory-game").collection("users").orderBy("score2", "desc").limit(10);
const mnemoManiaRef = fireStoreDB.collection("Marketing").doc("memory-test").collection("users").orderBy("score", "desc").limit(100);


export const PlayerContextProvider = ({ children }) => {
    const playerManager = PlayerManager();
    return (
        <PlayerContext.Provider value={playerManager}>
            {children}
        </PlayerContext.Provider>
    )
}

export const usePlayers = () => useContext(PlayerContext);

const PlayerManager = () => {
    const [playersOne, loading1, error1] = useCollectionData(player1Ref);
    const [playersTwo, loading2, error2] = useCollectionData(player2Ref);
    const [mnemoMania, loading3, error3] = useCollectionData(mnemoManiaRef, { idField: "id" });
    if (!loading2) {
        // console.log(gameTwoUsers);
    }
    return {
        mnemoMania, loading3, playersOne, playersTwo, loading1, loading2
    }
}