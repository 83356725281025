const courseSubjects = [
    "CollegeAdmission",

];

const videoType = [
    "Outline",
    "Theory",
    "MathSolve",
    "BoardQuestions",
    "ShortTechnique"
]

export { courseSubjects, videoType }