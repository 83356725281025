import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { fireStoreDB } from '../../firebase/fire-app';
import { InputWrapper } from '../EditUser';
import WordsContainer from './WordsContainer';


const wordCollectionRef = fireStoreDB.collection("Marketing").doc("memory-game").collection("words");

const AddNewWord = () => {
    const [showLoad, setShowLoad] = useState(false);
    const { register, handleSubmit } = useForm();
    const [values, loading, error] = useCollectionData(wordCollectionRef.orderBy("createdAt", "desc"), { idField: "id" });

    const onWordAdded = (data, e) => {
        console.log(data);
        data.createdAt = new Date();
        wordCollectionRef.add(data).then(res => {
            console.log("Added: ", data.word);
            e.target.reset();
        })
    }

    return (
        <>

            <Container>
                {/* <button onClick={() => console.log(values)} className="btn btn-primary">log</button> */}
                <h3 className="text-primary text-center my-4">Add New Words</h3>
                <form className="mt-0" onSubmit={handleSubmit(onWordAdded)}>
                    <Row>
                        <InputWrapper col={4} label="Word">
                            <input ref={register({ required: true })} type="text" name="word" className="form-control" placeholder="Write down the word..." />
                        </InputWrapper>
                        <InputWrapper col={4} label="Meaning">
                            <input ref={register({ required: true })} type="text" name="meaning" className="form-control" placeholder="Meaning..." />
                        </InputWrapper>
                        <InputWrapper col={4} label="Parts of Speech">
                            <input ref={register({ required: true })} type="text" className="form-control" name="partsOfSpeech" placeholder="Parts of Speech..." />
                        </InputWrapper>
                        <InputWrapper col={3} label="Pronunciation">
                            <input ref={register()} type="text" className="form-control" name="pronunciation" placeholder="Pronunciation..." />
                        </InputWrapper>
                        <InputWrapper col={6} label="Fun fact" >
                            <input ref={register()} type="text" name="funFact" className="form-control" placeholder="Fun fact of this word" />
                        </InputWrapper>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary">Add New Word</button>
                        </div>
                    </Row>
                </form>
            </Container>
            <div className="mt-5 bg-light">
                {loading && <SectionSpinner />}
                {values && <WordsContainer words={values} />}
                {showLoad && <SectionSpinner />}

            </div>
        </>
    );
};

export default AddNewWord;