import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import Switch from "react-bootstrap-switch"
import { medicalCourseContentCollection } from '../../firebase/fire-app';

const MedAddContentCheatSheet = ({ defaultData }) => {
    const { register, handleSubmit } = useForm();

    const [isPremium, setIsPremium] = useState(defaultData ? defaultData.isPremium : false);
    const [isPublished, setIsPublished] = useState(defaultData ? defaultData.isPublished : false);



    const FunctionHandler = {
        setIsPremium,
        setIsPublished
    }
    const handleSwitch = (sw, field) => {
        const stat = sw.state.value;
        FunctionHandler[field](stat)

    }

    const onNewContentAdded = (data, e) => {
        data.isPremium = isPremium;
        data.isPublished = isPublished;
        data.createdAt = new Date();
        data.serialNumber = Number(data.serialNumber);
        data.type = "Cheatsheet"

        medicalCourseContentCollection.add(data).then(() => {
            e.target.reset();
            alert("Added New Content to Medical Admission")
        })
        console.log(data)
    }
    const onContentUpdate = (data, e) => {
        data.isPremium = isPremium;
        data.isPublished = isPublished;
        data.serialNumber = Number(data.serialNumber);
        data.type = "Cheatsheet"
        medicalCourseContentCollection.doc(defaultData.id).update(data).then(() => {
            alert("Content updated!")
        })
    }


    return (
        <>
            <div className="container">
                {defaultData ?
                    <form className='' onSubmit={handleSubmit(onContentUpdate)}>
                        <div className="row d-flex justify-content-center">
                            <InputWrapper col="3" label="SerialNo.">
                                <input defaultValue={defaultData.serialNumber} ref={register({ required: true })} type="number" name="serialNumber" className="form-control" placeholder="serial number" />
                            </InputWrapper>
                            <InputWrapper col="4" label="Sheet Title">
                                <input defaultValue={defaultData.cheatSheetTitle} ref={register({ required: true })} type="text" name="cheatSheetTitle" className="form-control" placeholder="cheat sheet title" />
                            </InputWrapper>
                            <InputWrapper col="4" label="Sub Title">
                                <input defaultValue={defaultData.cheatSheetSubTitle} ref={register({ required: true })} type="text" name="cheatSheetSubTitle" className="form-control" placeholder="cheat sheet sub title" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Sheet Link">
                                <input defaultValue={defaultData.cheatSheetPdfLink} ref={register()} type="text" name="cheatSheetPdfLink" className="form-control" placeholder="cheat sheet link" />
                            </InputWrapper>
                            <div className="col-md-12 col-12 text-center mb-4">
                                <Switch defaultValue={false} labelText="isPremium" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "setIsPremium")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="setIsPremium" />
                                <Switch defaultValue={false} labelText="isPublished" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "setIsPublished")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="setIsPublished" />
                            </div>

                            <div className="col-12 text-center pb-5">
                                <button type="submit" className="btn btn-secondary">Add New Cheat Sheet</button>
                            </div>
                        </div>
                    </form>
                    :
                    <form className='' onSubmit={handleSubmit(onNewContentAdded)}>
                        <div className="row d-flex justify-content-center">
                            <InputWrapper col="3" label="SerialNo.">
                                <input ref={register({ required: true })} type="number" name="serialNumber" className="form-control" placeholder="serial number" />
                            </InputWrapper>
                            <InputWrapper col="4" label="Sheet Title">
                                <input ref={register({ required: true })} type="text" name="cheatSheetTitle" className="form-control" placeholder="cheat sheet title" />
                            </InputWrapper>
                            <InputWrapper col="4" label="Sub Title">
                                <input ref={register({ required: true })} type="text" name="cheatSheetSubTitle" className="form-control" placeholder="cheat sheet sub title" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Sheet Link">
                                <input ref={register({ required: true })} type="text" name="cheatSheetPdfLink" className="form-control" placeholder="cheat sheet link" />
                            </InputWrapper>
                            <div className="col-md-12 col-12 text-center mb-4">
                                <Switch defaultValue={false} labelText="isPremium" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "setIsPremium")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="setIsPremium" />
                                <Switch defaultValue={false} labelText="isPublished" wrapperClass="mr-4" onChange={(el, state) => handleSwitch(el, "setIsPublished")} baseClass="bootstrap-switch" animate={true} onColor="primary" offColor="danger" name="setIsPublished" />
                            </div>

                            <div className="col-12 text-center pb-5">
                                <button type="submit" className="btn btn-secondary">Add New Cheat Sheet</button>
                            </div>
                        </div>
                    </form>}
            </div>
        </>
    );
};

export default MedAddContentCheatSheet;