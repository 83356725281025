import React, { useState, useContext, useEffect } from "react";
import PageSpinner from "../Components/Spinner/PageSpinner";
import { Redirect, Route } from "react-router-dom";
import fireApp from "../firebase/fire-app";

export const AuthContext = React.createContext();
export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        fireApp.auth().onAuthStateChanged((usr) => {
            setCurrentUser(usr);
            setPending(false);
        });
    }, []);
    if (pending) {
        return <><PageSpinner /></>;
    }
    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    );
};
export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    return (

        <Route
            {...rest}
            render={(routeProps) =>
                (currentUser &&
                    currentUser.email === "questions@prottoy.com.bd") ||
                    (currentUser && currentUser.email === "admin@prottoy.com.bd") ? (
                        <RouteComponent {...routeProps} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: routeProps.location },
                            }}
                        />
                    )
            }
        />
    );
};
export const useAuth = () => useContext(AuthContext);
