import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { zoomClassesCollection } from '../../firebase/fire-app';
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';



const LiveZoomClasses = () => {

    const [liveClasses, loading, error] = useCollectionData(zoomClassesCollection.orderBy("startAt", "asc"), { idField: "id" });
    const [viewClasses, setViewClasses] = useState(null);
    const [finished, setFinished] = useState(false);
    useEffect(() => {
        if (liveClasses) {
            if (finished) {
                const filteredClasses = liveClasses.filter(item => item.isFinished === true);
                setViewClasses(filteredClasses);
            } else {
                const filteredClasses = liveClasses.filter(item => item.isFinished === false);

                setViewClasses(filteredClasses);
            }
        }
    }, [liveClasses, finished])
    const onTabChanged = (e) => {
        console.log(e)
        if (e == "true") {
            setFinished(true)

        } else if (e == "false") {
            setFinished(false)
        }
    }
    return (
        <>
            <div className="jumbotron py-5 text-center">
                <h4>Live Classes {finished ? <span className="badge badge-primary">FINISHED</span> : <span className="badge badge-danger">UPCOMING</span>} </h4>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <RadioGroup value={finished} onChange={(e) => onTabChanged(e)} horizontal>
                                <RadioButton pointColor="#075e54" rootColor="#CCCCCC" value="false">
                                    Upcoming
                                </RadioButton>
                                <RadioButton pointColor="#075e54" rootColor="#CCCCCC" value="true">
                                    Finished
                                </RadioButton>
                            </RadioGroup>
                        </div>
                    </div>
                </div>

            </div>
            {loading && <SectionSpinner />}
            <div className="container py-5">
                <div className="row">
                    {viewClasses && viewClasses.length > 0 && viewClasses.map(item => <ClassCard finished={finished} data={item} key={item.id} />)}
                </div>
            </div>
        </>
    );
};

export default LiveZoomClasses;

const ClassCard = ({ data }) => {
    const startTime = new Date(data.startAt.seconds * 1000);
    var check = moment(startTime);
    var month = check.format('MMMM');
    var day = check.format('D');
    var year = check.format('YYYY');
    var hour = check.format('hh');
    var minute = check.format('mm');
    const deleteClass = (id) => {
        zoomClassesCollection.doc(id).delete()
    }
    return (
        <>
            <div className="col-md-4 mb-4">
                <div className={`${data.isFinished ? "bg-primary " : "bg-danger "} card p-4 text-white`} >
                    <h4 className="font-bold">{data.title}        <CopyToClipboard text={data.id}>
                        <button className="btn btn-sm btn-warning" >copy id</button>
                    </CopyToClipboard> </h4>
             
                    <hr />
                    <div className="d-flex justify-content-between">
                        <p className="mb-0">Published: {data.isPublished ? "TRUE" : "FALSE"} </p>
                        <p className="mb-0">Live Now: {data.isLive ? "TRUE" : "FALSE"} </p>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                        <p className="mb-0">Finished: {data.isFinished ? "TRUE" : "FALSE"} </p>
                        <p className="mb-0">Premium: {data.isPremium ? "TRUE" : "FALSE"} </p>
                    </div>
                    <p className="font-bold mb-3">Class time: {`${day}th ${month} - ${hour}:${minute}`} </p>
                    <p>{data.shortDescription} </p>

                    <div className="mt-3 d-flex justify-content-between">
                        <Link to={`/live-classes/edit/${data.id}`} className="btn btn-sm btn-primary" >Edit & Details</Link>
                        <Button size="sm" onClick={() => deleteClass(data.id)} variant="dark">Delete</Button>
                        <a href={data.classLink} target="_blank" className="btn btn-sm btn-warning" rel="noopener noreferrer">Class Link</a>

                    </div>
                </div>
            </div>
        </>
    )
}