import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MedAddContentExam from '../../Pages/MedicalAdmission/_MedAddContentExam';
import CrashContentModal from './CrashContentModal';
import "./styles.scss";

const CrashLiveExamCard = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className="col-md-12">
                <div className="crash-card p-4">
                    <div className="row d-flex align-items-center">
                        <div className="col-5">
                            <p className="mb-1">
                                <span className="number mr-3">{data.serialNumber}</span>   <b>{data.type}</b>
                            </p>
                        </div>
                        <div className="col-4 d-flex justify-content-around ">
                            {
                                data.isPremium ? <span className="font-bold text-danger">Premium Exam</span> : <span className="text-primary">Free Exam</span>
                            }
                            {
                                data.isPublished ? <span className="font-bold text-danger">Published</span> : <span className="text-primary">Unpublished</span>
                            }
                            {/* <span className="text-primary"><b>Exam ID: </b>{data.liveExamId}</span> */}
                        </div>
                        <div className="col-3 d-flex justify-content-around">
                            <Link className='btn btn-sm btn-primary' to={`/liveExams/details/${data.liveExamId}`}>
                                Exam Details
                            </Link>
                            <Button size='sm' variant="danger" onClick={() => setModalShow(true)}>
                                Edit Content
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <CrashContentModal show={modalShow} onHide={() => setModalShow(false)}>
                <MedAddContentExam defaultData={data} />
            </CrashContentModal>
        </>
    );
};

export default CrashLiveExamCard;