import React from "react";
import { useState } from "react";
import { Row, Col, Toast } from "react-bootstrap";

const SuccessToast = (props) => {
    const [show, setShow] = useState(props.showToast);
    return (
        <>
            <Row>
                <Col xs={6}>
                    <Toast
                        className="bg-primary"
                        onClose={() => setShow(false)}
                        show={show}
                        delay={10000}
                        autohide
                    >
                        <Toast.Body>
                            Woohoo, you're reading this text in a Toast!
                        </Toast.Body>
                    </Toast>
                </Col>
            </Row>
        </>
    );
};

export default SuccessToast;
