import React from 'react';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { fireStoreDB } from '../../../firebase/fire-app';
import CompSubjects from './CompSubjects';
import CompVideoDetails from './CompVideoDetails';
import { useCollegeAdmissionCourse } from './useCollegeAdmissionCourse';




const CAAddCourseVideo = () => {

    const { subject, setSubject, chapter, setChapter, chapters, subjectSLoading } = useCollegeAdmissionCourse();
    const basicLog = () => {
        console.log(chapters);
    }

    return (
        <>
            <CompSubjects />
            {!subjectSLoading && <CompVideoDetails />}
        </>
    );
};

export default CAAddCourseVideo;