import React from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SectionSpinner from "../Components/Spinner/SectionSpinner";
import { useColleges } from "../Hooks/useColleges";
import { InputWrapper } from "./EditUser";
// import { CopyToClipboard } from "react-copy-to-clipboard";

const AllColleges = () => {
    const collegesManager = useColleges();
    const colleges = collegesManager.colleges;
    document.title = "All Colleges";
    return (
        <>
            <div className="container">
                {collegesManager.isLoading && <SectionSpinner />}
                {colleges && colleges.map((item, index) => (
                    <CollegeCardComponent college={item} serial={index + 1} />
                ))}
            </div>
        </>
    );
};

export default AllColleges;

export const CollegeCardComponent = (props) => {
    const [showEdit, setShowEdit] = useState(false);
    // const [textCopied, setTextCopied] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const id = props.college.id;
    const { name, caps } = props.college.data;
    const { deleteCollege, updateCollegeInfo } = useColleges();
    const onSubmit = (data) => {
        data.caps = data.name.toUpperCase();
        updateCollegeInfo(id, data).then((res) => setShowEdit(false));
    };
    const serial = props.serial;
    return (
        <div className="border-bottom py-3 ">
            <Row className="mb-2">
                <Col md={1}>#{serial}</Col>
                <Col md={5}>{name} </Col>
                <Col md={6}>
                    ID: <strong>{id} </strong>
                    {/* <CopyToClipboard text={id}>
                        ID: <strong>{id} </strong>
                    </CopyToClipboard> */}
                    {/* {textCopied && (
                        <span className="text-primary">Copied!</span>
                    )} */}
                </Col>
                {/* <Col className="text-center" md={2}>
                    <Button onClick={() => setShowEdit(true)} size="sm">
                        Edit
                    </Button>{" "}
                    <Button
                        onClick={() => deleteCollege(id)}
                        variant="danger"
                        size="sm"
                    >
                        Delete
                    </Button>
                </Col> */}
            </Row>
            {showEdit && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mt-2">
                        <InputWrapper col={6} label="Change to: ">
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                defaultValue={name}
                                ref={register({
                                    required: true,
                                })}
                            />
                        </InputWrapper>
                        <InputWrapper col={4} label="ID: ">
                            <input
                                type="text"
                                name="id"
                                className="form-control"
                                placeholder={id}
                                disabled
                            />
                        </InputWrapper>
                        <Col md={2} className="text-center">
                            <Button size="sm" type="submit">
                                Update
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </div>
    );
};
