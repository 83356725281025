import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputWrapper from '../../../../Components/InputWrapper/InputWrapper';
import { fireStoreDB } from '../../../../firebase/fire-app';
import { useCourseModule } from '../useCourseModule';
import ComponentAddBook from './ComponentAddBook';
import firebase from "firebase";
import PreviewMathSolve from './PreviewMathSolve';
import { emptyElementRemover } from '../../../../utils/useful-modules';


const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const TypeMathSolve = () => {
    const { currentSub, currentChap, currentType } = useCourseModule();
    const [bookLessons, setBookLessons] = useState(null)


    const [currentBook, setCurrentBook] = useState(null);
    const moduleRef = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("MathSolve");
    const [books, booksLoading, booksError] = useCollectionData(moduleRef.orderBy("serialNumber"), { idField: "id" })
    const [newBookForm, setNewBookForm] = useState(false);
    const { register, handleSubmit } = useForm();
    const [lessonSize, setLessonSize] = useState(1);
    const onSubmit = data => console.log(data);
    const onBookChanged = (e) => {
        let bk = e.target.value;
        setCurrentBook(bk);
    }
    // books ref
    const bookDocRef = fireStoreDB.collection("Courses").doc("Subjects").collection(currentSub).doc(currentChap).collection("MathSolve");
    useEffect(() => {

        if (currentBook) {
            bookDocRef.doc(currentBook).onSnapshot((doc) => {
                setBookLessons({ id: doc.id, ...doc.data() })
                console.log({ id: doc.id, ...doc.data() });
            })
        }

    }, [currentBook])
    const onModuleSubmitted = (data, e) => {
        data.videos.shift()
        data.videos = emptyElementRemover(data.videos)
        moduleRef.doc(currentBook).update({
            lessons: firebase.firestore.FieldValue.arrayUnion(data)
        }).then(() => {
            e.target.reset();
        })
        console.log(data);
    }
    return (
        <>
            <div className="container-fluid mt-4">
                <div className="row border-bottom pb-3">
                    <div className="col-md-4 border-right pr-5">
                        <select onChange={onBookChanged} className="custom-select" name="subject">
                            <option value=""> Choose book..</option>
                            {books && books.map(item => <option value={item.id} >{item.name} </option>)}
                        </select>
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        {books && <p>Available books: {books.map(item => <span className="bg-primary mx-2 text-white" >{item.name} </span>)} </p>}
                        <button onClick={() => setNewBookForm(true)} className="ml-4 btn btn-danger btn-sm">Add new book?</button>
                    </div>
                    <div className="col-md-6 offset-md-2">
                        {newBookForm && <ComponentAddBook sub={currentSub} chap={currentChap} hide={setNewBookForm} />}
                    </div>
                </div>
                <div className="row mt-3">


                    <div className="col-md-4 border-right">
                        <h4 className="text-left mb-4">You are adding <span className="bg-danger text-white px-3"> math solve</span> videos to : <span className=" text-danger px-3 mt-2"> {bookLessons && bookLessons.name}</span></h4>

                        <form className=" pl-3" onSubmit={handleSubmit(onModuleSubmitted)}>
                            <div className="row">
                                <InputWrapper col="8" label="Lesson">
                                    <input ref={register({
                                        required: true,
                                    })} type="text" name="name" placeholder="Lesson name" className="form-control" />
                                </InputWrapper>
                                <div className="col-12">
                                    <h5 className="mt-4">Add lesson videos here</h5>
                                </div>
                                {createArrayWithNumbers(lessonSize).map(
                                    (index) => {
                                        return (
                                            <>
                                                <InputWrapper col="10" addClass=" mb-md-1" label={`Name ${index}`}>


                                                    <input
                                                        className="form-control  "
                                                        name={`videos[${index}].name`}
                                                        placeholder={
                                                            "Put video name here "
                                                        }
                                                        ref={register()}
                                                    />
                                                </InputWrapper>
                                                <InputWrapper col="10" addClass=" mb-3" label={`ID ${index}`}>
                                                    <input
                                                        className="form-control "
                                                        name={`videos[${index}].videoID`}
                                                        placeholder={
                                                            "Put video id here "
                                                        }
                                                        ref={register()}
                                                    /></InputWrapper>

                                            </>

                                        );
                                    }
                                )}
                                <div className="col-10 text-right">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setLessonSize(lessonSize + 1)} >
                                        Add more
                                    </button>
                                </div>
                                <div className="col-10">
                                    <button type="submit" className="btn-sm btn-primary btn">Add this lesson</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        {currentBook && <PreviewMathSolve bookID={currentBook} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TypeMathSolve;