import * as firebase from "firebase";
import React, { useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { days, months } from "../../Data/daysandmonths";
import allSubjectsList from "../../Data/subjects";
import fireApp, { fireStoreDB } from "../../firebase/fire-app";
import SectionSpinner from "../../Components/Spinner/SectionSpinner"
import imageCompression from 'browser-image-compression';

// import firebase from "firebase";
const createArrayWithNumbers = (length) => {
    return Array.from({ length }, (_, k) => k + 1);
};

const db = fireStoreDB;
const examRef = db
    .collection("ExamOrQuiz")
    .doc("LiveExam")
    .collection("Exam").doc();
const AddLiveExam = () => {
    document.title = "Add New Live Exam";
    const { register, handleSubmit, watch, errors } = useForm();
    const [loading, setLoading] = useState(false);

    const [questionCount, setQuestionCount] = useState(1);
    const [videoCount, setVideoCount] = useState(1);
    const location = useLocation();
    const history = useHistory();

    const onSubmit = (data, e) => {
        setLoading(true)
        data.questionList.shift();
        data.videos.shift()

        const newExam = {};
        newExam.title = data.title;
        newExam.subTitle = data.subTitle;
        newExam.level = data.level;
        newExam.startAt = new Date(data.startAt);
        newExam.endAt = new Date(data.endAt);
        newExam.numberOfQuestions = Number(data.numberOfQuestions);
        newExam.timeInMinutesForEachQuestion = Number(
            data.timeInMinutesForEachQuestion
        );
        newExam.positiveMarks = Number(data.positiveMarks);
        newExam.negativeMarks = Number(data.negativeMarks);
        newExam.questionList = data.questionList;
        newExam.isPublished = (data.isPublished == "true");
        newExam.isPremium = (data.isPremium == "true");
        // console.log(data.videos);
        if (data.videos) {
            if (data.videos[0])
                newExam.videos = data.videos;
        }
        // newExam.videos = data.videos;
        // alert(JSON.stringify(newExam));

        examRef.set(newExam).then((res) => {
            e.target.reset();
            setLoading(false);
            history.push("/liveExams/details/" + examRef.id)
        }).catch(err => {
            setLoading(false);
            alert(err);
        })
    };
    return (
        <>
            <Container>
                {loading && <SectionSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-5">
                        <Row>
                            <InputWrapper col={4} label="Title">
                                <input
                                    name="title"
                                    id="title"
                                    type="text"
                                    placeholder="Exam title"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Sub Title:">
                                <input
                                    name="subTitle"
                                    id="subTitle"
                                    type="text"
                                    placeholder="Exam sub title"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={4} label="Level">
                                <select
                                    className="custom-select"
                                    name="level"
                                    id="level"
                                    ref={register({
                                        required: true,
                                    })}
                                >
                                    <option value="HSC" selected>HSC </option>
                                    <option value="SSC">SSC </option>
                                    <option value="CollegeAdmission">CollegeAdmission</option>
                                    <option value="Varsity">Varsity</option>
                                    <option value="Medical">Medical</option>
                                    <option value="Engineering">Engineering</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={6} label="Starts at">
                                <input
                                    type="datetime-local"
                                    name="startAt"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Ends at">
                                <input
                                    type="datetime-local"
                                    name="endAt"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper col={6} label="Num. of Questions">
                                <input
                                    type="number"
                                    name="numberOfQuestions"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={6}
                                label="Time in min. for each question."
                            >
                                <input
                                    type="number"
                                    name="timeInMinutesForEachQuestion"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={4}
                                label="Positive marks per question"
                            >
                                <input
                                    type="number"
                                    name="positiveMarks"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={4}
                                label="Negative marks per question"
                            >
                                <input
                                    type="number"
                                    name="negativeMarks"
                                    className="form-control"
                                    ref={register({
                                        required: true,
                                    })}
                                    step=".01"
                                />
                            </InputWrapper>
                            <InputWrapper
                                col={4}
                                label="Publish now"
                            >
                                <select name="isPublished" className="custom-select" ref={register({
                                    required: true
                                })}>
                                    <option value="choose">Choose</option>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper
                                col={4}
                                label="Premium Exam"
                            >
                                <select name="isPremium" className="custom-select" ref={register({
                                    required: true
                                })}>
                                    <option value="choose">Choose</option>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </InputWrapper>
                            <InputWrapper col={7} label="Total questions to set">
                                <input type="number" className="form-control" name="totalQuestionCount" onBlur={(e) => {
                                    setQuestionCount(e.target.value);
                                }} ref={register({
                                    required: true,
                                })} />
                            </InputWrapper>
                            <div className="col-md-6 text-center mt-3">
                                <h4>Exam Questions</h4>
                                <div className="row">
                                    {createArrayWithNumbers(questionCount).map(
                                        (index) => {
                                            return (
                                                <InputWrapper
                                                    addClass=" "
                                                    col={12}
                                                    label={"ID for question: " + index}
                                                >
                                                    <input
                                                        className="form-control"
                                                        name={`questionList[${index}]`}
                                                        placeholder={
                                                            "Put question ID of " +
                                                            index
                                                        }
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                </InputWrapper>
                                            );
                                        }
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6 text-center mt-3">
                                <h4>Related Videos</h4>
                                <div className="row">
                                    {createArrayWithNumbers(videoCount).map(
                                        (index) => {
                                            return (
                                                <>
                                                    <InputWrapper
                                                        addClass=" "
                                                        col={12}
                                                        label={"ID for video: " + index}
                                                    >
                                                        <input
                                                            className="form-control"
                                                            name={`videos[${index}]`}
                                                            placeholder={
                                                                "Put video ID of " +
                                                                index
                                                            }
                                                            ref={register()}
                                                        />
                                                    </InputWrapper>

                                                </>
                                            );
                                        }
                                    )}
                                    <div className="col-12 text-center"><Button onClick={() => setVideoCount(videoCount + 1)} className="btn btn-primary btn-sm">Add more..</Button> </div>
                                </div>
                            </div>

                        </Row>
                        <div className="text-center mt-4">
                            <Button type="submit" center>
                                Submit Form
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </>
    );
};

export default AddLiveExam;

const InputWrapper = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            {props.children}
        </div>
    );
};

