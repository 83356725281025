const courseSubjects = [
    "CH01",
    "CH02",
    "PH01",
    "PH02",
    "MT01",
    "MT02",
    "BIO01",
    "BIO02"
];

const videoType = [
    "Outline",
    "Theory",
    "MathSolve",
    "BoardQuestions",
    "ShortTechnique"
]

export { courseSubjects, videoType }