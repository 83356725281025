import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import MedAddContentCheatSheet from './_MedAddContentCheatSheet';
import MedAddContentClass from './_MedAddContentClass';
import MedAddContentExam from './_MedAddContentExam';

const MedicalAddModule = () => {
    const [moduleType, setModuleType] = useState("Live Class");
    return (
        <>
            <section className="py-3 bg-light">

                <p className="mb-0 text-left font-14">Medical Admission/Add Module/<b>{moduleType}</b></p>
            </section>
            <div className="container mb-5">
                <div className="text-center">
                    <p className="font-14">Select the content type</p>
                    <ButtonGroup aria-label="Basic example">
                        <Button onClick={() => setModuleType("Live Class")} variant={moduleType === "Live Class" ? "primary" : "secondary"}>Live Class</Button>
                        <Button onClick={() => setModuleType("Live Exam")} variant={moduleType === "Live Exam" ? "primary" : "secondary"}>Live Exam</Button>
                        <Button onClick={() => setModuleType("Cheatsheet")} variant={moduleType === "Cheatsheet" ? "primary" : "secondary"}>Cheat Sheet</Button>
                    </ButtonGroup>
                </div>
            </div>
            {
                moduleType === "Live Class" && <MedAddContentClass />
            }
            {
                moduleType === "Live Exam" && <MedAddContentExam />
            }
            {
                moduleType === "Cheatsheet" && <MedAddContentCheatSheet />
            }
        </>
    );
};

export default MedicalAddModule;