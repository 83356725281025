import React, { createContext, useContext } from "react";
import { useState } from "react";
import { fireStoreDB } from "../../../firebase/fire-app";
import { useCollectionData } from 'react-firebase-hooks/firestore';


const CollegeAdmissionContext = createContext();

export const CollegeAdmissionContextProvider = (props) => {
    const videoManager = VideoManager();
    return (
        <CollegeAdmissionContext.Provider value={videoManager}>
            {props.children}
        </CollegeAdmissionContext.Provider>
    )
}
export const useCollegeAdmissionCourse = () => useContext(CollegeAdmissionContext);


const VideoManager = () => {
    const [subject, setSubject] = useState("CollegeAdmission-BIO");
    const [chapter, setChapter] = useState(null);
    const [requestedSubject, setRequestedSubject] = useState("CollegeAdmission-PH");
    const [type, setType] = useState(null);
    const [chapterNum, setChapterNum] = useState(null)
    const subjectsCollectionRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection("CollegeAdmission");
    const reqChapterCollectionRef = fireStoreDB.collection("Courses").doc("SpecialCourses").collection(requestedSubject);
    const [subjects, subjectSLoading, chapError] = useCollectionData(subjectsCollectionRef, { idField: "id" })
    const [requestedChapters, reqChapLoading, reqChapError] = useCollectionData(reqChapterCollectionRef, { idField: "id" })

    return {
        requestedChapters, reqChapLoading, setRequestedSubject, subject, setSubject, chapter, setChapter, subjects, subjectSLoading, type, setType, chapterNum, setChapterNum
    }
}