import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useColleges } from "../../Hooks/useColleges";
import { useUsers } from "../../Hooks/useUsers";
import { InputWrapper } from "../../Pages/EditUser";
import SuccessToast from "../SuccessToast/SuccessToast";

const NewCollegeCard = (props) => {
    const serial = props.serial;
    const { id, uid } = props.college;
    const [collegeData, setCollegeData] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const [userData, setUserData] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        watch: watch2,
        errors: errors2,
    } = useForm();
    const {
        deleteCollege,
        updateCollegeInfo,
        findCollegeInfoById,
    } = useColleges();
    const { findUserById, updateUser } = useUsers();
    // fetching college and user info
    useEffect(() => {
        findCollegeInfoById(id).then((res) => {
            setCollegeData(res);
        });
    }, []);
    useEffect(() => {
        if (uid) {
            findUserById(uid).then((res) => {
                setUserData(res);
            });
        }
    }, []);
    const onSubmit = (data) => {
        data.caps = data.name.toUpperCase();
        updateCollegeInfo(id, data).then((res) => setShowEdit(true));
    };
    const onSubmit2 = (data) => {
        updateUser(uid, data).then((res) => {
            setShowSuccess(true);
        });

    };

    return (
        <div className="border-bottom py-3 ">
            {collegeData ? (
                <Row className="mb-2">
                    <Col md={1}>#{serial}</Col>
                    <Col md={5}>{collegeData.name} </Col>
                    <Col md={4}>
                        ID: <strong>{id} </strong>
                    </Col>
                    <Col className="text-center" md={2}>
                        <Button onClick={() => setShowEdit(true)} size="sm">
                            Edit
                        </Button>{" "}
                        <Button
                            onClick={() => deleteCollege(id)}
                            variant="danger"
                            size="sm"
                        >
                            Delete
                        </Button>
                    </Col>
                </Row>
            ) : (
                    <Spinner animation="grow" variant="primary" />
                )}

            {showEdit && (
                <form className="mt-0" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mt-2">
                        <InputWrapper col={6} label="Change to: ">
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                defaultValue={collegeData.name}
                                ref={register({
                                    required: true,
                                })}
                            />
                        </InputWrapper>
                        <InputWrapper col={4} label="ID: ">
                            <input
                                type="text"
                                name="id"
                                className="form-control"
                                placeholder={collegeData.id}
                                disabled
                            />
                        </InputWrapper>
                        <Col md={2} className="text-center">
                            <Button size="sm" type="submit">
                                Update
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
            {showEdit && (
                <form className="mt-0" onSubmit={handleSubmit2(onSubmit2)}>
                    <Row className="mt-2">
                        <DisabledInput
                            label="User ID: "
                            col={4}
                            default={userData.id}
                        />
                        <DisabledInput
                            label="User name: "
                            col={4}
                            default={userData.name}
                        />
                        <DisabledInput
                            label="User phone: "
                            col={4}
                            default={userData.phone}
                        />
                        <InputWrapper col={5} label="College: ">
                            <input
                                type="text"
                                name="college"
                                className="form-control"
                                defaultValue={userData.college}
                                ref={register2({
                                    required: true,
                                })}
                            />
                        </InputWrapper>
                        <InputWrapper col={5} label="Change ID: ">
                            <input
                                type="text"
                                name="collegeId"
                                className="form-control"
                                defaultValue={userData.collegeId}
                                ref={register2({
                                    required: true,
                                })}
                            />
                        </InputWrapper>
                        <Col md={2}>
                            <div className="text-center">
                                <Button type="submit" size="sm" center>
                                    Update User
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            )}
            <SuccessToast showToast={showSuccess} />
        </div>
    );
};

export default NewCollegeCard;

const DisabledInput = (props) => {
    return (
        <div
            className={
                "input-group mb-3 col-md-" + props.col + " " + props.addClass
            }
        >
            <div className="input-group-prepend">
                <span className="input-group-text">{props.label} </span>
            </div>
            <input
                type="text"
                className="form-control"
                defaultValue={props.default}
                disabled
            />
        </div>
    );
};
