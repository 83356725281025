import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PageSpinner from "../Components/Spinner/PageSpinner";
import fireApp from "../firebase/fire-app";

const db = fireApp.firestore();

const CollegesContext = createContext();

export const CollegesContextProvider = (props) => {
    const collegesManager = CollegesManager();
    // if (collegesManager.isLoading) {
    //     return (
    //         <>
    //             <PageSpinner />
    //         </>
    //     );
    // }
    return (
        <CollegesContext.Provider value={collegesManager}>

            { props.children}
        </CollegesContext.Provider>
    );
};

export const useColleges = () => useContext(CollegesContext);
const collegesCollectionRef = db
    .collection("metadata")
    .doc("collegeData")
    .collection("collegeList");
const CollegesManager = () => {
    const [colleges, setColleges] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lastChange, setLastChange] = useState(null);
    const [newColleges, setNewColleges] = useState([]);
    useEffect(() => {
        const fetchColleges = async () => {
            const collegesCollection = await collegesCollectionRef
                .orderBy("name")
                .get();
            setColleges(
                collegesCollection.docs.map((doc) => {
                    return { id: doc.id, data: doc.data() };
                })
            );
            setIsLoading(false);
        };
        fetchColleges();
    }, [lastChange]);
    const deleteCollege = (id) => {
        collegesCollectionRef
            .doc(id)
            .delete()
            .then((res) => {
                setLastChange(id);
            });
    };
    const updateCollegeInfo = (id, data) => {
        return collegesCollectionRef
            .doc(id)
            .update(data)
            .then((res) => {
                setLastChange(id);
                return data;
            });
    };
    const findCollegeInfoById = (id) => {
        return collegesCollectionRef
            .doc(id)
            .get()
            .then((doc) => {
                return { id: doc.id, ...doc.data() };
            });
    };
    return {
        colleges,
        isLoading,
        deleteCollege,
        updateCollegeInfo,
        findCollegeInfoById,
    };
};
