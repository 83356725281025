import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { fireStoreDB } from "../../firebase/fire-app";
import FetchImgComponent from "../FetchImgComponent/FetchImgComponent";
import MathJax from "react-mathjax-preview";

const db = fireStoreDB;
const QuestionCard = ({ qID, count }) => {
    const qId = qID;
    const [qDocRefId, setQDocRefId] = useState(qId);
    const [question, setQuestion] = useState(null);
    useEffect(() => {
        const fetchQuestion = async () => {
            const questionDoc = await db
                .collection("ExamOrQuiz")
                .doc("Question")
                .collection("MCQ")
                .doc(qId)
                .get();
            if (!questionDoc.exists) {
                return <>"No such question found!"</>;
            } else {
                setQuestion(questionDoc.data());
            }
        };
        fetchQuestion();
    }, []);
    if (!question) {
        return (
            <>
                <Card className="p-3 mb-3 text-center">
                    <Row>
                        <Spinner variant="primary" animation="border" />
                    </Row>
                </Card>
            </>
        );
    }
    return (
        <>
            {
                true
            // question.subject === "ENG"
             ?<Card className="p-3 mb-3">
                <Row>
                    <Col>
                        <strong>#{count} </strong> {qID}
                        <p>
                            <MathJax math={question.questionBody.text} />
                        </p>
                    </Col>
                    {question.questionBody.img && (
                        <Col md={3}>
                            <FetchImgComponent
                                imgName={question.questionBody.img}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col md={3}>
                        <p>
                            <MathJax math={"(i) " + question.option1.text} />{" "}
                        </p>
                        {question.option1.img && (
                            <FetchImgComponent imgName={question.option1.img} />
                        )}
                    </Col>
                    <Col md={3}>
                        <p>
                            <MathJax math={"(ii) " + question.option2.text} />{" "}
                        </p>
                        {question.option2.img && (
                            <FetchImgComponent imgName={question.option2.img} />
                        )}
                    </Col>
                    <Col md={3}>
                        <p>
                            <MathJax math={"(iii) " + question.option3.text} />{" "}
                        </p>
                        {question.option3.img && (
                            <FetchImgComponent imgName={question.option3.img} />
                        )}
                    </Col>
                    <Col md={3}>
                        <p >
                            <MathJax math={"(iv) " + question.option4.text} />{" "}
                        </p>
                        {question.option4.img && (
                            <FetchImgComponent imgName={question.option4.img} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Answer: option {"  " + question.answer}</p></Col>
                </Row>
            </Card>: ""}
        </>
    );
};

export default QuestionCard;
