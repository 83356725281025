import React from 'react';
import { Container, Row } from 'react-bootstrap';
import InputWrapper from '../../../Components/InputWrapper/InputWrapper';
import SectionSpinner from '../../../Components/Spinner/SectionSpinner';
import CompAddChapter from './CompAddChapter';
import { courseSubjects } from './data';
import { useCourseVideo } from './useCourseVideo';

const CompSubjects = () => {
    const { subject, setSubject, chapter, setChapter, chapters, chapLoading, type, chapterNum, setChapterNum } = useCourseVideo();
    const [modalShow, setModalShow] = React.useState(false);

    const onSubjectChanged = (e) => {
        const newSub = e.target.value;
        setSubject(newSub)
    }
    const onChapterChanged = (e) => {
        const newChap = e.target.value;
        setChapter(newChap);
        setChapterNum(newChap);
    }
    return (
        <>
            <div className="container">
                <div className="row mt-2">
                    <div className="col-md-3 text-center offset-md-2">
                        <span className="btn btn-primary">{subject}</span>
                    </div>
                    <div className="col-md-3 text-center ">
                        <span className="btn btn-primary">{chapterNum}</span>
                    </div>
                    <div className="col-md-2 text-center">
                        <span className="btn btn-primary">{type}</span>

                    </div>
                </div>
            </div>
            {chapLoading && <SectionSpinner />}

            {!chapLoading > 0 &&
                <Container className="py-3">

                    <Row className="d-flex align-items-center">
                        <InputWrapper col={4} label="Subject">
                            <select onChange={onSubjectChanged} className="custom-select" name="subject">
                                <option value={subject}>{subject}</option>
                                {courseSubjects.map(item => <option value={item} >{item} </option>)}
                            </select>
                        </InputWrapper>
                        <InputWrapper col={4} label="Chapter">
                            <select onChange={onChapterChanged} className="custom-select" name="subject">
                                <option value=""> Choose chapter..</option>
                                {chapters && chapters.map(item => <option value={item.chapterNum} >{item.name} </option>)}
                            </select>
                        </InputWrapper>
                        <div className="col-md-4">
                            <p className="text-primary text-center text-md-right">Chapter not found? <button onClick={() => setModalShow(true)} className="btn btn-danger btn-sm">Add new chapter</button> </p>
                        </div>
                    </Row>
                </Container>
            }
            <CompAddChapter setModalShow={setModalShow} subject={subject} show={modalShow}
                onHide={() => setModalShow(false)} />
        </>
    );
};

export default CompSubjects;