import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import MedAddContentCheatSheet from '../../Pages/MedicalAdmission/_MedAddContentCheatSheet';
import CrashContentModal from './CrashContentModal';
const CrashCheatSheetCard = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className="col-md-12">
                <div className="crash-card p-4">
                    <div className="row d-flex align-items-center">
                        <div className="col-5 d-flex justify-content-start">
                            <span className="number mr-3">{data.serialNumber}</span>
                            <p className="mb-0 font-14">
                                <b>{data.type}</b>: {data.cheatSheetTitle}<br />
                                {data.cheatSheetSubTitle}
                            </p>
                        </div>
                        <div className="col-4 d-flex justify-content-around ">
                            {
                                data.isPremium ? <span className="font-bold text-danger">Premium Class</span> : <span className="text-primary">Free Class</span>
                            }
                            {
                                data.isPublished ? <span className="font-bold text-danger">Published</span> : <span className="text-primary">Unpublished</span>
                            }
                            {/* <span className="text-primary"><b>Class ID: </b>{data.liveClassId}</span> */}
                        </div>
                        <div className="col-3 d-flex justify-content-around">
                      
                            <a href={data.cheatSheetPdfLink} className="btn btn-secondary btn-sm" target="_blank" rel="noopener noreferrer">
                                PDF URL
                            </a>
                            <Button size='sm' variant="danger" onClick={() => setModalShow(true)}>
                                Edit Content
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <CrashContentModal show={modalShow} onHide={() => setModalShow(false)}>
                <MedAddContentCheatSheet defaultData={data} />
            </CrashContentModal>
        </>
    );
};

export default CrashCheatSheetCard
    ;